import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { AuthService } from '../../helpers/AuthService';
import DisplayAlert from '../DisplayAlert';
import { Redirect } from 'react-router-dom';

const TOKEN_INTERVAL = 3000;

export class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: false,
            loading: true,
            email: '',
            passwordUntouched: true,
            password: '',
            confirmPasswordUntouched: true,
            confirmPassword: '',
            reset: null,
            alert: {
                alertType: '',
                message: ''
            }
        };

        this.redirectPage = this.redirectPage.bind(this);

        AuthService.verifyEmail(this.props.token)
            .then((rsp) => {
                this.setState({
                    verified: rsp.succeeded,
                    loading: false,
                    email: rsp.succeeded ? rsp.email : '',
                    passwordUntouched: true,
                    password: '',
                    confirmPasswordUntouched: true,
                    confirmPassword: '',
                    reset: null,
                    alert: {
                        alertType: '',
                        message: ''
                    }
                });
                   
            }).catch(() => {
                this.setState({
                    loading: false,
                    verified: false
                });
            });

        if (this.state.reset) {
            setInterval(this.redirectPage, TOKEN_INTERVAL)
        }
    }

    render() {
        if (this.state.reset) {
            return <Redirect to={this.props.history.push('/')} />;
        }

        let verifyDOM = this.state.verified
            ? this.renderResetForm()
            : <Row>
                <Col lg={12}>
                    <div className="well well-lg">
                        <div className="recover">
                            <h3>Email verification failed.</h3>
                            <p>&nbsp;</p>
                            <p>Email verification failed, <a href={'/'}>click here</a> to jump back to Home.</p>
                        </div>
                    </div>
                </Col>
            </Row>;

        let resetDOM = this.state.reset
            ? <Row>
                <Col lg={12}>
                    <div className="well well-lg">
                        <div className="recover">
                            <h3>Password is reset.</h3>
                            <p>&nbsp;</p>
                            <p>You can now login using new password, <a href={'/login'}>click here</a> to login.</p>
                        </div>
                    </div>
                </Col>
            </Row> : verifyDOM;

        return this.state.loading
            ? <div><p><em>Page is still loading...</em></p></div>
            : <div>
                <Row>
                    <Col md={12} className="d-flex">
                        <div className="page-header mb-4">Reset Password</div>
                    </Col>
                </Row>
                {resetDOM}
            </div>;
    }

    renderResetForm() {
        return (<div>
            <Form>
                <FormGroup className={(this.validPassword() || this.state.passwordUntouched) ? "" : "has-error"} row>
                    <Col md="6">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"><i className="glyphicon glyphicon-lock" /></InputGroupAddon>
                            <Input type="password" name="password" id="password"
                                onChange={(e) => this.onPasswordChange(e)} placeholder="Password" />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup className={(this.validConfirmPassword() || this.state.confirmPasswordUntouched) ? "" : "has-error"} row>
                    <Col md="6">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"><i className="glyphicon glyphicon-lock" /></InputGroupAddon>
                            <Input type="password" name="confirmPassword" id="confirmPassword"
                                onChange={(e) => this.onConfirmPasswordChange(e)} placeholder="Confirm Password" />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <div className="btn-toolbar" role="toolbar">
                    <Button color="primary" size="sm" disabled={!this.onValid()} onClick={() => { this.resetPassword() }}>RESET &gt;</Button>
                </div>
            </Form>
            <Row>
                <Col md="6 mb-4">
                    <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
                </Col>
            </Row>
        </div>);
    }

    validPassword() {
        if (this.state.password === null || this.state.password === "" || this.state.password.trim().length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    onPasswordChange(e) {
        this.setState({
            passwordUntouched: false,
            password: e.target.value
        });
    }

    validConfirmPassword() {
        if ((this.state.confirmPassword === null || this.state.confirmPassword === "" || this.state.confirmPassword.trim().length <= 0) || this.state.confirmPassword !== this.state.password) {
            return false;
        } else {
            return true;
        }
    }

    onConfirmPasswordChange(e) {
        this.setState({
            confirmPasswordUntouched: false,
            confirmPassword: e.target.value
        });
    }

    onValid() {
        if (this.state.password === null || this.state.password === '' || this.state.password !== this.state.confirmPassword)
            return false;
        else
            return true;
    }

    resetPassword() {
        AuthService.resetPassword(this.state.email, this.state.password)
            .then((rsp) => {
                if (rsp.succeeded) {
                    this.setState({
                        reset: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        reset: false,
                        loading: false,
                    })
                }
            })
            .catch(() => {
                this.setState({
                    reset: false,
                    loading: false
                })
            });
    }

    redirectPage() {
        this.setState({ reset: true });
    }
}