import React, { Component } from 'react';
import { Form, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchForm.css';

export class SearchForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            keywords: this.props.keywords
        } 
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.keywords !== prevState.keywords) {
            return { keywords: nextProps.keywords };
        }

        return null;
    } 

    render() {
        return (<div className="hor">
            <Form onSubmit={this.props.onSearch} className="search pb-1">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <strong>Search |</strong>
                    </InputGroupAddon>
                    <Input type="text" value={this.state.keywords} bsSize="sm" onChange={this.props.onChangeSearchKeywords} />
                    <InputGroupAddon addonType="append">
                        <Button>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Form>
        </div>);
    }
}