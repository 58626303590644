import { APIBASEURL } from './SiteConfig';

export const TokenHelper =
{
    getJwt: () => {
        return window.localStorage.getItem("liberum_career_access_token");
    },
    removeJwt: () => {
        window.localStorage.removeItem("liberum_career_access_token");
        window.localStorage.removeItem("liberum_career_refresh_token");
        window.localStorage.removeItem("liberum_career_access_token_expiry");
        window.localStorage.removeItem("liberum_career_refresh_token_expiry");
        return true;
    },
    isAccessTokenExpired: () => {
        const access_token_expiry = window.localStorage.getItem("liberum_career_access_token_expiry");
        var d1 = new Date(Number(access_token_expiry));
        var year = d1.getFullYear();
        var month = d1.getMonth();
        var date = d1.getDate();
        var hour = d1.getHours();
        var minute = d1.getMinutes();
        var second = d1.getSeconds();
        var ms = d1.getMilliseconds();

        var accessExpiry = new Date(year, month + 1, date, hour, minute, second, ms);
        if (accessExpiry <= new Date())
            return true;
        else
            return false;
    },
    refreshJwt: () => {
        const url = APIBASEURL.concat('api/account/refreshToken');
        const refresh_token = window.localStorage.getItem("liberum_career_refresh_token");

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                "refresh_token": refresh_token
            })
        })
            .then(rsp => rsp.json())
            .then(data => {
                if (data.succeeded) {
                    window.localStorage.setItem('liberum_career_access_token', data.token.access_token);
                    window.localStorage.setItem('liberum_career_refresh_token', data.token.refresh_token);
                    window.localStorage.setItem('liberum_career_access_token_expiry', data.token.access_expires.toString());
                    window.localStorage.setItem('liberum_career_refresh_token_expiry', data.token.refresh_expires.toString());
                }
                else {
                    TokenHelper.removeJwt();
                }
                return data.succeeded;
            })
            .catch(error => {
                throw error;
            });
    }
}