import React, { Component } from 'react';
import {
    Row, Col, CustomInput, Form, FormGroup, Label, Input, Button
} from 'reactstrap';
import { Modal } from 'react-bootstrap';
import DisplayAlert from '../DisplayAlert';
import DatePicker, { registerLocale } from 'react-datepicker';
import { TokenHelper } from '../../helpers/TokenHelper';
import "react-datepicker/dist/react-datepicker.css";
import './EditJob.css';
import enGB from "date-fns/locale/en-GB";
import update from 'immutability-helper';
//import CKEditor from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from 'ckeditor4-react';
import moment from 'moment';
import { ViewJobDetail } from './JobDetail';
import { DataService } from '../../helpers/DataService';
import history from '../../history';

registerLocale("en-GB", enGB);

export class EditJob extends Component {
    constructor(props) {
        super(props);

        let alertType = '';
        let message = '';

        if (this.props.params && this.props.params.status == 'created') {
            alertType = 'info';
            message = 'Job created.';
        } else if (this.props.params && this.props.params.status == 'duplicated') {
            alertType = 'info';
            message = 'Job duplicated.';
        }
        
        this.state = {
            loading: this.props.params.mode === 'create' ? false : true,
            id: (this.props.params && this.props.params.id) ? this.props.params.id : '0',
            mode: this.props.params.mode,
            auth: this.props.auth,
            job: {
                id: 0,
                title: '',
                description: '',
                excerpt: '',
                location: '',
                contractType: '',
                closingDate: new Date(),
                active: true,
                archived: false,
            },
            oldJob: {
                id: 0,
                title: '',
                description: '',
                excerpt: '',
                location: '',
                contractType: '',
                closingDate: new Date(),
                active: true,
                archived: false,
            },
            form: {
                titleUntouched: true,
                contractTypeUntouched: true,
                locationUntouched: true,
                closingDateUntouched: true,
                descriptionUntouched: true,
                inputDate: new Date()
            },
            alert: {
                alertType: alertType,
                message: message
            },
            backdrop: true,
            showModal: false,
            modalType: -1, // 0 - close job; 1 - archive job;
            redirect: false
        };

        this.onDuplicate = this.onDuplicate.bind(this);
        this.onClosingDateChange = this.onClosingDateChange.bind(this);
        this.duplicateJob = this.duplicateJob.bind(this);
        this.updateJob = this.updateJob.bind(this);

        this.toggle = this.toggle.bind(this);

        if (this.state.id > 0) {
            this.fetchJob();
        }
    }

    jobEqual() {
        return (this.state.job.title == this.state.oldJob.title
            && this.state.job.description == this.state.oldJob.description
            && this.state.job.excerpt == this.state.oldJob.excerpt
            && this.state.job.location == this.state.oldJob.location
            && this.state.job.contractType == this.state.oldJob.contractType
            && this.state.job.closingDate == this.state.oldJob.closingDate
            && this.state.job.active == this.state.oldJob.active
            && this.state.job.archived == this.state.oldjob.archived);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.params && nextProps.params.id && (nextProps.params.id !== prevState.id)) {
            return {
                id: nextProps.params.id,
                showModal: false,
                modalType: -1
            };
        }
        if (nextProps.params && (nextProps.params.mode !== prevState.mode)) {
            return {
                mode: nextProps.params.mode,
                showModal: false,
                modalType: -1
            };
        }
        if (nextProps.auth !== prevState.auth) {
            return {
                auth: nextProps.auth,
                showModal: false,
                modalType: -1
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.params !== this.props.params) {
            this.fetchJob();
        }
    }

    fetchJob() {
        DataService.getJob(this.state.id).then(data => {
            if (data.status !== 404) {
                let updateJob = update(this.state.job, {
                    id: { $set: data.id },
                    title: { $set: data.title },
                    description: { $set: data.description },
                    excerpt: { $set: data.excerpt },
                    location: { $set: data.location },
                    contractType: { $set: data.contractType },
                    closingDate: { $set: data.closingDate },
                    active: { $set: data.active },
                    archived: { $set: data.archived },
                });

                let updateOldJob = update(this.state.oldJob, {
                    id: { $set: data.id },
                    title: { $set: data.title },
                    description: { $set: data.description },
                    excerpt: { $set: data.excerpt },
                    location: { $set: data.location },
                    contractType: { $set: data.contractType },
                    closingDate: { $set: data.closingDate },
                    active: { $set: data.active },
                    archived: { $set: data.archived },
                });

                let updateForm = update(this.state.form, {
                    inputDate: { $set: new Date(data.closingDate) }
                });

                this.setState({
                    job: updateJob,
                    oldJob: updateOldJob,
                    form: updateForm,
                    loading: false
                });
            }
        });
    }

    render() {
        return this.state.loading ?
            (<div><p><em>Loading job detail...</em></p></div>) :
            (<div>
                {this.renderHeader()}
                {
                    this.state.mode === "view"
                        ? <ViewJobDetail job={this.state.job} history={this.props.history} admin={this.state.auth} />
                        : this.renderEidtJobForm(this.state.job, this.state.oldJob, this.state.form)
                }
                <Row>
                    <Col md={12}>
                        <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
                    </Col>
                </Row>
            </div>);
    }

    renderHeader() {
        let content = null;
        switch (this.state.mode) {
            case 'view':
                content = this.state.job.title;
                break;
            case 'edit':
                content = "Edit Job - #".concat(this.props.params.id).concat(' ').concat(this.state.job.title);
                break;
            default:
                content = "Create Jobs";
                break;
        }
        return (<Row>
            <Col lg={12} className="d-flex">
                <div className="page-header mb-4">{content}</div>
            </Col>
        </Row>);
    }

    renderModal() {
        let modalHeader = '';
        let wording = '';
        let func = null;

        switch (this.state.modalType) {
            case 0:
                modalHeader = "Please confirm activation";
                wording = (<div>
                    <p>Once the job is closed, applicants will no longer be able to apply the job.</p>
                    <p>Are you sure to close this job?</p>
                </div>);
                func = this.updateJob;
                break;
            case 1:
                modalHeader = "Please confirm archive";
                wording = (<div>
                    <p>Once the job is archived, applicants will no longer be able to apply to this job.</p>
                    <p>The job and applications will be hidden from you and external users.</p>
                    <p>Are you sure to archive this job?</p>
                </div>);
                func = this.updateJob;
                break;
            case 2:
                modalHeader = "Please confirm duplication";
                wording = (<div>
                    <p>Once the job is duplicated, you may want to change the closing date and active the job.</p>
                    <p>Are you sure to duplicate this job?</p>
                </div>);
                func = this.duplicateJob;
                break;
            default:
                break;
        }

        return (<Modal show={this.state.showModal} onHide={this.toggle} backdrop={true} animation={true}>
            <Modal.Header closeButton toggle={this.toggle}>
                <Modal.Title>{modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{wording}</Modal.Body>
            <Modal.Footer>
                <Button color="danger" onClick={func}>Yes</Button>
            </Modal.Footer>
        </Modal>);
    }

    renderEidtJobForm() {
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        return <div className="edit-job">
            {this.renderModal()}
            <Form>
                <FormGroup row>
                    <Label for="jobTitle" lg={2}>Job Title</Label>
                    <Col lg={4}>
                        <Input type="text" name="jobTitle" id="jobTitle" bsSize="sm"
                            disabled={this.state.job.archived ? "disabled" : ""} value={this.state.job.title}
                            onChange={(e) => { this.onJobTitleChange(e) }} placeholder="Job Title" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="contractType" lg={2}>Contract Type</Label>
                    <Col lg={4}>
                        <Input type="select" name="contractType" id="contractType" bsSize="sm" 
                            disabled={this.state.job.archived ? "disabled" : ""} value={this.state.job.contractType}
                            onChange={(e) => { this.onContractTypeChange(e) }}>
                            <option value="">Please select</option>
                            <option value="Temporary">Temporary</option>
                            <option value="Permanent">Permanent</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="location" lg={2}>Location</Label>
                    <Col lg={4}>
                        <Input type="select" name="Location" id="Location" bsSize="sm"
                            disabled={this.state.job.archived ? "disabled" : ""}
                            value={this.state.job.location}
                            onChange={(e) => { this.onLocationChange(e) }}>
                            <option value="">Please select</option>
                            <option value="London">London</option>
                            <option value="New York">New York</option>
                        <option value="Guernsey">Guernsey</option>
                            </Input>
                    </Col>
                </FormGroup>
                <FormGroup className={(this.validClosingDate() || this.state.form.closingDateUntouched) ? "" : "has-error"} row>
                    <Label for="closingDate" lg={2}>Closing Date</Label>
                    <Col lg={4}>
                        <DatePicker name="closingDate" id="closingDate"
                            onChange={this.onClosingDateChange} selected={this.state.form.inputDate}
                            dateFormat="dd/MM/yyyy" locale="en-GB" className="form-control form-control-sm" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="excerpt" lg={2}>Excerpt</Label>
                    <Col lg={8}>
                        <Input name="excerpt" id="Excerpt" type="textarea" rows="5" bsSize="sm"
                            className={this.state.job.archived ? "disabled" : ""}
                            value={this.state.job.excerpt} onChange={(e) => { this.onJobExerptChange(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="jobDescription" lg={2}>Job Description</Label>
                    <Col lg={8}>
                        <CKEditor name="jobdescription" id="jobDescription" disabled={this.state.job.archived}
                            data={this.state.job.description} onChange={(evt) => { this.onJobDescriptionChange(evt) }} />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={12}>
                        <CustomInput type="switch" name="jobClosed" id="jobClosed" label="Active job"
                            checked={this.state.job.active ? "checked" : ""}
                            disabled={this.state.job.archived ? "disabled" : ""}
                            onChange={(e) => { this.onJobActive(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={12}>
                        <CustomInput type="switch" name="jobArchived" id="jobArchived" label="Archive this job"
                            checked={this.state.job.archived ? "checked" : ""} 
                            disabled={this.state.oldJob.archived ? "disabled" : ""}
                            onChange={(e) => this.onJobArchived(e)} />
                    </Col>
                </FormGroup>
                <hr />
                {this.renderButtonGroup()}
            </Form>
        </div>;
    }

    renderButtonGroup() {
        let createButton = <Button color="primary" size="sm" disabled={!this.validForm()} onClick={() => this.onUpdate()}>Create</Button>;
        let updateButton = <Button color="primary" size="sm" onClick={() => this.onUpdate()}>Update</Button>;
        let cancelButton = <Button size="sm" onClick={() => this.props.history.push('/')}>Cancel</Button>;
        let duplicateButton = <Button color="success" size="sm" onClick={() => this.onDuplicate()}>Duplicate this job</Button>;

        if (this.state.mode === "edit" && this.state.job.archived) {
            return (<FormGroup row><Col lg={2}></Col><Col lg={6}>{duplicateButton}</Col></FormGroup>);
        }
        else if (this.state.mode === "edit" && !this.state.job.archived) {
            return (<FormGroup row>
                <Col lg={2}></Col>
                <Col lg={6}>{cancelButton}&nbsp;&nbsp;{updateButton}</Col>
            </FormGroup>);
        }
        else {
            return (<FormGroup row>
                <Col lg={2} className="text-right"></Col>
                <Col lg={6}>{createButton}&nbsp;&nbsp;{cancelButton}</Col>
            </FormGroup>);
        }
    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }             

    onJobTitleChange(e) {
        this.setState({
            form: update(this.state.form, {
                titleUntouched: { $set: false }
            }),
            job: update(this.state.job, {
                title: { $set: e.target.value }
            })
        });
    }

    onLocationChange(e) {
        this.setState({
            form: update(this.state.form, {
                locationUntouched: { $set: false }
            }),
            job: update(this.state.job, {
                location: { $set: e.target.value }
            })
        });
    }

    onContractTypeChange(e) {
        this.setState({
            form: update(this.state.form, {
                contractType: { $set: false }
            })
        });

        this.setState({
            job: update(this.state.job, {
                contractType: { $set: e.target.value }
            })
        });
    }

    onClosingDateChange(date) {
        this.setState({
            form: update(this.state.form, {
                closingDateUntouched: { $set: false },
                inputDate: { $set: date }
            }),
            job: update(this.state.job, {
                closingDate: { $set: date.toISOString() }
            })
        });
    }

    onJobDescriptionChange(evt) {
        var data = evt.editor.getData();
        this.setState({
            form: update(this.state.form, {
                descriptionUntouched: { $set: false }
            }),
            job: update(this.state.job, {
                description: { $set: data }
            })
        });
    }

    onJobExerptChange(e) {
        this.setState({
            form: update(this.state.form, {
                excerpt: { $set: e.target.value }
            }),
            job: update(this.state.job, {
                excerpt: { $set: e.target.value }
            })
        });
    }

    onJobActive(e) {
        this.setState({
            job: update(this.state.job, {
                active: {
                    $set: (!this.state.job.archived) && e.target.checked
                }
            })
        });
    }

    onJobArchived(e) {
        this.setState({
            job: update(this.state.job, {
                archived: { $set: e.target.checked },
                active: { $set: e.target.checked ? false : this.state.job.active }
            })
        });
    }

    onJobUpdate(e) {
        if (this.state.backupJob.active && !this.state.job.active) {
            this.setState({ showModal: true, modalType: 0 });
        }
    }

    validJobTitle = (title) => {
        return title && title.trim().length > 0;
    }

    validLocation = (location) => {
        return location && location.trim().length > 0;
    }

    validContractType = (contractType) => {
        return contractType && contractType.trim().length > 0;
    }

    validClosingDate = (date) => {
        return date instanceof Date && !isNaN(date.valueOf());
    }
    
    validDescription = (description) => {
        return description && description.trim().length > 0;
    }

    validForm() {
        return this.validJobTitle(this.state.job.title)
            && this.validLocation(this.state.job.location)
            && this.validContractType(this.state.job.contractType)
            //&& this.validClosingDate(this.state.job.closingDate)
            && this.validDescription(this.state.job.description);
    }

    onUpdate() {
        if (this.state.oldJob.active && !this.state.job.active) {
            this.setState({ showModal: true, modalType: 0 });
        }
        else if (this.state.job.archived) {
            this.setState({ showModal: true, modalType: 1 });
        }
        else if (this.state.mode === "create") {
            this.createJob();

        } else if (this.state.mode === "edit") {
            this.updateJob();
        }
    }

    onDuplicate() {
        this.setState({ showModal: true, modalType: 2 });
    }

    createJob() {
        DataService.createJob(this.state.job)
            .then(data => {
                this.props.history.push('/job/view/' + data.id);

                this.setState({
                    alert: update(this.state.alert, {
                        alertType: { $set: 'info' },
                        message: { $set: 'Job created.' }
                    })
                });
            })
            .then(err => {
                if (err) {
                    this.setState({
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: err }
                        })
                    });
                }
            });
    }

    updateJob() {
        DataService.updateJob(this.state.job)
            .then(data => {
                this.props.history.push('/job/view/' + data.id);

                this.setState({
                    job: update(this.state.job, {
                        id: { $set: data.id },
                        title: { $set: data.title },
                        excerpt: { $set: data.excerpt },
                        description: { $set: data.description },
                        location: { $set: data.location },
                        contractType: { $set: data.contractType },
                        closingDate: { $set: data.closingDate },
                        active: { $set: data.active },
                        archived: { $set: data.archived },
                    }),
                    oldJob: update(this.state.oldJob, {
                        id: { $set: data.id },
                        title: { $set: data.title },
                        excerpt: { $set: data.excerpt },
                        description: { $set: data.description },
                        location: { $set: data.location },
                        contractType: { $set: data.contractType },
                        closingDate: { $set: data.closingDate },
                        active: { $set: data.active },
                        archived: { $set: data.archived },
                    }),
                    form: update(this.state.form, {
                        inputDate: { $set: new Date(data.closingDate) }
                    }),
                    alert: update(this.state.alert, {
                        alertType: { $set: 'info' },
                        message: { $set: 'Job details have been updated at ' + new Date().toLocaleTimeString() + '.' }
                    })
                });
            }).then(err => {
                if (err) {
                    this.setState({
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: err }
                        })
                    });
                }
            });
    }

    duplicateJob() {
        var job = this.state.job;
        job.closingDate = moment(new Date()).add(1, 'M');
        job.active = true;
        job.archived = false;

        DataService.duplicateJob(job)
            .then(data => {
                this.props.history.push('/job/edit/' + data.id + '/duplicated');

                this.setState({
                    id: data.id,
                    job: update(this.state.job, {
                        id: { $set: data.id },
                        title: { $set: data.title },
                        excerpt: { $set: data.excerpt },
                        description: { $set: data.description },
                        location: { $set: data.location },
                        contractType: { $set: data.contractType },
                        closingDate: { $set: data.closingDate },
                        active: { $set: data.active },
                        archived: { $set: data.archived },
                    }),
                    oldJob: update(this.state.oldJob, {
                        id: { $set: data.id },
                        title: { $set: data.title },
                        excerpt: { $set: data.excerpt },
                        description: { $set: data.description },
                        location: { $set: data.location },
                        contractType: { $set: data.contractType },
                        closingDate: { $set: data.closingDate },
                        active: { $set: data.active },
                        archived: { $set: data.archived },
                    }),
                    form: update(this.state.form, {
                        inputDate: { $set: new Date(data.closingDate) }
                    }),
                    showModal: false,
                    alert: update(this.state.alert, {
                        alertType: { $set: 'info' },
                        message: { $set: 'Job is now duplicated.' }
                    })
                });
            })
            .catch(err => {
                this.setState({
                    alertType: 'error',
                    message: err
                });
            });
    }
}