import React, { Component } from 'react';
import DisplayAlert from './DisplayAlert';
import { TokenHelper } from '../helpers/TokenHelper';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import { DataService } from '../helpers/DataService';
import { APIBASEURL } from '../helpers/SiteConfig';

export class EditUser extends Component {
    constructor(props) {
        super(props); 
        
        this.state = {
            user: null,
            loading: true,
            email: '',
            password: '',
            confirmPassword: '',
            firstNameUntouched: true,
            lastNameUntouched: true,
            emailUntouched: true,
            passwordUntouched: true,
            confirmPasswordUntouched: true,
            line1Untouched: true,
            cityUntouched: true,
            postcodeUntouched: true,
            countryUntouched: true,
            updateAccountAlertType: '',
            updateAccountMessage: '',
            updateProfileAlertType: '',
            updateProfileMessage: '',
            updateContactAlertType: '',
            updateContactMessage: '',
            alert: {
                alertType: '',
                message: ''
            }
        };
    }

    componentWillMount() {
        this.fetchUserInfo(this.props.userId);
    }
    
    render() {
        let content = this.renderEditProfileForm();

        return (<div className='content-container'>
            {content}
            <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
        </div>);
    }
    
    fetchUserInfo(id) {
        DataService.getUserById(id)
            .then(data => {
                let alert = {
                    alertType: '',
                    message: ''
                };

                this.setState({
                    user: data,
                    loading: false,
                    email: data.email ? data.email : '',
                    alert: alert
                });

            }).catch((error) => {
                let alert = {
                    alertType: 'error',
                    message: 'Something is wrong when fetching user data.'
                };

                this.setState({
                    user: null,
                    loading: false,
                    email: '',
                    password: '',
                    confirmPassword: '',
                    alert: alert
                })
            });
    }

    renderEditProfileForm() {
        let passwordSection = null
        let accountStatus = null;

        let btnAccountStatus = this.state.user && this.state.user.active
            ? <Button color="warning" size="sm" onClick={(e) => { this.onActiveChange(e) }}>Deactivate account</Button>
            : <Button color="warning" size="sm" onClick={(e) => { this.onActiveChange(e) }}>Re-activiate account</Button> 

        let btnAccountType = this.state.user && this.state.user.admin
            ? <Button color="warning" size="sm" onClick={(e) => { this.onAdminChange(e) }}>Change account type to User</Button>
            : <Button color="warning" size="sm" onClick={(e) => { this.onAdminChange(e) }}>Change account type to Admin</Button>

        accountStatus = this.state.user && this.props.auth.admin ? (<div>
            <FormGroup row>
                <Label lg={2}>Account Type</Label>
                <Col lg={6}>
                    {btnAccountType}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label lg={2}>Account Status</Label>
                <Col lg={6}>
                    {btnAccountStatus}
                </Col>
            </FormGroup>
        </div>)
            : (<FormGroup row>
                <Col lg={2}></Col>
                <Col lg={6}>
                    <Button color="primary" size="sm" disabled={!this.validProfile()}
                        onClick={() => { this.onUpdateProfile() }}>Update Profile</Button>
                </Col>
            </FormGroup>);

        passwordSection = this.state.user && !this.props.auth.admin ? (<div>
            <FormGroup row>
                <Label for="Password" lg={2}>Password <span className="red">*</span></Label>
                <Col lg={6}>
                    <Input type="password" name="Password" id="Password" bsSize="sm" value={this.state.password}
                        onChange={(e) => this.onPasswordChange(e)} placeholder="Password" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="confirmPassword" lg={2}>Confirm Password <span className="red">*</span></Label>
                <Col lg={6}>
                    <Input type="password" id="confirmPassword" name="confirmPassword" bsSize="sm" value={this.state.confirmPassword}
                        onChange={(e) => this.onConfirmPasswordChange(e)} placeholder="Confirm Password" />
                </Col>
            </FormGroup>
        </div>) : null;
        
        let content = this.state.user ? (<div>
            <Row>
                <Col lg={12}>
                    <span className="page-header">
                        {"Edit User: #" + this.state.user.userId + " " + this.state.user.firstName + " " + this.state.user.lastName}
                    </span>
                </Col>
            </Row>
            <div className="block">
                <Row>
                    <Col lg={12}>
                        <span className="block-header">Basic Profile</span>
                    </Col>
                </Row>
                <Form>
                    <FormGroup row>
                        <Label for="firstName" lg={2}>First Name <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="firstName" id="firstName" bsSize="sm"
                                value={this.state.user.firstName ? this.state.user.firstName : ''}
                                onChange={(e) => this.onFirstNameChange(e)} placeholder="First Name" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="lastName" lg={2}>Last Name <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="lastName" id="lastName" bsSize="sm"
                                value={this.state.user.lastName ? this.state.user.lastName : ''}
                                onChange={(e) => this.onLastNameChange(e)} placeholder="Last Name" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <Button color="primary" size="sm" disabled={!this.validProfile()} onClick={() => { this.onUpdateProfile() }}>Update Profile</Button>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <DisplayAlert alertType={this.state.updateProfileAlertType} header={''} message={this.state.updateProfileMessage} />
                        </Col>
                    </FormGroup>
                </Form>
            </div>

            <div className="block">
                <Row>
                    <Col lg={12}>
                        <span className="block-header">Account</span>
                    </Col>
                </Row>
                <Form>
                    <FormGroup row>
                        <Label for="Email" lg={2}>Email</Label>
                        <Col lg={6}>
                            <Input type="text" name="Email" id="Email" bsSize="sm"
                                value={this.state.user.email ? this.state.user.email : ''} disabled={true} />
                        </Col>
                    </FormGroup>
                    {passwordSection}
                    {accountStatus}
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <DisplayAlert alertType={this.state.updateAccountAlertType} header={''} message={this.state.updateAccountMessage} />
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="block">
                <Row>
                    <Col lg={12}>
                        <span className="block-header">Contact Details</span>
                    </Col>
                </Row>
                <Form>
                    <FormGroup row>
                        <Label for="addressLine1" lg={2}>Address Line 1 <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="addressLine1" id="addressLine1" bsSize="sm"
                                value={this.state.user.line1 ? this.state.user.line1 : ''}
                                placeholder="Address Line 1" onChange={(e) => { this.onLine1Changed(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="addressLine2" lg={2}>Address Line 2</Label>
                        <Col lg={6}>
                            <Input type="text" name="addressLine2" id="addressLine2" bsSize="sm"
                                value={this.state.user.line2 ? this.state.user.line2 : ''}
                                placeholder="Address Line 2" onChange={(e) => { this.onLine2Changed(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="City" lg={2}>City <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="City" id="City" bsSize="sm"
                                value={this.state.user.city ? this.state.user.city : ''}
                                placeholder="City" onChange={(e) => { this.onCityChanged(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="County" lg={2}>County</Label>
                        <Col lg={6}>
                            <Input type="text" name="County" id="County" bsSize="sm"
                                value={this.state.user.county ? this.state.user.county : ''}
                                placeholder="County" onChange={(e) => { this.onCountyChanged(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Postcode" lg={2}>Postcode <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="Postcode" id="Postcode" bsSize="sm"
                                value={this.state.user.postcode ? this.state.user.postcode : ''}
                                placeholder="Postcode" onChange={(e) => { this.onPostcodeChanged(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Country" lg={2}>Country <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="Country" id="Country" bsSize="sm"
                                value={this.state.user.country ? this.state.user.country : ''}
                                placeholder="Country" onChange={(e) => { this.onCountryChanged(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="contactNumber" lg={2}>Contact Number <span className="red">*</span></Label>
                        <Col lg={6}>
                            <Input type="text" name="contactNumber" id="contactNumber" bsSize="sm"
                                value={this.state.user.phone ? this.state.user.phone : ''}
                                placeholder="Contact Number" onChange={(e) => { this.onPhoneChanged(e) }} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <Button color="primary" size="sm" disabled={!this.validContact()}
                                onClick={() => this.onUpdateContactDetails()}>Update Contact Details</Button>
                        </Col>
                    </FormGroup>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <DisplayAlert alertType={this.state.updateContactAlertType} header={''} message={this.state.updateContactMessage} />
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>) : (<div><p><em>Cannot find this user.</em></p></div>);
        
        return this.state.loading ? (<div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>) : content;
    }

    onFirstNameChange(e) {
        let user = this.state.user;
        if (user) {
            user.firstName = e.target.value;
            this.setState({ user: user });
        }
    }

    onLastNameChange(e) {
        let user = this.state.user;
        if (user) {
            user.lastName = e.target.value;
            this.setState({ user: user });
        }
    }

    onEmailChange(e) {
        let user = this.state.user;
        if (user) {
            user.email = e.target.value;
            this.setState({ user: user });
        }
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    onConfirmPasswordChange(e) {
        this.setState({
           confirmPassword: e.target.value
        });
    }

    onPhoneChanged(e) {
        let user = this.state.user;
        if (user) {
            user.phone = e.target.value;
            this.setState({ user: user });
        }
    }

    onLine1Changed(e) {
        let user = this.state.user;
        if (user) {
            user.line1 = e.target.value;
            this.setState({ user: user });
        }
    }

    onLine2Changed(e) {
        let user = this.state.user;
        if (user) {
            user.line2 = e.target.value;
            this.setState({ user: user });
        }
    }

    onCityChanged(e) {
        let user = this.state.user;
        if (user) {
            user.city = e.target.value;
            this.setState({ user: user });
        }
    }

    onCountyChanged(e) {
        let user = this.state.user;
        if (user) {
            user.county = e.target.value;
            this.setState({ user: user });
        }
    }

    onPostcodeChanged(e) {
        let user = this.state.user;
        if (user) {
            user.postcode = e.target.value;
            this.setState({ user: user });
        }
    }

    onCountryChanged(e) {
        let user = this.state.user;
        if (user) {
            user.country = e.target.value;
            this.setState({ user: user });
        }
    }

    onActiveChange(e) {
        let user = this.state.user;
        if (user) {
            user.active = !user.active;
            this.setState({ user: user });
        }
        this.onUpdateAccountStatus();
    }

    onAdminChange(e) {
        let user = this.state.user;
        if (user) {
            user.admin = !user.admin;
            this.setState({ user: user });
        }
        this.onUpdateAccountType();
    }

    validAccount() {
        let valid = true;

        if (!this.state.user) {
            valid = false;
        } else {
            if (this.state.password !== this.state.confirmPassword)
                valid = false;
        }

        return valid;
    }

    validContact() {
        let valid = true;

        if (!this.state.user) {
            valid = false;
        }
        else {
            if (this.state.user.line1 == null || this.state.user.line1.trim().length <= 0)
                valid = false;

            if (this.state.user.postcode == null || this.state.user.postcode.trim().length <= 0)
                valid = false;

            if (this.state.user.city == null || this.state.user.city.trim().length <= 0)
                valid = false;

            if (this.state.user.country == null || this.state.user.country.trim().length <= 0)
                valid = false;
        }

        return valid;
    }

    validProfile() {
        let valid = true;

        if (!this.state.user) {
            valid = false;
        } else {
            if (!this.state.user.firstName || this.state.user.firstName.trim().length <= 0)
                valid = false;

            if (!this.state.user.lastName || this.state.user.lastName.trim().length <= 0)
                valid = false;
        }

        return valid;
    }

    onUpdateProfile() {
        const access_token = TokenHelper.getJwt();

        if (access_token && this.state.user) {

            let data = JSON.stringify({
                'userId': this.state.user.userId,
                'firstName': this.state.user.firstName,
                'lastName': this.state.user.lastName
            });

            let url = APIBASEURL.concat('api/user/' + this.state.user.userId + '/profile');

            fetch(url, {
                method: 'PUT',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '.concat(access_token)
                }),
                body: data
            })
                .then((rsp) => rsp.json())
                .then((data) => {
                    console.log(data);
                    if (data.succeeded) {
                        this.setState({
                            updateProfileAlertType: 'info',
                            updateProfileMessage: 'Profile have been updated.'
                        });
                    }
                    else {
                        this.setState({
                            updateProfileAlertType: 'error',
                            updateProfileMessage: 'Update failed. ' + data.message
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        alertType: 'error',
                        message: 'Update failed. ' + error
                    });
                });
        }
    }

    onUpdateContactDetails() {
        const access_token = TokenHelper.getJwt();

        if (access_token && this.state.user) {
            let data = JSON.stringify({
                'userId': this.state.user.userId,
                'phone': this.state.user.phone,
                'addressId': this.state.user.addressId,
                'line1': this.state.user.line1,
                'line2': this.state.user.line2,
                'city': this.state.user.city,
                'county': this.state.user.county,
                'postcode': this.state.user.postcode,
                'country': this.state.user.country
            }); 
            
            let method = null;
            if (this.state.user.addressId !== null && this.state.user.addressId > 0) {
                method = "PUT";
            } else
                method = "POST";

            let url = APIBASEURL.concat('api/user/' + this.state.user.userId + '/address');

            fetch(url, {
                method: method,
                credentials: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '.concat(access_token)
                }),
                body: data
            })
                .then((rsp) => rsp.json())
                .then((data) => {
                    console.log(data);
                    if (data.succeeded) {
                        this.setState({
                            updateContactAlertType: 'info',
                            updateContactMessage: 'Contact details have been updated.'
                        });
                    }
                    else {
                        this.setState({
                            updateContactAlertType: 'error',
                            updateContactMessage: "Update failed. " + data.message
                        });
                    }

                }).catch((error) => {
                    this.setState({
                        updateContactAlertType: 'error',
                        updateContactMessage: "Update failed. " + error
                    });
                });
        }
    }

    onUpdateAccountStatus() {
        const access_token = TokenHelper.getJwt();

        if (this.state.user && access_token && this.props.auth.admin) {

            let url = APIBASEURL.concat('api/user/' + this.state.user.userId + '/status/' + this.state.user.active);

            fetch(url, {
                method: 'PUT',
                credentials: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '.concat(access_token)
                })
            })
                .then(rsp => rsp.json())
                .then(data => {
                    console.log(data);

                    if (data.succeeded) {
                        this.setState({
                            updateAccountAlertType: 'info',
                            updateAccountMessage: "Account status has been updated."
                        });
                    }
                    else {
                        this.setState({
                            updateAccountAlertType: 'error',
                            updateAccountMessage: data.message
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        updateAccountAlertType: 'error',
                        updateAccountMessage: "Update failed. " + error
                    });
                });
        }
    }

    onUpdateAccountType() {
        const access_token = TokenHelper.getJwt();

        if (this.state.user && access_token && this.props.auth.admin) {
            const url = APIBASEURL.concat('api/user/' + this.state.user.userId + '/authorization/' + this.state.user.admin);

            fetch(url, {
                method: 'PUT',
                credentials: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '.concat(access_token)
                })
            })
                .then(rsp => rsp.json())
                .then(data => {
                    if (data.succeeded) {
                        this.setState({
                            updateAccountAlertType: 'info',
                            updateAccountMessage: "Account type has been updated."
                        });
                    }
                    else {
                        this.setState({
                            updateAccountAlertType: 'error',
                            updateAccountMessage: data.message
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        updateAccountAlertType: 'error',
                        updateAccountMessage: "Update failed. " + error
                    });
                });
        }
    }

    onUpdateAccount() {
        const access_token = TokenHelper.getJwt();

        if (this.state.user && access_token && !this.props.auth.admin && this.validAccount()) {
            let data = JSON.stringify({
                'email': this.state.email,
                'password': this.state.password
            });

            fetch('api/account/password/reset', {
                method: 'POST',
                credentials: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '.concat(access_token)
                }),
                body: data
            })
                .then(rsp => rsp)
                .then(data => {
                    this.setState({
                        updateAccountAlertType: 'info',
                        updateAccountMessage: "Password has been updated."
                    });

                }).catch((error) => {
                    this.setState({
                        updateAccountAlertType: 'error',
                        updateAccountMessage: "Password update failed (error) " + error
                    });
                });
        }
    }
}