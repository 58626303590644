import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Badge } from 'reactstrap';
import './SearchApplication.css';
import { SearchForm } from '../SearchForm';
import { APIBASEURL } from '../../helpers/SiteConfig';
import { ApplicationService } from '../../helpers/ApplicationService';
import { Pagination, Spinner } from 'react-bootstrap';

const toolkit = (isAdmin, applicationId, jobId, editable) => {
    if (!isAdmin && editable) {
        return (<div>
            <a href={APIBASEURL.concat('apply/job/').concat(jobId.toString())}>Edit</a>
        </div>);
    }
    else {
        return (<div>
            <a href={APIBASEURL.concat('applications/view/').concat(applicationId.toString())}>Review</a>
        </div>);
    }
}

const statusBadge = (status) => {
    let content = null;
    switch (status) {
        case "accepted":
            content = (<Badge color="success">{status}</Badge>);
            break;

        case "rejected":
            content = (<Badge color="danger">{status}</Badge>);
            break;

        case "shortlisted":
            content = (<Badge color="info">{status}</Badge>);
            break;

        default:
            content = (<Badge color="primary">{status}</Badge>);
            break;
    }
    return content;
};

export class SearchApplication extends Component {
    constructor(props) {
        super(props);

        const p = (this.props.params.page && !isNaN(parseInt(this.props.params.page)) && parseInt(this.props.params.page) > 1) ? parseInt(this.props.params.page) : 1

        this.state = {
            auth: this.props.auth,
            applications: [],
            loading: true,
            keywords: this.props.params.keywords,
            currentPage: p,
            total: 0,
            totalPages: 0,
            boundary: [1, 1],
            ellipsis: 5,
            limit: 10,
            hasNextPage: true,
            hasPrevPage: true,
            displayPagination: true
        };

        this.onChangeSearchKeywords = this.onChangeSearchKeywords.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClickEllipsisPrev = this.onClickEllipsisPrev.bind(this);
        this.onClickEllipsisNext = this.onClickEllipsisNext.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevSate) {
        if (nextProps.auth !== prevSate.auth) {
            return { auth: nextProps.auth };
        }
        return null;
    }

    async componentDidMount() {
        await this.fetchApplication(this.state.currentPage);
        await this.getApplicationCount();
    }

    async fetchApplication(p) {
        let data = [];
        data = await ApplicationService.getAllApplications(p);

        this.setState(prevState => ({
            applications: data.length > 0 ? data : [],
            loading: false,
            displayPagination: true
        }));
    }

    async getApplicationCount() {
        const count = await ApplicationService.totalApplicationCount();
        const totalPages = Math.ceil(parseInt(count) / this.state.limit);

        let lowBound = 1;
        let highBound = 1;

        if (totalPages >= 10) {
            if (this.state.currentPage <= 10) {
                lowBound = 1;
                highBound = 10;
            }
            else if (this.state.currentPage >= totalPages - 9) {
                lowBound = totalPages - 9;
                highBound = totalPages;
            }
            else {
                lowBound = this.state.currentPage;
                highBound = this.state.currentPage + 9 <= totalPages ? this.state.currentPage + 9 : totalPages
            }
        }
        else
            highBound = totalPages;

        this.setState({
            total: parseInt(count),
            totalPages: totalPages,
            boundary: [lowBound, highBound],
            hasNextPage: this.state.currentPage < totalPages,
            hasPrevPage: this.state.currentPage > 1
        });
    }

    render() {
        let pagination = this.state.applications.length > 0 ? this.renderPagination(this.state.currentPage, this.state.totalPages) : <div />;
        let contents = this.state.loading ? (<div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>) : this.renderApplications(this.state.applications);

        return (<div>
            <Row>
                <Col lg={9} className="d-flex">
                    <div className="page-header mb-5">Job Applications</div>
                </Col>
                <Col lg={3}>
                    <SearchForm keywords={this.state.keywords}
                        onChangeSearchKeywords={this.onChangeSearchKeywords} onSearch={this.onSearch} />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>{contents}</Col>
            </Row>
            {this.state.displayPagination ? pagination : (<div />)}
        </div>);
    }

    renderApplications = () => {
        let content = this.state.applications.length <= 0
            ? (<p><em>You have not created any application yet.</em></p>)
            : this.state.applications.map(appl =>
                <Card key={'application_' + appl.applicationId} className="card-block">
                    <CardHeader>
                        <Row>
                            <Col lg={4}><span className="strong">{appl.user.firstName + ' ' + appl.user.lastName}</span></Col>
                            <Col lg={6}><span className="strong">Job Title:</span> {appl.jobTitle}</Col>
                            <Col lg={2} className="text-right">
                                {statusBadge(appl.status)}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {appl.coverLetterExcerpt == null ? "" : appl.coverLetterExcerpt}
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col lg={12} className="text-right">
                                {this.state.auth.authenticated ? toolkit(this.state.auth.admin, appl.applicationId, appl.jobId, appl.editable) : null}
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            );
        return content;
    }

    renderPagination(p, totalPages) {
        let firstTab = p > 1 ? (<Pagination.First href={this.pageLink(1)} />) : null;
        let lastTab = p < totalPages ? (<Pagination.Last href={this.pageLink(totalPages)} />) : null;

        let prevTab = this.state.hasPrevPage ? (<Pagination.Prev key={p - 1} href={this.pageLink(p - 1)} />) : null;
        let nextTab = this.state.hasNextPage ? (<Pagination.Next key={p + 1} href={this.pageLink(p + 1)} />) : null;

        const lowBound = this.state.boundary[0];
        const highBound = this.state.boundary[1];
        let prevEllipsis = lowBound > 1 ? (<Pagination.Ellipsis onClick={this.onClickEllipsisPrev} />) : null;
        let nextEllipsis = highBound < totalPages ? (<Pagination.Ellipsis onClick={this.onClickEllipsisNext} />) : null;

        let pageItems = [];
        for (var i = this.state.boundary[0]; i <= this.state.boundary[1]; i++) {
            pageItems.push(<Pagination.Item key={'pagination_item_' + i} href={this.pageLink(i)} active={p === i}>{i}</Pagination.Item>);
        }

        return (<Row>
            <Col lg={12}>
                <Pagination size="md" className="justify-content-center">
                    {firstTab}
                    {prevTab}
                    {prevEllipsis}
                    {
                        [...Array(pageItems)].map(item => {
                            return (item);
                        })
                    }
                    {nextEllipsis}
                    {nextTab}
                    {lastTab}
                </Pagination>
            </Col>
        </Row>);
    }

    onClickEllipsisPrev() {
        const lowBound = this.state.boundary[0] - this.state.ellipsis < 1 ? 1 : this.state.boundary[0] - this.state.ellipsis;
        const highBound = this.state.boundary[1] - this.state.ellipsis < 1 ? 1 : this.state.boundary[1] - this.state.ellipsis;

        this.setState({
            boundary: [lowBound, highBound]
        });
    }

    onClickEllipsisNext() {
        const lowBound = this.state.boundary[0] + this.state.ellipsis > this.state.totalPages ? this.state.totalPages : this.state.boundary[0] + this.state.ellipsis;
        const highBound = this.state.boundary[1] + this.state.ellipsis > this.state.totalPages ? this.state.totalPages : this.state.boundary[1] + this.state.ellipsis;

        this.setState({
            boundary: [lowBound, highBound]
        });
    }

    moveBoundary = (forward) => {
        if (forward) {
            let lowBound = this.state.boundary[0] + 6 < this.state.totalPages ? this.state.boundary[0] + 6 : this.state.totalPages;
            let highBound = this.state.boundary[1] + 6 <= this.state.totalPages ? this.state.boundary[1] + 6 : this.state.totalPages;

            this.setState({
                boundary: [lowBound, highBound]
            })
        }
        else {
            let lowBound = this.state.boundary[0] - 6 >= 0 ? this.state.boundary[0] - 6 : 0;
            let highBound = this.state.boundary[1] - 6 > this.state.totalPages ? this.state.boundary[1] - 6 : 0;

            this.setState({
                boundary: [lowBound, highBound]
            })
        }
    }

    pageLink = (p) => {
        if (this.state.keywords === undefined || this.state.keywords === null || this.state.keywords === '')
            return APIBASEURL.concat('applications/p/' + p);
        else
            return APIBASEURL.concat('applications/p/' + p + '/' + this.state.keywords);
    }

    onChangeSearchKeywords(e) {
        this.setState({
            keywords: e.target.value
        });
    }

    onSearch(e) {
        e.preventDefault();
        const keywords = this.state.keywords;

        if (keywords !== undefined && keywords !== NaN && keywords !== '' && keywords.trim() !== '') {
            ApplicationService.search(keywords, this.state.currentPage)
                .then(data => {
                    this.setState({
                        applications: data,
                        displayPagination: false
                    })
                })
                .catch(err => {
                });
        }
        else {
            this.fetchApplication(this.state.currentPage);
        }
    }
}