import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


export class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        };
    }

    componentDidMount() {
        var ok = this.props.logout();
        this.setState({ redirect: ok });
    }

    render() {
        return (this.state.redirect ? <Redirect to='/' /> : null);
    }
}