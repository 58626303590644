import React from 'react';
import { Alert } from 'reactstrap';

const DisplayAlert = ({ alertType, header, message }) => {
    let content = null;

    switch (alertType) {
        case "error":
            content = <Alert color="danger" fade={true}>
                {(header && header !== '') ? (<div><h4>{header}</h4><hr /></div>) : ''}
                {message}
            </Alert>;
            break;
        case "info":
            content = <Alert color="info" fade={true}>
                {(header && header !== '') ? (<div><h4>{header}</h4><hr /></div>) : ''}
                {message}
            </Alert>;
            break;
        case "warning":
            content = <Alert color="warning" fade={true}>
                {(header && header !== '') ? (<div><h4>{header}</h4></div>) : ''}
                {message}
            </Alert>;
            break;
        default:
            break;
    }

    return (<div className="block">{content}</div>);
};

export default DisplayAlert;