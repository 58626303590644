import React, { Component } from 'react';
import { Col, Row, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import { ApplicationService } from '../../helpers/ApplicationService';
import { DisplayApplication } from './DisplayApplication';
import { TokenHelper } from '../../helpers/TokenHelper';
import { APIBASEURL } from '../../helpers/SiteConfig';
import './ViewApplication.css';
import update from 'immutability-helper';
import history from '../../history';

export class ViewApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: Number(this.props.id ? this.props.id : '0'),
            auth: this.props.auth,
            application: null,
            loading: true,
            print: this.props.print,
            backdrop: true,
            showModal: false,
            modalType: 0,
            alert: {
                alertType: '',
                message: ''
            }
        }
        
        this.shortlist = this.shortlist.bind(this);
        this.reject = this.reject.bind(this);
        this.accept = this.accept.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            return { auth: nextProps.auth };
        }
        if (nextProps.id.toString() !== prevState.id.toString()) {
            return { id: Number(nextProps.params.id ? nextProps.params.id : '0') };
        }
        return null;
    }

    componentDidMount() {
        if (this.state.id > 0)
            this.fetchApplication();
    }

    fetchApplication() {
        ApplicationService.getApplication(this.state.id)
            .then(data => {

                if (data.applicationId > 0) {
                    this.setState({
                        application: data,
                        loading: false
                    });
                }

            }).catch((err) => {
                this.setState({
                    application: null,
                    alert: update(this.state.alert, {
                        alertType: { $set: 'error' },
                        message: { $set: err }
                    })
                });
            });
    }

    render() {
        let content = (this.state.loading || this.state.application == null)
            ? (<div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner></div>)
            : (<DisplayApplication history={history} data={this.state.application}
                auth={this.state.auth} reject={this.reject}
                accept={this.accept} shortlist={this.shortlist}
                fetchFile={this.fetchFile} />);

        return (<div>
            <div id="printArea">
                <Row><Col lg={12}>{content}</Col></Row>
            </div>
        </div>);
    }
    
    shortlist(application) {
        ApplicationService.shorlist(application)
            .then(rsp => {
                if (rsp.succeeded) {
                    this.updateStateApplication(rsp.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    reject(application) {
        ApplicationService.reject(application)
            .then(rsp => {
                let obj = rsp.data;
                console.log(rsp);
                if (rsp.succeeded) {
                    this.setState({
                        application: obj
                    });
                    this.refreshList(obj);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    accept(application) {
        ApplicationService.accept(application)
            .then(rsp => {
                if (rsp.succeeded) {
                    this.setState({
                        application: rsp.data
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    updateStateApplication = (appl) => {
        if (this.state.application !== null) {
            let updatedAppl = update(this.state.application, {
                jobId: { $set: appl.jobId },
                jobTitle: { $set: appl.jobTitle },
                acknowledged: { $set: appl.acknowledged },
                applicable: { $set: appl.applicable },
                applicationId: { $set: appl.applicationId },
                coverLetter: { $set: appl.coverLetter },
                coverLetterExcerpt: { $set: appl.coverLetterExcerpt },
                createdAt: { $set: appl.createdAt },
                currency: { $set: appl.currency },
                department: { $set: appl.department },
                editable: { $set: appl.editable },
                eligibility: { $set: appl.eligibility },
                experience: { $set: appl.experience },
                keywords: { $set: appl.keywords },
                location: { $set: appl.location },
                qualification: { $set: appl.qualification },
                read: { $set: appl.read },
                salary: { $set: appl.salary },
                salaryInfo: { $set: appl.salaryInfo },
                status: { $set: appl.status },
                updatedAt: { $set: appl.updatedAt },
                user: {
                    userId: { $set: appl.user.userId },
                    firstName: { $set: appl.user.firstName },
                    lastName: { $set: appl.user.lastName },
                    email: { $set: appl.user.email },
                    phone: { $set: appl.user.phone },
                    addressId: { $set: appl.user.addressId },
                    line1: { $set: appl.user.line1 },
                    line2: { $set: appl.user.line2 },
                    city: { $set: appl.user.city },
                    county: { $set: appl.user.county },
                    country: { $set: appl.user.country },
                    postcode: { $set: appl.user.postcode },
                }
            });
            
            this.setState({
                application: updatedAppl
            });
        }
        else {
            this.setState({ application: appl })
        }
    }

    fetchFile(application, fileName) {
        const url = APIBASEURL.concat("api/application/").concat(application.applicationId.toString()).concat("/file");
        const token = TokenHelper.getJwt();

        if (token) {
            fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then((rsp) => rsp.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                })
                .catch(() => {
                });
        }
    }
}