import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { ApplicationContent } from './ApplicationContent';
import { ApplicationService } from '../../helpers/ApplicationService';
import './ApplyJob.css';
import update from 'immutability-helper';

export class ApplyJob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: this.props.auth, 
            jobId: this.props.jobId,
            application: null,
            stage: 1,
            loading: true,
            alert: this.props.alert
        }

        this.initializeApplication(this.props.jobId);

        this.onEligibilityChange = this.onEligibilityChange.bind(this);
        this.onAcknowledgementChange = this.onAcknowledgementChange.bind(this);
        this.onChangeStage = this.onChangeStage.bind(this);
        this.onBasicDetailSubmit = this.onBasicDetailSubmit.bind(this);
        this.onCareerDetailsSubmit = this.onCareerDetailsSubmit.bind(this);
        this.onCVUpload = this.onCVUpload.bind(this);
        this.onCVRemove = this.onCVRemove.bind(this);
        this.onChangeStage = this.onChangeStage.bind(this);
    }

    initializeApplication(id) {
        ApplicationService.getUserApplicationByJob(id).then(data => {
            this.setState({
                application: {
                    acknowledged: data.acknowledged,
                    applicable: data.applicable,
                    applicationId: data.applicationId,
                    coverLetter: data.coverLetter,
                    coverLetterExcerpt: data.coverLetterExcerpt,
                    createdAt: data.createdAt,
                    currency: data.currency,
                    cv: data.cv,
                    department: data.department,
                    editable: data.editable,
                    education: data.education,
                    eligibility: data.eligibility,
                    eligibilityDetails: data.eligibilityDetails,
                    experience: data.experience,
                    jobId: data.jobId,
                    jobTitle: data.jobTitle,
                    keywords: data.keywords,
                    location: data.location,
                    qualification: data.qualification,
                    read: data.read,
                    salary: data.salary,
                    salaryInfo: data.salaryInfo,
                    status: data.status,
                    updatedAt: data.updatedAt,
                    user: {
                        userId: data.user.userId,
                        firstName: data.user.firstName,
                        lastName: data.user.lastName,
                        email: data.user.email,
                        phone: data.user.phone,
                        addressId: data.user.addressId,
                        line1: data.user.line1,
                        line2: data.user.line2,
                        city: data.user.city,
                        county: data.user.county,
                        postcode: data.user.postcode,
                        country: data.user.country,
                        active: data.user.active,
                        admin: data.user.admin
                    }
                },
                loading: false
            });
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            return {
                auth: {
                    userId: nextProps.auth.userId,
                    userName: nextProps.auth.userName,
                    authenticated: nextProps.auth.authenticated,
                    admin: nextProps.auth.admin
                }
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.auth !== this.props.auth) {
            this.initializeApplication(prevProps.jobId);
        }
    }
    
    render() {
        if (this.state.loading || !this.state.application) {
            return <div><p><em>Loading...</em></p></div>;
        }
        else {
            if (this.state.application.applicable && this.state.application.editable) {
                let menu = this.renderNavMenu();

                let content = <ApplicationContent
                    jobId={this.props.jobId}
                    stage={this.state.stage}
                    application={this.state.application}
                    changeStage={this.onChangeStage}
                    checkEligibility={this.onEligibilityChange}
                    checkAcknowledgement={this.onAcknowledgementChange}
                    saveBasicDetails={this.onBasicDetailSubmit}
                    saveCareerDetails={this.onCareerDetailsSubmit}
                    uploadCV={this.onCVUpload}
                    removeCV={this.onCVRemove}
                    alert={this.state.alert}
                    handleLogin={this.props.handleLogin}
                    auth={this.state.auth}
                    history={this.props.history} />;

                return <div className="apply-job">
                    <div className="row">
                        <div className="col col-md-2">{menu}</div>
                        <div className="col col-md-10">{content}</div>
                    </div>
                </div>;
            }
            else if (!this.state.application.editable) {
                return <div><p><em>Your application has already been processed, please apply for another job.</em></p></div>
            }
            else {
                return <div><p><em>Job is closed, you cannot apply for this job.</em></p></div>
            }
        }
    }

    renderNavMenu() {
        let content = null;

        if (this.state.application) {
            let link3, link4, link5 = null;

            if (this.state.application.acknowledged) {
                link3 = <ListGroupItem active={this.state.stage === 3} tag="button"
                    className="job-application-item" onClick={() => { this.onChangeStage(3) }} action>Basic Details</ListGroupItem>;
            } else {
                link3 = <ListGroupItem active={this.state.stage === 3} tag="button"
                    className="job-application-item" onClick={(e) => e.preventDefault()} action>Basic Details</ListGroupItem>;
            }

            if (this.state.application.acknowledged && this.state.application.user && this.state.application.user.userId) {
                link4 = <ListGroupItem active={this.state.stage === 4} tag="button"
                    className="job-application-item" onClick={() => { this.onChangeStage(4) }} action>Career Details</ListGroupItem>;
            }
            else {
                link4 = <ListGroupItem active={this.state.stage === 4} tag="button"
                    className="job-application-item" onClick={(e) => e.preventDefault()} action>Career Details</ListGroupItem>;
            }

            if (this.state.application.acknowledged && this.state.application.user && this.state.application.user.userId
                && this.state.application.education && this.state.application.experience) {
                link5 = <ListGroupItem active={this.state.stage === 5} tag="button"
                    className="job-application-item" onClick={() => { this.onChangeStage(5) }} action>Upload CV</ListGroupItem>;
            } else {
                link5 = <ListGroupItem active={this.state.stage === 5} tag="button"
                    className="job-application-item" onClick={(e) => e.preventDefault()} action>Upload CV</ListGroupItem>;
            }

            content = <div className="job-application-menu">
                <ListGroup>
                    <ListGroupItem active={this.state.stage === 1} tag="button"
                        className="job-application-item" onClick={() => { this.onChangeStage(1) }} action>Eligibility</ListGroupItem>
                    <ListGroupItem active={this.state.stage === 2} tag="button"
                        className="job-application-item" onClick={() => { this.onChangeStage(2) }} action>Acknowledgement</ListGroupItem>
                    {link3}{link4}{link5}
                </ListGroup>
            </div>;
        }

        return content;
    }

    onChangeStage(value) {
        if (value >= 1 && value <= 5) {
            this.setState({
                stage: value,
                alert: update(this.state.alert, {
                    alertType: { $set: '' },
                    message: { $set: '' }
                })
            });
        }
    }

    onEligibilityChange(value, details) {
        let obj = this.state.application;

        if (obj !== null) {
            if (obj.applicationId > 0) {
                obj.eligibility = value;
                obj.eligibilityDetails = details;

                ApplicationService.onEligibilityChange(obj)
                    .then(rsp => {
                        if (rsp.succeeded) {
                            this.setState({
                                application: rsp.data,
                                alert: update(this.state.alert, {
                                    alertType: { $set: '' },
                                    message: { $set: '' }
                                }),
                                stage: 2 
                            });
                        } else {
                            this.setState({
                                alert: update(this.state.alert, {
                                    alertType: { $set: 'error' },
                                    message: { $set: rsp.error.message }
                                })
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({
                            alert: update(this.state.alert, {
                                alertType: { $set: 'error' },
                                message: { $set: err }
                            })
                        });
                    });
            }
            else {
                this.setState({
                    application: obj,
                    alert: update(this.state.alert, {
                        alertType: { $set: '' },
                        message: { $set: '' }
                    }),
                    stage: 2
                });
            }
        }
    }

    onAcknowledgementChange(value) {
        const obj = this.state.application;

        if (obj) {
            if (obj.acknowledged !== value) {
                obj.acknowledged = value;
                this.setState({
                    application: obj,
                    alert: update(this.state.alert, {
                        alertType: { $set: '' },
                        message: { $set: '' }
                    }),
                    stage: 3
                });
            }
            else {
                this.setState({ stage: 3 });
            }
        }
    }


    onBasicDetailSubmit(value) {
        let obj = this.state.application;

        if (obj && value) {
            obj.user = value;

            ApplicationService.onBasicDetailSubmit(obj)
                .then(rsp => {
                    if (rsp.succeeded) {
                        this.setState({
                            application: rsp.data,
                            alert: update(this.state.alert, {
                                alertType: { $set: '' },
                                message: { $set: '' }
                            }),
                            stage: 4
                        });
                    }
                    else { 
                        this.setState({
                            application: obj,
                            alert: update(this.state.alert, {
                                alertType: { $set: 'error' },
                                message: { $set: rsp.error.errorMessage }
                            })
                        });
                    }

                }).catch(() => {
                    this.setState({
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: 'Application update failed.' }
                        })
                    });
                });
        }
    }


    onCareerDetailsSubmit(value) {
        let obj = this.state.application;

        if (obj) {
            obj.keywords = value.keywords ? value.keywords : '';
            obj.department = value.department ? value.department : '';
            obj.location = value.location ? value.location : '';
            obj.currency = value.currency ? value.currency : '';
            obj.salary = value.salary;
            obj.salaryInfo = value.salaryInfo;
            obj.experience = value.experience ? value.experience : '';
            obj.education = value.education ? value.education : '';
            obj.qualification = value.qualification ? value.qualification : '';
            obj.coverLetter = value.coverLetter ? value.coverLetter : '';
            obj.coverLetterExcerpt = value.coverLetter ? value.coverLetter : '';

            ApplicationService.onCareerDetailsSubmit(obj)
                .then(rsp => {
                    if (rsp.succeeded) {
                        this.setState({
                            application: {
                                acknowledged: rsp.data.acknowledged,
                                applicable: rsp.data.applicable,
                                applicationId: rsp.data.applicationId,
                                coverLetter: rsp.data.coverLetter,
                                coverLetterExcerpt: rsp.data.coverLetterExcerpt,
                                createdAt: rsp.data.createdAt,
                                currency: rsp.data.currency,
                                cv: rsp.data.cv,
                                department: rsp.data.department,
                                editable: rsp.data.editable,
                                education: rsp.data.education,
                                eligibility: rsp.data.eligibility,
                                eligibilityDetails: rsp.data.eligibilityDetails,
                                experience: rsp.data.experience,
                                jobId: rsp.data.jobId,
                                jobTitle: rsp.data.jobTitle,
                                keywords: rsp.data.keywords,
                                location: rsp.data.location,
                                qualification: rsp.data.qualification,
                                read: rsp.data.read,
                                salary: rsp.data.salary,
                                salaryInfo: rsp.data.salaryInfo,
                                status: rsp.data.status,
                                updatedAt: rsp.data.updatedAt,
                                user: {
                                    userId: rsp.data.user.userId,
                                    firstName: rsp.data.user.firstName,
                                    lastName: rsp.data.user.lastName,
                                    email: rsp.data.user.email,
                                    phone: rsp.data.user.phone,
                                    addressId: rsp.data.user.addressId,
                                    line1: rsp.data.user.line1,
                                    line2: rsp.data.user.line2,
                                    city: rsp.data.user.city,
                                    county: rsp.data.user.county,
                                    postcode: rsp.data.user.postcode,
                                    country: rsp.data.user.country,
                                    active: rsp.data.user.active,
                                    admin: rsp.data.user.admin
                                }
                            },
                            alert: update(this.state.alert, {
                                alertType: { $set: '' },
                                message: { $set: '' }
                            }),
                            stage: 5
                        });

                    } else {
                        this.setState({
                            application: obj,
                            alert: update(this.state.alert, {
                                alertType: { $set: 'error' },
                                message: { $set: rsp.error.errorMessage }
                            })
                        });
                    }
                }).catch(() => {
                    this.setState({
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: 'Update failed.' }
                        })
                    });
                });

        }
    }


    onCVUpload(files) {
        let fileData = (files === null || files.length <= 0) ? null : files[0];
        let obj = this.state.application;

        if (obj && fileData) {
            if (fileData) {
                console.log('start upload');

                ApplicationService.onCVUpload(obj, fileData)
                    .then(rsp => {
                        console.log('on cv upload');
 
                        if (rsp.succeeded) {
                            this.setState({
                                application: {
                                    acknowledged: rsp.data.acknowledged,
                                    applicable: rsp.data.applicable,
                                    applicationId: rsp.data.applicationId,
                                    coverLetter: rsp.data.coverLetter,
                                    coverLetterExcerpt: rsp.data.coverLetterExcerpt,
                                    createdAt: rsp.data.createdAt,
                                    currency: rsp.data.currency,
                                    cv: rsp.data.cv,
                                    department: rsp.data.department,
                                    editable: rsp.data.editable,
                                    education: rsp.data.education,
                                    eligibility: rsp.data.eligibility,
                                    eligibilityDetails: rsp.data.eligibilityDetails,
                                    experience: rsp.data.experience,
                                    jobId: rsp.data.jobId,
                                    jobTitle: rsp.data.jobTitle,
                                    keywords: rsp.data.keywords,
                                    location: rsp.data.location,
                                    qualification: rsp.data.qualification,
                                    read: rsp.data.read,
                                    salary: rsp.data.salary,
                                    salaryInfo: rsp.data.salaryInfo,
                                    status: rsp.data.status,
                                    updatedAt: rsp.data.updatedAt,
                                    user: {
                                        userId: rsp.data.user.userId,
                                        firstName: rsp.data.user.firstName,
                                        lastName: rsp.data.user.lastName,
                                        email: rsp.data.user.email,
                                        phone: rsp.data.user.phone,
                                        addressId: rsp.data.user.addressId,
                                        line1: rsp.data.user.line1,
                                        line2: rsp.data.user.line2,
                                        city: rsp.data.user.city,
                                        county: rsp.data.user.county,
                                        postcode: rsp.data.user.postcode,
                                        country: rsp.data.user.country,
                                        active: rsp.data.user.active,
                                        admin: rsp.data.user.admin
                                    }
                                },
                                alert: update(this.state.alert, {
                                    alertType: { $set: 'info' },
                                    message: { $set: 'Your application has been successfully submitted. If you wish to track your application please visit your profile on our careers portal.' }
                                })
                            });
                        } else {
                            this.setState({
                                alert: update(this.sate.alert, {
                                    alertType: { $set: 'error' },
                                    message: { $set: rsp.error.errorMessage }
                                })
                            });
                        }
                    }).catch(() => {
                        this.setState({
                            alert: update(this.state.alert, {
                                alertType: { $set: 'error' },
                                message: { $set: 'CV upload failed.' }
                            })
                        });
                    });
            }

        }
    }


    onCVRemove() {
        let obj = this.state.application;

        if (obj) {
            ApplicationService.onCVRemove(obj)
                .then((rsp) => {
                    if (rsp.succeeded) {
                        this.setState({
                            application: {
                                acknowledged: rsp.data.acknowledged,
                                applicable: rsp.data.applicable,
                                applicationId: rsp.data.applicationId,
                                coverLetter: rsp.data.coverLetter,
                                coverLetterExcerpt: rsp.data.coverLetterExcerpt,
                                createdAt: rsp.data.createdAt,
                                currency: rsp.data.currency,
                                cv: rsp.data.cv,
                                department: rsp.data.department,
                                editable: rsp.data.editable,
                                education: rsp.data.education,
                                eligibility: rsp.data.eligibility,
                                eligibilityDetails: rsp.data.eligibilityDetails,
                                experience: rsp.data.experience,
                                jobId: rsp.data.jobId,
                                jobTitle: rsp.data.jobTitle,
                                keywords: rsp.data.keywords,
                                location: rsp.data.location,
                                qualification: rsp.data.qualification,
                                read: rsp.data.read,
                                salary: rsp.data.salary,
                                salaryInfo: rsp.data.salaryInfo,
                                status: rsp.data.status,
                                updatedAt: rsp.data.updatedAt,
                                user: {
                                    userId: rsp.data.user.userId,
                                    firstName: rsp.data.user.firstName,
                                    lastName: rsp.data.user.lastName,
                                    email: rsp.data.user.email,
                                    phone: rsp.data.user.phone,
                                    addressId: rsp.data.user.addressId,
                                    line1: rsp.data.user.line1,
                                    line2: rsp.data.user.line2,
                                    city: rsp.data.user.city,
                                    county: rsp.data.user.county,
                                    postcode: rsp.data.user.postcode,
                                    country: rsp.data.user.country,
                                    active: rsp.data.user.active,
                                    admin: rsp.data.user.admin
                                }
                            },
                            alert: update(this.state.alert, {
                                alertType: { $set: 'info' },
                                message: { $set: 'File removed.' }
                            })
                        });
                    } else {
                        this.setState({
                            alert: update(this.state.alert, {
                                alertType: { $set: 'error' },
                                message: { $set: rsp.error.errorMessage }
                            })
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: 'Remove failed.' }
                        })
                    });
                });
        }
    }
}