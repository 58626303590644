import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import DisplayAlert from '../DisplayAlert';
import { AuthService } from '../../helpers/AuthService';
import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);

        this.state = {
            firstNameUntouched: true,
            firstName: '',
            lastNameUntouched: true,
            lastName: '',
            emailUntouched: true,
            email: '',
            passwordUntouched: true,
            password: null,
            confirmPasswordUntouched: true,
            confirmPassword: null,
            registerSuccess: false,
            alert: {
                alertType: '',
                message: ''
            }
        }

        this.validFirstName = this.validFirstName.bind(this);
        this.validLastName = this.validLastName.bind(this);
        this.validPassword = this.validPassword.bind(this);
        this.validConfirmPassword = this.validConfirmPassword.bind(this);
        this.validEmail = this.validEmail.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.renderRegisterForm = this.renderRegisterForm.bind(this);
        this.renderSuccessScreen = this.renderSuccessScreen.bind(this);
    }

    render() {
        return (<div>
            <Row>
                <Col md={6} className="d-flex">
                    <div className="page-header mb-4">Register</div>
                </Col>
            </Row>
            {this.state.registerSuccess ? this.renderSuccessScreen() : this.renderRegisterForm(this.props.embedded)}
            <Row>
                <Col md={6}>
                    <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
                </Col>
            </Row>
        </div>);
    }

    renderSuccessScreen = () => {
        return (<Row>
            <Col md={12}>
                <div className="panel panel-default">
                    <div className="recover">
                        <h3>Check your email!</h3>
                        <p>&nbsp;</p>
                        <p>We've sent an email to your email address. Click the link in the email to confirm your email.</p>
                        <p>If you don't see a message in your inbox, make sure the email address listed above is correct
                        and check your spam or junk mail folder. This email is sent from
                        &nbsp;<a href="mailto:donotreply@liberum.com">donotreply@liberum.com</a>.
                        </p>
                    </div>
                </div>
            </Col>
        </Row>);
    }

    renderRegisterForm = (embedded) => {
        const toolkit = embedded ? <div className="application-toolkit">
            <FormGroup row>
                <Col md={6} className="text-left"></Col>
                <Col md={6} className="text-right">
                    <Button color="primary" disabled={!this.onValid()} onClick={() => { this.handleRegister() }}>NEXT &gt;</Button>
                </Col>
            </FormGroup>
        </div> : <FormGroup row>
                <Col md={6}>
                    <Button color="primary" disabled={!this.onValid()} onClick={() => { this.handleRegister() }}>Register</Button>
                </Col>
            </FormGroup>;

        return (<Form>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disable><FontAwesomeIcon icon={faUser} /></Button>
                        </InputGroupAddon>
                        <Input type="text" name="firstName" id="firstName" value={this.state.firstName} 
                            onChange={(e) => this.onFirstNameChange(e)} placeholder="First Name"
                            invalid={!this.validFirstName(this.state.firstName) || this.state.firstNameUntouched} />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disable><FontAwesomeIcon icon={faUser} /></Button>
                        </InputGroupAddon>
                        <Input type="text" name="lastName" id="LastName" value={this.state.lastName}
                            onChange={(e) => this.onLastNameChange(e)} placeholder="Last Name"
                            invalid={!this.validLastName(this.state.lastName) || this.state.lastNameUntouched} />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disable><FontAwesomeIcon icon={faEnvelope} /></Button>
                        </InputGroupAddon>
                        <Input type="text" name="email" id="Email" value={this.state.email}
                            onChange={(e) => this.onLoginEmailChange(e)} placeholder="Email"
                            invalid={!this.validEmail(this.state.email) || this.state.emailUntouched} />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button><FontAwesomeIcon icon={faLock} /></Button>
                        </InputGroupAddon>
                        <Input type="password" name="password" id="Password" 
                            onChange={(e) => this.onLoginPasswordChange(e)} placeholder="Password"
                            invalid={!this.validPassword(this.state.password) || this.state.passwordUntouched} />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disable><FontAwesomeIcon icon={faLock} /></Button>
                        </InputGroupAddon>
                        <Input type="password" name="confirmPassword" id="ConfirmPassword" 
                            onChange={(e) => this.onConfirmPasswordChange(e)} placeholder="Confirm Password"
                            invalid={!this.validConfirmPassword(this.state.confirmPassword, this.state.password) || this.state.confirmPasswordUntouched} />
                    </InputGroup>
                </Col>
            </FormGroup>
            {toolkit}
        </Form>);
    }

    validFirstName = (firstName) => {
        if (/\S/.test(firstName))
            return true;
        else
            return false;
    }

    onFirstNameChange(e) {
        this.setState({
            firstNameUntouched: false,
            firstName: e.target.value
        });
    }

    validLastName = (lastName) => {
        if (/\S/.test(lastName))
            return true;
        else
            return false;
    }

    onLastNameChange(e) {
        this.setState({
            lastNameUntouched: false,
            lastName: e.target.value
        });
    }

    validEmail = (email) => {
        if (/\S/.test(email))
            return true;
        else
            return false;
    }

    onLoginEmailChange(e) {
        this.setState({
            emailUntouched: false,
            email: e.target.value
        });
    }

    validPassword = (password) => {
        if (/\S/.test(password)) {
            return true;
        } else {
            return false;
        }
    }
    
    onLoginPasswordChange(e) {
        this.setState({
            passwordUntouched: false,
            password: e.target.value
        });
    }

    validConfirmPassword = (confirmPassword, password) => {
        if (/\S/.test(confirmPassword) && confirmPassword === password) {
            return true;
        } else {
            return false;
        }
    }

    onConfirmPasswordChange(e) {
        this.setState({
            confirmPasswordUntouched: false,
            confirmPassword: e.target.value
        });
    }

    onValid() {
        return this.validFirstName(this.state.firstName)
            && this.validLastName(this.state.lastName)
            && this.validEmail(this.state.email)
            && this.validPassword(this.state.password)
            && this.validConfirmPassword(this.state.confirmPassword, this.state.password);
    }

    handleRegister = () => {
        AuthService.register(this.state.firstName, this.state.lastName, this.state.email, this.state.password)
            .then(rsp => {
                this.setState({
                    alert: update(this.state.alert, {
                        alertType: { $set: '' },
                        message: { $set: '' },
                    }),
                    registerSuccess: true
                });
            })
            .catch(err => {
                this.setState({
                    alert: update(this.state.alert, {
                        alertType: { $set: 'error' },
                        message: { $set: 'Register failed. ' + err },
                    }),
                    registerSuccess: false
                });
            })
    }
}