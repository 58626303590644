import React, { Component } from 'react';
import { Row, Col, InputGroup, InputGroupAddon, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import { DisplayApplication } from './DisplayApplication';
import { ApplicationService } from '../../helpers/ApplicationService';
import 'isomorphic-fetch';
import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { TokenHelper } from '../../helpers/TokenHelper';
import { APIBASEURL } from '../../helpers/SiteConfig';
import './ViewApplicationByJob.css'
import history from '../../history';

export class ViewApplicationByJob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: Number(this.props.params.id ? this.props.params.id : '0'),
            status: this.props.params.status ? this.props.params.status : '',
            auth: this.props.auth,
            applications: [],
            applicationsBackup: [],
            application: null,
            filterStatus: this.props.params.status ? this.props.params.status : '',
            filterDropdown: [],
            loading: true,
            alert: {
                alertType: '',
                message: ''
            }
        }

        this.fetchApplicationByJob = this.fetchApplicationByJob.bind(this);
        this.reject = this.reject.bind(this);
        this.accept = this.accept.bind(this);
        this.shortlist = this.shortlist.bind(this);
        this.updateStateApplication = this.updateStateApplication.bind(this);
        this.markApplicationRead = this.markApplicationRead.bind(this);
    }

    componentDidMount() {
        if (this.state.id > 0)
            this.fetchApplicationByJob();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            return { auth: nextProps.auth };
        }
        if (nextProps.params.status !== prevState.status) {
            return { status: nextProps.params.status ? nextProps.params.status : '' };
        }
        if (nextProps.params.id !== prevState.id) {
            return { id: Number(nextProps.params.id ? nextProps.params.id : '0') };
        }
        return null;
    } 

    fetchApplicationByJob() {

        ApplicationService.getApplicationsByJob(this.state.id)
            .then(data => {
                let displayAppls = [];

                switch (this.state.filterStatus) {
                    case 'unread':
                        var len = data.length;
                        for (var i = 0; i < len; i++) {
                            if (!data[i].read
                                && data[i].status !== 'shortlisted'
                                && data[i].status !== 'rejected'
                                && data[i].status !== 'accepted')
                                displayAppls.push(data[i]);
                        }
                        break;

                    case 'read':
                        var len = data.length;
                        for (var i = 0; i < len; i++) {
                            if (data[i].read
                                && data[i].status !== 'shortlisted'
                                && data[i].status !== 'rejected'
                                && data[i].status !== 'accepted')
                                displayAppls.push(data[i]);
                        }
                        break;

                    case 'shortlisted':
                        var len = data.length;
                        for (var i = 0; i < len; i++) {
                            if (data[i].status == 'shortlisted')
                                displayAppls.push(data[i]);
                        }
                        break;

                    case 'rejected':
                        var len = data.length;
                        for (var i = 0; i < len; i++) {
                            if (data[i].status == 'rejected')
                                displayAppls.push(data[i]);
                        }
                        break;

                    default:
                        var len = data.length;
                        for (var i = 0; i < len; i++) {
                            displayAppls.push(data[i]);
                        }
                        break;
                }
                
                this.setState({
                    loading: false,
                    applications: displayAppls.length > 0 ? displayAppls : [],
                    applicationsBackup: data.length > 0 ? data : [],
                    application: update(this.state.application, { $set: displayAppls ? displayAppls[0] : null }),
                    alert: update(this.state.alert, {
                        alertType: { $set: '' },
                        message: { $set: '' }
                    })
                });

                this.getFilterDropdown();

            }).catch(err => {
                this.setState({
                    applications: [],
                    applicationsBackup: [],
                    application: null,
                    alert: update(this.state.alert, {
                        alertType: { $set: 'error' },
                        message: { $set: err }
                    })
                });
            });
    }

    render() {
        return this.state.loading ? (<div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>) : this.renderRightPanel();
    }

    renderLeftNavPanel() {
        return (this.state.applications !== null && this.state.applications.length > 0) ? (<div className = "job-application-menu">
            <ListGroup flush>
                {(this.state.applications).map(appl => {
                    let name = !appl.read && appl.status !== 'shortlisted'
                        && appl.status !== 'rejected' && appl.status !== 'accepted'
                        ? (<strong>{appl.user.firstName + ' ' + appl.user.lastName}</strong>)
                        : appl.user.firstName + ' ' + appl.user.lastName;

                    return <ListGroupItem key={'job_application_' + appl.applicationId}
                        active={(this.state.application && (this.state.application.applicationId === appl.applicationId))}
                        tag="button" onClick={(e) => this.updateStateApplication(appl)} action>
                        <FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;&nbsp;
                        {name}
                    </ListGroupItem>;
                })}
            </ListGroup>
        </div>) : null;
    }

    renderRightPanel() {
        let nav = this.renderLeftNavPanel();
        let content = this.state.application !== null
            ? <DisplayApplication history={history} data={this.state.application}
                auth={this.state.auth} reject={this.reject}
                shortlist={this.shortlist} accept={this.accept} read={this.markApplicationRead}
                fetchFile={this.fetchFile} />
            : (<p><em>Sorry, cannot find applications base on your request...</em></p>);

        return (<div>
            <Row className="apply-job">
                <Col lg={10}></Col>
                <Col lg={2}>{this.renderFilterBar(this.state.filterStatus)}</Col>
            </Row>
            <Row>
                <div className="viewappl-left-panel">{nav}</div>
                <div id="applbox" className="viewappl-right-panel">{content}</div>
            </Row>
        </div>);
    }

    getFilterDropdown() {
        let dropdownStatus = [];

        if (this.state.applicationsBackup !== null && this.state.applicationsBackup.length > 0) {

            let hasRead = false;
            let hasUnread = false;
            let hasRejected = false;
            let hasShortlisted = false;

            for (var i = 0; i < this.state.applicationsBackup.length; i++) {
                if (this.state.applicationsBackup[i].read
                    && this.state.applicationsBackup[i].status !== 'rejected'
                    && this.state.applicationsBackup[i].status !== 'shortlisted') {
                    hasRead = true;
                }
                else if (!this.state.applicationsBackup[i].read
                    && this.state.applicationsBackup[i].status !== 'rejected'
                    && this.state.applicationsBackup[i].status !== 'shortlisted') {
                    hasUnread = true;
                }

                if (this.state.applicationsBackup[i].status == 'rejected') {
                    hasRejected = true;
                }
                else if (this.state.applicationsBackup[i].status == 'shortlisted') {
                    hasShortlisted = true
                }
            }

            if (hasUnread)
                dropdownStatus.push('Unread');

            if (hasRead)
                dropdownStatus.push('Read');

            if (hasShortlisted)
                dropdownStatus.push('Shortlisted');

            if (hasRejected)
                dropdownStatus.push('Rejected');
        }

        this.setState({
            filterDropdown: dropdownStatus
        });
    }

    renderFilterBar = (status) => {
        if (this.state.filterStatus == '' || this.state.filterStatus == 'unread'
            || this.state.filterStatus == 'read' || this.state.filterStatus == 'shortlisted'
            || this.state.filterStatus == 'rejected')
        {
            return (<InputGroup size="sm">
                <InputGroupAddon addonType="prepend">Status</InputGroupAddon>
                <Input type="select" value={status} onChange={(e) => this.onFilterStatusChange(e.target.value)}>
                    <option value="">All</option>
                    {this.state.filterDropdown.map((s) => {
                        return (<option key={'status_' + s} value={s.toLowerCase()}>{s}</option>);
                    })}
                </Input>
            </InputGroup>);
        }
        else
            return (<div />);
    }

    shortlist(application) {
        ApplicationService.shorlist(application)
            .then(rsp => {
                if (rsp.succeeded) {
                    this.refreshList(rsp.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    reject(application) {
        ApplicationService.reject(application)
            .then(rsp => {
                if (rsp.succeeded) {
                    this.refreshList(rsp.data);
                }
            })
            .catch((err) => {  
                console.log(err);
            });
    }

    accept(application) {
        ApplicationService.accept(application)
            .then(rsp => {
                if (rsp.succeeded) {
                    this.setState({
                        application: rsp.data
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    refreshList = (appl) => {
        var res1 = this.state.applications;

        for (var i = 0; i < res1.length; i++) {
            if (res1[i].applicationId == appl.applicationId
                && this.state.filterStatus !== ''
                && appl.status !== this.state.filterStatus) {
                res1.splice(i, 1);
            }
            else if(res1[i].applicationId == appl.applicationId) {
                this.updateStateApplication(appl);
                res1[i] = appl;
            }
        }
        
        if (res1.length == 0) {
            this.setState({
                filterStatus: ''
            });

            this.fetchApplicationByJob();
        }
        else {
            this.setState({
                application: res1[0],
                applications: res1
            });

            var res2 = this.state.applicationsBackup;
            for (var i = 0; i < res2.length; i++) {
                if (res2[i].applicationId == appl.applicationId) {
                    res2[i] = appl;
                }
            }

            this.setState({
                applicationsBackup: res2
            });
        }
        
        this.getFilterDropdown();
    }

    onFilterStatusChange(status) {
        this.setState({
            filterStatus: status
        });

        this.fetchApplicationByJob(status);
    }

    markApplicationRead = (id) => {

        ApplicationService.read(id).then((appl) =>
        {
            if (this.state.application !== null && appl.succeeded) {

                let updatedAppl = update(this.state.application, {
                    read: { $set: appl.data.read },
                    updatedAt: { $set: appl.data.updatedAt }
                });

                let objs = this.state.applications;
                for (var i = 0; i < objs.length; i++) {
                    if (objs[i].applicationId == appl.data.applicationId) {
                        objs[i].read = appl.data.read;
                        objs[i].updatedAt = appl.data.updatedAt;
                    }
                }

                let objs2 = this.state.applicationsBackup;
                for (var i = 0; i < objs2.length; i++) {
                    if (objs2[i].applicationId == appl.data.applicationId) {
                        objs2[i].read = appl.data.read;
                        objs2[i].updatedAt = appl.data.updatedAt;
                    }
                }

                this.setState({
                    application: updatedAppl,
                    applications: objs
                });

                this.getFilterDropdown();
            }
        });
    }

    updateStateApplication = (appl) => {
        if (this.state.application !== null) {
            let updatedAppl = update(this.state.application, {
                jobId: { $set: appl.jobId },
                jobTitle: { $set: appl.jobTitle },
                acknowledged: { $set: appl.acknowledged },
                applicable: { $set: appl.applicable },
                applicationId: { $set: appl.applicationId },
                coverLetter: { $set: appl.coverLetter },
                coverLetterExcerpt: { $set: appl.coverLetterExcerpt },
                createdAt: { $set: appl.createdAt },
                currency: { $set: appl.currency },
                department: { $set: appl.department },
                editable: { $set: appl.editable },
                eligibility: { $set: appl.eligibility },
                experience: { $set: appl.experience },
                keywords: { $set: appl.keywords },
                location: { $set: appl.location },
                qualification: { $set: appl.qualification },
                read: { $set: appl.read },
                salary: { $set: appl.salary },
                salaryInfo: { $set: appl.salaryInfo },
                status: { $set: appl.status },
                updatedAt: { $set: appl.updatedAt },
                user: {
                    userId: { $set: appl.user.userId },
                    firstName: { $set: appl.user.firstName },
                    lastName: { $set: appl.user.lastName },
                    email: { $set: appl.user.email },
                    phone: { $set: appl.user.phone },
                    addressId: { $set: appl.user.addressId },
                    line1: { $set: appl.user.line1 },
                    line2: { $set: appl.user.line2 },
                    city: { $set: appl.user.city },
                    county: { $set: appl.user.county },
                    country: { $set: appl.user.country },
                    postcode: { $set: appl.user.postcode },
                }
            });
            
            this.setState({
                application: updatedAppl
            });

            document.getElementById("applbox").scrollTop = 0;
        }
        else {
            this.setState({ application: appl })
        }
    }

    fetchFile(application, fileName) {
        const url = APIBASEURL.concat("api/application/").concat(application.applicationId.toString()).concat("/file");
        const token = TokenHelper.getJwt();

        if (token) {
            fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then((rsp) => rsp.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                })
                .catch(() => {
                });
        }
    }
}