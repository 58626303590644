import React from 'react';
import { Row, Col, Label, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile';
import { TokenHelper } from '../../../helpers/TokenHelper';
import { APIBASEURL } from '../../../helpers/SiteConfig';
import parse from 'html-react-parser';

const ApplicationDetail = (props) => {

    return (<div className="application" id={'application_' + props.application.applicationId}>
        <Row>
            <Col lg={12}>
                <span className="page-header">Application Review</span>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <Label className="application-job">{props.application.jobTitle}</Label>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <Label className="application-name">{props.application.user.firstName + ' ' + props.application.user.lastName}</Label>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <span className="application-keywords">{props.application.keywords}</span>
            </Col>
        </Row>
        <div className="application-block">
            <Row>
                <Col lg={12}>
                    <span className="application-block-header">Contact</span>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <div className="address">
                        <FontAwesomeIcon id="icon_email" icon={faEnvelopeOpen} />&nbsp;&nbsp;
                        <a href={"mailto:" + props.application.user.email}>{props.application.user.email}</a>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="address">
                        <FontAwesomeIcon id="icon_mobile" icon={faMobile} />&nbsp;&nbsp;{props.application.user.phone}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="address">
                        <FontAwesomeIcon id="icon_address" icon={faAddressCard} />&nbsp;&nbsp;
                        {generateAddress(props.application.user.line1, props.application.user.line2,
                            props.application.user.city, props.application.user.county, props.application.user.postcode,
                            props.application.user.country)}
                    </div>
                </Col>
            </Row>
        </div>
        <div className="application-block">
            <Row>
                <Col lg={12}>
                    <span className="application-block-header">Experiences</span>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Row className="application-inrow">
                        <Col lg={4}>
                            <span className="strong">Eligibility to work</span>
                        </Col>
                        <Col lg={8}>
                            {props.application.eligibility ? <span>Yes</span> : <span className="red"><strong>No</strong></span>}
                        </Col>
                    </Row>
                    <Row className="application-inrow">
                        <Col lg={4}>
                            <span className="strong">Department</span>
                        </Col>
                        <Col lg={8}>{props.application.department}</Col>
                    </Row>
                    <div className="row application-inrow">
                        <Col lg={4}>
                            <span className="strong">Location</span>
                        </Col>
                        <Col lg={8}>{props.application.location}</Col>
                    </div>
                    <div className="row application-inrow">
                        <Col lg={4}><span className="strong">Expected Salary</span></Col>
                        <Col lg={8}>{props.application.salary ? ((props.application.currency === "USD" ? "$" : "£") + ' ' + props.application.salary.toLocaleString()) : ''}</Col>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="row application-inrow">
                        <Col lg={4}><span className="strong">Experience</span></Col>
                        <Col lg={8}>{props.application.experience}</Col>
                    </div>
                    <div className="row application-inrow">
                        <Col lg={4}><span className="strong">Education</span></Col>
                        <Col lg={8}>{props.application.education}</Col>
                    </div>
                    <div className="row application-inrow">
                        <Col lg={4}><span className="strong">Qualification</span></Col>
                        <Col lg={8}>{props.application.qualification ? props.application.qualification : ''}</Col>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="application-block">
            <Row>
                <Col lg={12}>
                    <span className="application-block-header">Cover Letter</span>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="cover-letter">
                        {props.application.coverLetter ? parse(props.application.coverLetter) : 'The applicant has not entered cover letter yet.'}
                    </div>
                </Col>
            </Row>
        </div>
        {props.fullScreen ? null : <ApplicationCV cv={props.application.cv} id={props.application.applicationId} />}
        <div className="application-block">
            <Row>
                <Col lg={12}>
                    <span className="application-block-header">Application Status</span>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <p><span className="strong">This application is</span> {generateJobStatus(props.application.status)}</p>
                    <p><span className="strong">Created</span> @ {props.application.createdAt ? props.application.createdAt : 'unknown'}</p>
                    <p><span className="strong">Last modified</span> @ {props.application.updatedAt ? props.application.updatedAt : 'unknown'}</p>
                </Col>
            </Row>
        </div>
    </div>);

    function generateAddress(line1, line2, city, county, postcode, country) {
        let addr = '';
        addr = addr.concat(line1 && line1.length > 0 ? line1 + ', ' : '');
        addr = addr.concat(line2 && line2.length > 0 ? line2 + ', ' : '');
        addr = addr.concat(city && city.length > 0 ? city + ', ' : '');
        addr = addr.concat(county && county.length > 0 ? county + ', ' : '');
        addr = addr.concat(postcode && postcode.length > 0 ? postcode + ', ' : '');
        addr = addr.concat(country && country.length > 0 ? country : '');
        return addr;
    }

    function generateJobStatus(status) {
        if (status === "accepted")
            return <Badge color="success">{status}</Badge>;
        else if (status === "rejected")
            return <Badge color="danger">{status}</Badge>;
        else
            return <Badge color="primary">{status}</Badge>;
    }
};

const ApplicationCV = (props) => {     

    function generateCVLink(fileName) {
        return <Button color="primary" size="sm" onClick={() => { fetchFile(fileName) }}>{fileName}</Button>;
    }

    return (<div className="application-block">
        <Row>
            <Col lg={12}>
                <span className="application-block-header">CV</span>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                {(props.cv && props.cv.length > 0) ? generateCVLink(props.cv[0]) : 'No cv has been uploaded yet.'}
            </Col>
        </Row>
    </div>);

    function fetchFile(fileName) {
        const url = APIBASEURL.concat('api/application/').concat(props.id).concat("/file");
        const token = TokenHelper.getJwt();

        fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                'Authorization': 'Bearer '.concat(token)
            })
        })
            .then(rsp => rsp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                //link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => { throw err });
    }
}

export { ApplicationCV, ApplicationDetail };