import React from 'react';
import { Row, Col, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

const Header = ({ embedded }) => (
    embedded ? (<h2>Login</h2>) : (
        <Row>
            <Col md={6} className="d-flex">
                <div className="page-header mb-4">Login</div>
            </Col>
        </Row>));

const LoginForm = (props) => {
    return (<div>
        <Header embedded={props.embedded} />
        <Form>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disabled><FontAwesomeIcon icon={faEnvelope} /></Button>
                        </InputGroupAddon>
                        <Input type="text" onChange={(e) => props.onLoginEmailChange(e)} placeholder="Email" invalid={!props.validEmail} aria-label="Email" />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disabled><FontAwesomeIcon icon={faLock} /></Button>
                        </InputGroupAddon>
                        <Input type="password" onChange={(e) => props.onLoginPasswordChange(e)} placeholder="Password" invalid={!props.validPassword} aria-label="Password" />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup className="btn-toolbar" role="toolbar" row>
                <Col md={6}>
                    <Button color="primary" disabled={!props.validForm()} onClick={props.onLogin} aria-label="Login">&nbsp;&nbsp;Login&nbsp;&nbsp;</Button>
                </Col>
            </FormGroup>
        </Form>
        <Row>
            <Col md="6 mb-4">
                <Link to={'/forgetpassword'}>Forgot password?</Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to={'/register'}>Not yet registered?</Link>
            </Col>
        </Row>
    </div>);
};

export default LoginForm;