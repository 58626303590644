import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import DisplayAlert from '../DisplayAlert';
import LoginForm from './login-form/LoginForm';

export class Login extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            auth: this.props.auth,
            emailUntouched: true,
            email: '',
            passwordUntouched: true,
            password: null,
            alert: this.props.alert
        }
        
        this.validEmail = this.validEmail.bind(this);
        this.validPassword = this.validPassword.bind(this);
        this.validForm = this.validForm.bind(this);
        this.onLoginEmailChange = this.onLoginEmailChange.bind(this);
        this.onLoginPasswordChange = this.onLoginPasswordChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.alert !== prevState.alert) {
            return {
                alert: {
                    alertType: nextProps.alert.alertType,
                    message: nextProps.alert.message
                }  
            };
        }
        if (nextProps.auth !== prevState.auth) {
            return {
                auth: {
                    userId: nextProps.auth.userId,
                    userName: nextProps.auth.userName,
                    authenticated: nextProps.auth.authenticated,
                    admin: nextProps.auth.admin
                }
            }
        }
        return null;
    }

    render() {
        if (!this.props.embedded && this.props.auth.authenticated) {
            return (<Redirect to={this.props.history.push('/')} />);
        }
        else {
            return (<div>
                <LoginForm embedded={this.props.embedded}
                    email={this.state.email}
                    password={this.state.password}
                    validEmail={this.validEmail}
                    validPassword={this.validPassword}
                    validForm={this.validForm}
                    onLoginEmailChange={this.onLoginEmailChange}
                    onLoginPasswordChange={this.onLoginPasswordChange}
                    onLogin={() => this.props.handleLogin(this.state.email, this.state.password)} />
                <Row>
                    <Col md="6">
                        <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
                    </Col>
                </Row>
            </div>);
        }
    }

    onLoginEmailChange(e) {
        this.setState({
            emailUntouched: false,
            email: e.target.value
        });
    }

    onLoginPasswordChange(e) {
        this.setState({
            passwordUntouched: false,
            password: e.target.value
        });
    }

    validEmail = () => {
        return this.state.email && this.state.email.length > 0 && this.state.email !== '';
    }

    validPassword = () => {
        return this.state.password && this.state.password.length > 0 && this.state.password.trim() !== '';
    }

    validForm = () => {
        return this.validEmail() && this.validPassword();
    }
}