import React, { Component } from 'react';
import { Form, Col, FormGroup, Label, Input } from 'reactstrap'
import { ApplicationToolkit } from './ApplicationToolkit';

export class CheckEligibility extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eligible: this.props.eligible || this.props.eligible === 'true',
            details: this.props.details
        };

        this.handleNextClick = this.handleNextClick.bind(this);
    }

    render() {
        return (<div className="application-section">
            <h2>Eligibility</h2>
            <p>Liberum has an employment policy based on the principles of fairness and equal opportunity irrespective of race, colour, religion, national origin, disability, gender, sexuality, age or marital status. We are an equal opportunities employer.</p>
            <p>The Company seeks to ensure that equality of treatment and opportunity is available to all employees and potential employees. This commitment applies to all aspects of the employment relationship including but not limited to recruitment, selection, compensation and benefits, training, performance management and promotions.</p>
            <p>The Company will use objective assessment methods to ensure that the most competent applicants are recruited and the most able employees’ progress within the Company.</p>
            <p>Do you have unrestricted legal right to work in the UK?</p>
            <p>&nbsp;</p>
            <Form>
                <FormGroup row>
                    <Col md={12}>
                        <FormGroup check inline>
                            <Label check>
                                <Input type="radio" name="rbEligibility" defaultChecked={this.state.eligible} value={true}
                                    onChange={(e) => {
                                        this.setState({
                                            eligible: e.target.value === 'true',
                                            details: ''
                                        });
                                    }} />{' '}
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input type="radio" name="rbEligibility" defaultChecked={!this.state.eligible} value={false}
                                    onChange={(e) => {
                                        this.setState({ eligible: e.target.value === 'true' });
                                    }} />{' '}
                                No
                            </Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label lg={12}>Please provide more details if you are not eligible to work in the U.K. <span className="red">*</span></Label>
                </FormGroup>
                <FormGroup row>
                    <Col lg={6}>
                        <Input type="text" name="detail" id="detail" bsSize="sm" defaultValue={this.state.details}
                            disabled={this.state.eligible} onChange={(e) => { this.setState({ details: e.target.value }); }} />
                    </Col>
                </FormGroup>
                <ApplicationToolkit stage={1}
                    valid={this.state.eligible || (!this.state.eligible && this.state.details && this.state.details.trim().length > 0)}
                    next={this.handleNextClick} />
            </Form>
        </div>);
    }                                                                                                  

    handleNextClick() {
       this.props.next(this.state.eligible, this.state.details);
    }
}