import { TokenHelper } from './TokenHelper';
import { APIBASEURL } from './SiteConfig';

/*------------------------------------------
 * Application Service
 * Author:         Jing Xu
 * Created at:     10th May 2018
 * Last Modified:  21st Aug 2018
---------------------------------------------*/

export const ApplicationService = {
    getApplication: (id) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/').concat(id ? id.toString() : '');

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            })
        })
            .then(rsp => rsp.json())
            .then(data => { return data })
            .catch(err => { return err });
    },
    getApplicationsByJob: (id) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/job/').concat(id);

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            })
        })
            .then(response => response.json())
            .then(data => { return data; })
            .catch(err => { return err; });
    },
    getUserApplicationByJob: (id) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/job/').concat(id).concat('/apply');
        const headers = access_token ? new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache'
        });

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: headers
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err; });
    },
    getUnreadApplications: (id) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/job/' + id + '/status/unread');

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            })
        })
            .then(response => response.json())
            .then(data => { return data })
            .catch(err => { return err });
    },
    getReadApplications: (id) => {
        const token = TokenHelper.getJwt();
        let url = APIBASEURL.concat('api/application/job/').concat(id).concat('/status/read');

        if (token) {
            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then(response => response.json())
                .then(data => { return data })
                .catch(err => { return err });
        }
        else {
            throw new Error("Inavlid token");
        }
    },
    getShortlistedApplications: (id) => {
        const token = TokenHelper.getJwt();
        let url = APIBASEURL.concat('api/application/job/').concat(id).concat('/status/shortlisted');

        if (token) {
            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then(response => response.json())
                .then(data => { return data })
                .catch(err => { return err });
        }
        else {
            throw new Error("Inavlid token");
        }
    },
    getAcceptedApplications: (id) => {
        const token = TokenHelper.getJwt();
        let url = APIBASEURL.concat('api/application/job/').concat(id).concat('/status/accepted');

        if (token) {
            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then(response => response.json())
                .then(data => { return data })
                .catch(err => { return err });
        }
        else {
            throw new Error("Inavlid token");
        }
    },
    getRejectedApplications: (id) => {
        const token = TokenHelper.getJwt();
        let url = APIBASEURL.concat('api/application/job/').concat(id).concat('/status/rejected');

        if (token) {
            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then(response => response.json())
                .then(data => { return data })
                .catch(err => { return err });
        }
        else {
            throw new Error("Inavlid token");
        }
    },
    onEligibilityChange: (application) => {
        const token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/' + application.applicationId);

        return fetch(url, {
            method: 'PUT',
            mode: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(token)
            }),
            body: JSON.stringify(application)
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err; });
    },
    onBasicDetailSubmit: (application) => {
        const token = TokenHelper.getJwt();

        if (token) {
            const method = application.applicationId <= 0 ? 'POST' : 'PUT';
            const url = application.applicationId <= 0 ? APIBASEURL.concat('api/application/') : APIBASEURL.concat('api/application/').concat(application.applicationId.toString());

            // here should start to create a new job application
            return fetch(url, {
                method: method,
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                }),
                body: JSON.stringify(application)
            })
                .then(rsp => rsp.json())
                .then(data => {
                    return data;
                }).catch(err => {
                    throw err;
                });
        }
        else {
            throw new Error('Invalid user');
        }
    },
    onCareerDetailsSubmit: (application) => {
        const token = TokenHelper.getJwt();

        if (token) {
            const url = APIBASEURL.concat('api/application/').concat(application.applicationId.toString());

            return fetch(url, {
                method: 'PUT',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                }),
                body: JSON.stringify(application)
            })
                .then(rsp => rsp.json())
                .then(data => {
                    return data;
                }).catch(err => {
                    throw err;
                });
        }
        else {
            throw new Error('Invalid user');
        }
    },
    onCVUpload: (application, file) => {
        const token = TokenHelper.getJwt();

        if (token) {
            const url = APIBASEURL.concat('api/application/').concat(application.applicationId.toString()).concat('/file');

            let postData = new FormData();
            postData.append("uploadFile", file);

            return fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                }),
                body: postData
            })
                .then(response => response.json())
                .then(data => {
                    return data;
                })
                .catch(err => {
                    throw err;
                });
        }
        else {
            throw new Error("Invalid user");
        }
    },
    onCVRemove: function (application) {
        const token = TokenHelper.getJwt();

        if (token) {
            const url = APIBASEURL.concat('api/application/').concat(application.applicationId.toString()).concat('/file');

            return fetch(url, {
                method: "DELETE",
                headers: new Headers({
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then(response => response.json())
                .then(data => {
                    return data;
                })
                .catch(err => {
                    throw err;
                });
        }
        else {
            throw new Error("Invalid user");
        }
    },
    reject: (application) => {
        const token = TokenHelper.getJwt();

        if (token) {
            let url = APIBASEURL.concat('api/application/' + application.applicationId + '/status/reject');

            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then((rsp) => rsp.json())
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
        }
        else {
            throw new Error("Invalid user");
        }
    },
    shorlist: (application) => {
        const token = TokenHelper.getJwt();

        if (token) {
            let url = APIBASEURL.concat('api/application/').concat(application.applicationId.toString()).concat('/status/shortlist');

            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then((rsp) => rsp.json())
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
        }
        else {
            throw new Error("Invalid user");
        }
    },
    accept: (application) => {
        const token = TokenHelper.getJwt();

        if (token) {
            let url = APIBASEURL.concat('api/application/').concat(application.applicationId.toString()).concat('/status/accept');
            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then((rsp) => rsp.json())
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
        }
        else {
            throw new Error("Invalid user");
        }
    },
    read: (id) => {
        const token = TokenHelper.getJwt();

        if (token) {
            let url = APIBASEURL.concat('api/application/' + id + '/read');
            return fetch(url, {
                method: 'GET',
                mode: 'same-origin',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer '.concat(token)
                })
            })
                .then((rsp) => rsp.json())
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
        }
        else {
            throw new Error("Invalid user");
        }
    },
    getAllApplications: async (p) => {
        const token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/page/' + p);

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: new Headers({
                'Accpet': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(token)
            })
        })
            .then(response => response.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    },
    totalApplicationCount: async (keywords) => {
        let searchKeys = '';

        if (keywords !== undefined && keywords !== null && keywords !== '') {
            const keys = keywords.split(' ');
            let ks = [];

            if (keys.length >= 1) {
                for (var i = 0; i < keys.length; i++) {
                    var k = keys[i].trim();
                    if (k !== '')
                        ks.push(k);
                }
            }

            if (ks.length > 0)
                searchKeys = ks.join(',');
        }

        const token = TokenHelper.getJwt();
        const url = searchKeys !== '' ? APIBASEURL.concat('api/application/search/' + searchKeys + '/count') : APIBASEURL.concat('api/application/count');

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: new Headers({
                'Accpet': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(token)
            })
        })
            .then(response => response.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    },
    search: async (keywords, page) => {
        const keys = keywords.split(' ');
        let ks = [];

        if (keys.length >= 1) {
            for (var i = 0; i < keys.length; i++) {
                var k = keys[i].trim();
                if (k !== '')
                    ks.push(k);
            }
        }

        let searchKeys = '';
        if (ks.length > 0)
            searchKeys = ks.join(',');

        const token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/application/search/' + searchKeys + '/page/' + page);

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: new Headers({
                'Accpet': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(token)
            })
        })
            .then(response => response.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    }
}