import React, { Component } from 'react';
import { Col, FormGroup, Button } from 'reactstrap';

export class ApplicationToolkit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backButtonHidden: false,
            nextButtonHidden: false,
            backButtonText: '< Back',
            nextButtonText: 'Next >'
        }
    }

    componentDidMount() {
        if (this.props.stage === 5) {
            this.setState({
                nextButtonText: 'Submit'
            });
        } else {
            this.setState({
                nextButtonText: 'Next >'
            });
        }
    }

    render() {
        return (<div className="job-application-toolkit">
            <FormGroup row>
                <Col md={6} className="text-left">
                    {
                        this.props.stage === 1
                            ? <span />
                            : <Button size="sm" onClick={() => this.handleBackClick()}>{this.state.backButtonText}</Button>
                    }
                </Col>
                <Col md={6} className="text-right">
                    <Button color="primary" size="sm" onClick={() => this.handleNextClick()}
                        disabled={!this.props.valid} hidden={this.state.nextButtonHidden}>
                        {this.state.nextButtonText}
                    </Button>
                </Col>
            </FormGroup>
        </div>);
    }

    handleNextClick() {
        this.props.next();
    }

    handleBackClick() {
        this.props.back();
    }
}
