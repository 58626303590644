import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import './DisplayApplication.css';
import { ApplicationDetail } from './application-detail/ApplicationDetail';
import '@babel/polyfill';

export class DisplayApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: this.props.auth,
            application: this.props.data,
            backdrop: true,
            showModal: false,
            modalType: 0
        }
        
        this.hideModal = this.hideModal.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.toggle = this.toggle.bind(this);
        this.print = this.print.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            return { auth: nextProps.auth };
        }
        if (nextProps.data !== prevState.application) {
            return { application: nextProps.data };
        }
        return null;
    }

    render() {
        let toolbar = this.renderToolbar();
        let modal = this.generateModal(this.state.modalType);

        let content = this.state.application
            ? (<div>
                {modal}
                <div id="printArea">
                    <ApplicationDetail application={this.state.application} fullScreen={false} fetchFile={this.props.fetchFile} />
                </div>
                {toolbar}
            </div>)
            : (<div><em>Cannot find application in database...</em></div>);

        return (<div>{content}</div>);
    }

    // 0 - reject application; 1 - accept application
    generateModal(modalType) {
        let message = null;
        let btn = null;

        if (modalType === 0) {
            message = <div><p>Once application is rejected, user and admins will not be able to amend the application.</p><p>Are you sure to reject this application?</p></div>;
            btn = <Button color="danger" size="sm" onClick={this.handleReject}>Yes</Button>;
        }
        else if (modalType === 1) {
            message = <div><p>Once application is accepted, user and admins will not be able to amend the application..</p><p>Are you sure to accept this application?</p></div>;
            btn = <Button color="danger" size="sm" onClick={this.handleAccept}>Yes</Button>
        }

        return <Modal show={this.state.showModal} onHide={this.toggle} animation={true} backdrop={true}>
            <Modal.Header toggle={this.toggle}>
                <Modal.Title>Please Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                {btn}
                <Button color="secondary" size="sm" onClick={this.toggle}>Cancel</Button>
            </Modal.Footer>
        </Modal>;
    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    } 

    renderToolbar = () => {
        return (this.state.application && this.state.auth.admin)
            ? <div className="application-block">
                <Row>
                    <Col lg={12} className="text-right">
                        <p>&nbsp;</p>
                        {this.renderButtonToolbar(this.state.application.status)}
                    </Col>
                </Row>
            </div> : <div />;
    }

    renderButtonToolbar = (status) => {
        let content = null;
        let id = new Date().getUTCMilliseconds(); 
        let btnPrint = (<span><Button color="secondary" size="sm"
            onClick={() => this.print('print_' + id)}>Print application</Button>&nbsp;&nbsp;</span>);
        let btnRead = this.state.application.read ? '' : (<span><Button color="primary" size="sm"
            onClick={() => { this.props.read(this.state.application.applicationId) }}>Mark as read</Button>&nbsp;&nbsp;</span>);
        
        switch (status) {
            case "cv submitted":
                content = <ButtonGroup id="applToolbar">
                    {btnRead}{btnPrint}
                    <Button color="info" size="sm" onClick={() => { this.props.shortlist(this.state.application) }}>Shortlist application</Button>&nbsp;&nbsp;
                    <Button color="danger" size="sm" onClick={() => { this.setState({ showModal: true, modalType: 0 }); }}>Reject application</Button>
                </ButtonGroup>;
                break;

            case "completed":
                content = <ButtonGroup id="applToolbar">
                    {btnRead}{btnPrint}
                    <Button color="info" size="sm"  onClick={() => { this.props.shortlist(this.state.application) }}>Shortlist application</Button>&nbsp;&nbsp;
                    <Button color="danger" size="sm" onClick={() => { this.setState({ showModal: true, modalType: 0 }); }}>Reject application</Button>
                </ButtonGroup>;
                break;

            case "shortlisted":
                content = <ButtonGroup id="applToolbar">
                    {btnPrint}
                    <Button color="success" size="sm"  onClick={() => { this.setState({ showModal: true, modalType: 1 }); }}>Accept application</Button>&nbsp;&nbsp;
                    <Button color="danger" size="sm" onClick={() => { this.setState({ showModal: true, modalType: 0 }); }}>Reject application</Button>
                </ButtonGroup>;
                break;
            
            default:
                content = (<ButtonGroup id="applToolbar">{btnPrint}</ButtonGroup>);
                break;
        }

        return content;
    }

    handleReject() {
        console.log("handle reject");
        this.props.reject(this.state.application);
        this.hideModal();
    }
    
    handleAccept() {
        this.props.accept(this.state.application);
        this.hideModal();
    }

    hideModal() {
        this.setState({ showModal: false });
    }
    
    print(uniqueIframeId) {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        const content = window.document.getElementById('printArea');
        const toolBar = window.document.getElementById("applToolbar");
        
        if (!isIE11) {
            var el;
            el = content.querySelector("svg");
            if (el)
                el.remove();
            el = content.querySelector("svg");
            if (el)
                el.remove();
            el = content.querySelector("svg");
            if (el)
                el.remove();

            toolBar.remove();

            let printWindow = window.open("", uniqueIframeId, "height=600,width=800,resizable,scrollbars=yes,status=1");
            printWindow.document.write("<html><head><title></title>");
            printWindow.document.write("<link rel=\"stylesheet\" href=\"../../site.css\" type=\"text/css\"/>");
            printWindow.document.write("<script>window.document.onload = function(e) { console.log('loaded'); }</script>")
            printWindow.document.write("</head><body style=\"margin:12px;line-height:1.5;\">");
            printWindow.document.write(content.innerHTML);
            printWindow.document.write("</body></html>");
            printWindow.focus();
            setTimeout(function () {
                printWindow.print();
                printWindow.close();
            }.bind(this), 2000);

        }
        else {
            var iconEmail = window.document.getElementById("icon_email");
            iconEmail.parentNode.removeChild(iconEmail);

            var iconMobile = window.document.getElementById("icon_mobile");
            iconMobile.parentNode.removeChild(iconMobile);

            var iconAddress = window.document.getElementById("icon_address");
            iconAddress.parentNode.removeChild(iconAddress);

            toolBar.parentNode.removeChild(toolBar);

            let printWindow = window.open("", uniqueIframeId, "height=600,width=800,resizable,scrollbars=yes,status=1");
            printWindow.document.write("<html><head><title></title>");
            printWindow.document.write("<link rel=\"stylesheet\" href=\"../../site.css\" type=\"text/css\"/>");
            printWindow.document.write("<script>window.document.onload = function(e) { console.log('loaded'); }</script>")
            printWindow.document.write("</head><body style=\"margin:12px;line-height:1.5;\">");
            printWindow.document.write(content.innerHTML);
            printWindow.document.write("</body></html>");
            printWindow.focus();
            printWindow.print();
        }
    }
}