import React, { Component } from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink, Table, Badge  } from 'reactstrap';
import { TokenHelper } from '../helpers/TokenHelper';
import { SearchForm } from './SearchForm';
import './ListUser.css';
import { APIBASEURL } from '../helpers/SiteConfig';
import { DataService } from '../helpers/DataService';

export class ListUser extends Component {
    constructor(props) {
        super(props);

        let offset = (this.props.params.offset && !isNaN(parseInt(this.props.params.offset))) ? parseInt(this.props.params.offset) : 0;
        let limit = (this.props.params.limit && !isNaN(parseInt(this.props.params.offset))) ? parseInt(this.props.params.limit) : 25;

        this.state = {
            auth: this.props.auth,
            users: [],
            loading: true,
            searchKeywords: '',
            hasPrevPage: offset > 0,
            hasNextPage: offset < limit,
            totalPage: 0,
            totalCount: 0,
            offset: offset,
            limit: limit
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            return { auth: nextProps.auth }
        }
        return null;
    }

    async componentDidMount() {
        await this.getUserCount();
        await this.fetchUsers();
    }

    async getUserCount() {
        let count = await DataService.getUserCount();

        this.setState({
            totalCount: count,
            totalPage: Math.ceil(count / this.state.limit)
        })
    }

    fetchUsers() {
        DataService.getUsers(this.state.offset, this.state.limit)
            .then(data => {
                this.setState({
                    users: [...this.state.users, ...data],
                    loading: false
                });
            }).catch(error => {
                if (error.message === "Forbidden") {
                    this.setState({
                        redirect: true
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            });
    }

    render() {
        let contents = this.state.loading ? <p><em>Loading...</em></p> : this.renderUserList();
        let pagination = (this.state.searchKeywords.trim().length == 0 && this.state.users) ? this.renderPagination() : null;

        return this.state.loading ?
            (<div><p><em>Loading user list...</em></p></div>) :
            (<div>
                <Row>
                    <Col lg={9} className="d-flex">
                        <span className="page-header mb-3">Users</span>
                    </Col>
                    <Col lg={3}>
                        <SearchForm keywords={this.state.searchKeywords}
                            onSearch={this.onSearch.bind(this)}
                            onChangeSearchKeywords={this.onChangeSearchKeywords.bind(this)} />
                    </Col>
                </Row>
                <Row><Col lg={12}>{contents}</Col></Row>
                <Row><Col lg={12}>{pagination}</Col></Row>
            </div>);
    }

    renderUserList() {
        if (this.state.users !== undefined && this.state.users !== null && this.state.users.length > 0) {
            return <Table striped bordered responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>User</th>
                        <th>Role</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Status</th>
                        <th>Applications</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.users.map(u =>
                        <tr key={u.userId}>
                            <td>{u.userId}</td>
                            <td>
                                {u.name}<br />
                                <span className="label label-default"><a href={"mailto:" + u.email}>{u.email}</a></span>
                            </td>
                            <td>
                                {u.role === 'user' ? <Badge color="primary">{u.role}</Badge> : <Badge color="warning">{u.role}</Badge>}
                            </td>
                            <td>{u.createdAt}</td>
                            <td>{u.updatedAt}</td>
                            <td>{u.active ? <Badge color="success">active</Badge> : <Badge color="danger">inactive</Badge>}</td>
                            <td><Badge color="secondary">{u.totalApplications}</Badge></td>
                            <td><a href={APIBASEURL.concat('users/u/').concat(u.userId)}>Edit</a></td>
                        </tr>
                    )}
                </tbody>
            </Table>;
        }
        else {
            return <div><p><em>Cannot find any users in database...</em></p></div>;
        }
    }

    renderPagination() {
        let currentP = this.state.offset / this.state.limit;
        let prevPage = currentP <= 0 ? 0 : currentP - 1;
        let nextPage = currentP + 1;
        
        let firstTab = currentP > 0 ? (<PaginationItem>
            <PaginationLink first href={this.pageLink(0)}>{'<<'}</PaginationLink>
        </PaginationItem>) : null;

        let lastTab = currentP < this.state.totalPage - 1 ? (<PaginationItem>
            <PaginationLink last href={this.pageLink(this.state.totalPage - 1)}>{'>>'}</PaginationLink>
        </PaginationItem>) : null;

        let prevTab = this.state.hasPrevPage ? (<PaginationItem>
            <PaginationLink previous href={this.pageLink(prevPage)}>{'<'}</PaginationLink>
        </PaginationItem>) : null;

        let nextTab = this.state.hasNextPage ? (<PaginationItem>
            <PaginationLink next href={this.pageLink(nextPage)}>></PaginationLink>
        </PaginationItem>) : null;

        return (<Row>
            <Col lg={12}>
                <Pagination size="sm" className="justify-content-center">
                    {firstTab}
                    {prevTab}
                    {
                        [...Array(this.state.totalPage)].map((_, i) =>
                            <PaginationItem key={'pi_' + i} active={currentP === i}>
                                <PaginationLink href={this.pageLink(i)}>{i + 1}</PaginationLink>
                            </PaginationItem>
                        )
                    }
                    {nextTab}
                    {lastTab}
                </Pagination>
            </Col>
        </Row>);
    }

    pageLink = (p) => {
        let currentP = (this.state.offset / this.state.limit);
        let offset = this.state.offset + this.state.limit * (p - currentP);
        return APIBASEURL.concat('users/view/').concat(offset).concat('/').concat(this.state.limit);
    }

    onChangeSearchKeywords(e) {
        this.setState({
            searchKeywords: e.target.value
        });
    }

    onSearch(e) {
        e.preventDefault();
        const keywords = this.state.searchKeywords;

        DataService.searchUser(keywords)
            .then(data => {
                this.setState({
                    users: data,
                    totalPage: 1
                });
            }).catch(error => {
                if (error.message === "Forbidden") {
                    this.setState({
                        redirect: true
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            });
    }
}