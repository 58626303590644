import { TokenHelper } from './TokenHelper';
import { APIBASEURL } from './SiteConfig';

/*------------------------------------------
 * Authentication & Authorization Service
 * Author:         Jing Xu
 * Created at:     10th May 2018
 * Last Modified:  21st Aug 2018
---------------------------------------------*/

export const AuthService = {
    forgetPassword: (email) => {
        const url = APIBASEURL.concat('api/account/password/forget');
        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache'
            },
            body: JSON.stringify(email)
        })
            .then((rsp) => rsp.json())
            .then((data) => { return data })
            .catch((err) => { throw err });
    },
    register: async (firstName, lastName, email, password) => {
        const url = APIBASEURL.concat('api/account/register');
        const postData = {
            "FirstName": firstName.trim(),
            "LastName": lastName.trim(),
            "Email": email.trim(),
            "Password": password.trim()
        };

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache'
            },
            body: JSON.stringify(postData)
        })
            .then((rsp) => rsp.json())
            .then((data) => {
                if (data.succeeded)
                    return data;
                else
                    throw new Error(data.error.errorMessage);
            })
            .catch(error => { throw error; });
    },
    login: (email, password) =>  {
        const url = APIBASEURL.concat('api/account/login');
        const postData = {
            "email": email.toString().trim(),
            "password": password ? password.toString().trim() : ''
        };

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache'
            }),
            body: JSON.stringify(postData)
        })
            .then(rsp => rsp.json())
            .then(data => {
                if (data.succeeded) {
                    window.localStorage.setItem('liberum_career_access_token', data.token.access_token);
                    window.localStorage.setItem('liberum_career_access_token_expiry', data.token.access_expires.toString());
                    window.localStorage.setItem('liberum_career_refresh_token', data.token.refresh_token);
                    window.localStorage.setItem('liberum_career_refresh_token_expiry', data.token.refresh_expires.toString());
                    return data;
                }
                else {
                    throw new Error(data.error.errorMessage);
                }
            })
            .catch(error => {
                throw error;
            });
    },
    verifyEmail: (verify_token) => {
        const url = APIBASEURL.concat('api/account/verify/?token=').concat(verify_token);
        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache'
            })
        })
            .then(rsp => rsp.json())
            .then(data => {
                if (data.succeeded) {
                    window.localStorage.setItem('liberum_career_access_token', data.token.access_token);
                    window.localStorage.setItem('liberum_career_access_token_expiry', data.token.access_expires.toString());
                    window.localStorage.setItem('liberum_career_refresh_token', data.token.refresh_token);
                    window.localStorage.setItem('liberum_career_refresh_token_expiry', data.token.refresh_expires.toString());
                }
                return data;
            })
            .catch(error => { throw error; });
    },
    resetPassword: (email, password) => {
        const url = APIBASEURL.concat('api/account/password/reset/');
        const postData = {
            "email": email.trim(),
            "password": password.trim()
        };

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache'
            }),
            body: JSON.stringify(postData)
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(error => { throw error; });
    },
    fetchAccount: () => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/account/auth');

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            }),
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err; });
    },
    logout() {
        const ok = TokenHelper.removeJwt();
        return ok;
    }
}