import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationToolkit } from './ApplicationToolkit';

export class CheckAcknowledgement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            agree: this.props.agree
        };

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    render() {
        return (<div className="application-section">
            <Row>
                <Col md={12}>
                    <h2>Acknowledgement</h2>
                    <p>Please agree with the following statement before proceeding with your application:</p>
                    <p>Your information will be securely held by Liberum Capital Ltd. </p>
                    <p>We carry out most of our credit searches and employment referencing using Vero Screening, but details may be disclosed to other agencies. You must agree to disclose all relevant personal details to them before commencing employment with us. </p>
                    <p>I confirm that:</p>
                    <ul className="ack-list">
                        <li>I understand that my Disclosure information will only be used for the specific purpose for which it was requested and for which my full consent has been given.</li>
                        <li>The information that I have provided in support of this application is complete and true.</li>
                        <li>I consent to the DBS or Disclosure Scotland checking the details I have provided in support of this application against the data sources specified in the notes for guidance, in order to verify my identity and process this application.</li>
                    </ul>
                    <p>These details may be recorded and used to assist other organisations for identity and verification purposes.</p>
                    <p>I confirm that the information supplied in this application is true, complete, and accurate. I understand that if any of the information is found to be false, any offer of employment may be withdrawn, or employment with Liberum Capital Ltd may be terminated.</p>
                    <p>I agree and give my permission that:</p>
                    <ul className="ack-list">
                        <li>Employment, academic and credit checks can be carried out;</li>
                        <li>You may make searches at credit reference agencies;</li>
                        <li>You may check the information I have provided against Fraud Prevention Databases;</li>
                        <li>If my application is unsuccessful, Liberum will keep my details on file for future relevant opportunities for five years, at which time it will be permanently deleted.</li>
                    </ul>
                    <p>Please let us know if you have any questions about the use of your personal information.</p>
                    <p><GDPRPolicyLink /></p>
                </Col>
            </Row>
            <Form>
                <FormGroup row>
                    <Col md={12}>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" defaultChecked={this.state.agree} onChange={(e) => this.onAgreeChanged(e)} />
                                {' '.concat("I agree")}
                            </Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
                <ApplicationToolkit stage={2} valid={this.state.agree} back={this.handleBackClick} next={this.handleNextClick} />
            </Form>
        </div>);
    }

    onAgreeChanged(e) {
        let a = e.target.checked;
        this.setState({
            agree: a
        });
    }

    handleBackClick() {
        this.props.back();
    }

    handleNextClick() {
        this.props.next(this.state.agree);
    }
}

const GDPRPolicyLink = () => {
    return (<Link to={'/files/gdpr.pdf'} target="_blank">Candidate Privacy Policy</Link>);
};