import React from 'react';
import { Row, Col, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';

const ViewJobDetail = ({ job, history, admin }) => {
    console.log(admin);
    let d = new Date(job.closingDate);
    let link = admin ? <Link color="primary" to={'/job/edit/' + job.id}>Edit</Link> : <Link color="primary" to={'/apply/job/' + job.id}>Apply</Link>;

    return (<div className="view-job">
        <Row>
            <Col lg={12}>
                <div><label>Job ID</label> {'# ' + job.id}</div>
                <div><label>Location</label> {job.location}</div>
                <div><label>Contract Type</label> {job.contractType}</div>
                <div><label>Closing Date</label> {d.toLocaleDateString()}</div>
            </Col>
        </Row>
        <Row>
            <Col lg={12} className="excerpt">
                <p><label>Excerpt</label></p>
                {job.excerpt}
            </Col>
        </Row>
        <Row>
            <Col lg={12} className="description">
                <p><label>Description</label></p>
                {parse(job.description)}
            </Col>
        </Row>
        <Row className="toolkit">
            <Col lg={6} className="text-left">
                <Button color="link" size="sm" onClick={(e) => { history.goBack() }}>&lt;&nbsp;Back</Button>
            </Col>
            <Col lg={6} className="text-right">{link}</Col>
        </Row>
    </div>);
}

const ViewJobToolkit = ({ auth, active, id, total, totalUnread, totalShortlisted}) => {
    let toolkit = null;

    if (auth.authenticated && auth.admin) {
        let lnkAll = (total > 0) ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/jobs/application/' + id}>Applications <Badge color="secondary">{total}</Badge></Link></span> : "";
        let lnkUnread = (totalUnread > 0) ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/jobs/application/' + id + '/unread'}>Unread <Badge color="secondary">{totalUnread}</Badge></Link></span> : "";
        let lnkShortlisted = (totalShortlisted > 0) ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/jobs/application/' + id + '/shortlisted'}>Shortlisted <Badge color="secondary">{totalShortlisted}</Badge></Link></span> : "";

        toolkit = <div>
            <Link to={'/job/edit/' + id}>Edit</Link>
            {lnkAll}
            {lnkUnread}
            {lnkShortlisted}
        </div>;
    }
    else {
        if (active) {
            toolkit = <div>
                <Link to={'/job/view/' + id}>View</Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to={'/apply/job/' + id}>Apply</Link>
            </div>;
        }
        else {
            toolkit = <span>Job is closed</span>;
        }
    }

    return (toolkit);
};

export { ViewJobDetail, ViewJobToolkit };