import React, { Component} from 'react';
import { Container, Row } from 'reactstrap';
import DisplayAlert from './DisplayAlert';
import { NavMenu } from './NavMenu';
import './Layout.css';

export class Layout extends Component {
    static displayName = Layout.name;
    
    constructor(props) {
        super(props);

        this.state = {
            auth: this.props.auth,
            scrollMainClass: 'main',
            alert: {
                alertType: '',
                message: ''
            }
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            return {
                auth: {
                    userId: nextProps.auth.userId,
                    userName: nextProps.auth.userName,
                    authenticated: nextProps.auth.authenticated,
                    admin: nextProps.auth.admin
                }
            };
        }
        if (nextProps.alert !== prevState.alert) {
            return {
                alert: {
                    alertType: nextProps.alertType,
                    message: nextProps.message
                }
            };
        }
        return null;
    }

    render() {
        return (<div>
            <NavMenu auth={this.state.auth} />
            <div className="main-container">
                <Container fluid className={this.state.scrollMainClass}>
                    <div className="content">
                        {this.renderChildren()}
                    </div>
                    <Row>
                        <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
                    </Row> 
                </Container>
            </div>
            <div className="footer">
                <Container fluid>
                    <div className="footer-term">
                        Liberum Capital Limited is authorised and regulated by the Financial Conduct Authority / Registered in England &amp; Wales No. 5912554 / Liberum Capital Inc. is a FINRA Member Firm and is regulated by the Securities and Exchange Commission (SEC)
                    </div>
                    <div className="footer-logo-wrapper">
                        <div className="footer-logo">
                            <svg viewBox="0 0 164.4 27" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                                <path d="M55 12.7c2-.6 4-2.7 4-5.9 0-6-5.1-6.8-9.4-6.8h-9.5v26.6H49c3.9 0 5.8 0 7.8-1.4 2.9-1.8 3-4.6 3-5.9.1-5.3-3.8-6.2-4.8-6.6zM43.8 3.1h6.5c1.4 0 5.1.1 5.1 3.9 0 4.6-4.2 4.3-7.8 4.3h-3.8V3.1zm9.4 20.1c-.8.2-1.4.2-3.3.2h-6.1v-9h4.7c3.2 0 4.1.1 5.2.5.3.1 2.5 1 2.5 4.1.1 2.2-.8 3.7-3 4.2zM100.7 14.2l.4-.1c1.3-.2 6.1-1.6 6.1-6.7 0-5.7-4-7.4-9.9-7.4h-9.9l7.3 11.4h-7v15.2h3.7V14.5h5.2l7.8 12.1h4.2l-7.9-12.4zM93.5 3.1h4c3.5 0 5.8 1 5.8 4.3 0 2.9-2.3 3.7-4.6 3.9l-5.2-8.2zM68.2 14.5h12.7v-3.1H68.2V3.1h13.9V0H64.5v26.6h18v-3.1H68.2zM3.7 0H0v26.6h16.2v-3.1H3.7zM19.2 3.1h6.5v20.4h-6.5v3.1h16.7v-3.1h-6.5V3.1h6.5V0H19.2zM128.4 17.2c0 1.4 0 3-1 4.2-1.7 1.9-3.7 2.3-5.3 2.3h-.2c-1.6 0-3.5-.5-5.1-2.3-1-1.2-.9-2.8-1-4.2V0h-3.6v17c0 2.8.3 4.9 1.7 6.7 2.2 2.6 5.1 3.3 8.2 3.3s6-.7 8.2-3.3c1.4-1.8 1.7-3.9 1.7-6.7V0h-3.6v17.2zM160.2 0l-9.4 14.5L141.6 0h-4.5v26.6h3.7V5.5l7.8 12.4h4.2l7.9-12.2v20.9h3.7V0z"></path>
                            </svg>
                        </div>
                    </div>
                </Container>
            </div>
        </div>);
    }

    renderChildren() {
        return React.Children.map(this.props.children, (child, i) => {
            if (React.isValidElement(child)) {
                let childProps = {
                    auth: this.state.auth
                };
                return React.cloneElement(child, childProps);
            }
        });
    }
}
