import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import {
    Row, Col, Badge, Card, CardHeader, CardBody, CardFooter,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import { Pagination, Spinner } from 'react-bootstrap';
import './SearchJob.css';
import { SearchForm } from '../SearchForm';
import { DataService } from '../../helpers/DataService';
import { APIBASEURL } from '../../helpers/SiteConfig';
import moment from 'moment';
import update from 'immutability-helper';

export class SearchJob extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            backup: [],
            loading: true,
            searchKeywords: '',
            hasNextPage: true,
            hasPrevPage: true,
            total: 0,
            totalPages: 0,
            boundary: [1, 1],
            ellipsis: 5,
            limit: this.props.params.limit && !isNaN(parseInt(this.props.params.limit)) ? parseInt(this.props.params.limit) : 5,
            currentPage: this.props.params.page && !isNaN(parseInt(this.props.params.page)) ? parseInt(this.props.params.page) : 1,
            mode: this.props.params.mode ? this.props.params.mode : "",
            backdrop: true,
            showModal: false,
            copyJobId: 0
        };

        this.onSearch = this.onSearch.bind(this);
        this.onDuplicate = this.onDuplicate.bind(this);
        this.duplicateJob = this.duplicateJob.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    
    async componentDidMount() {
        await this.getJobs(this.state.currentPage, this.state.mode, this.state.limit);
        await this.getJobCount();
    }

    async getJobCount() {
        const count = await DataService.getJobCount(this.state.mode);
        const totalPages = Math.ceil(parseInt(count) / this.state.limit);

        let lowBound = 1;
        let highBound = 1;

        if (totalPages >= 10) {
            if (this.state.currentPage <= 10) {
                lowBound = 1;
                highBound = 10;
            }
            else if (this.state.currentPage >= totalPages - 9) {
                lowBound = totalPages - 9;
                highBound = totalPages;
            }
            else {
                lowBound = this.state.currentPage;
                highBound = this.state.currentPage + 9 <= totalPages ? this.state.currentPage + 9 : totalPages;
            }
        }
        else
            highBound = totalPages;

        this.setState(prevState => ({
            total: parseInt(count),
            totalPages: totalPages,
            boundary: [lowBound, highBound],
            hasNextPage: this.state.currentPage < totalPages,
            hasPrevPage: this.state.currentPage > 1
        }));
    }

    async getJobs(p, m, limit) {
        let data = [];
        if (m === "archived") {
            data = await DataService.getArchivedJobs(p, limit);
        }
        else {
            data = await DataService.getJobs(p, limit);
        }
        
        this.setState(prevState => ({
            jobs: data.length > 0 ? data : [],
            backup: data.length > 0 ? data : [],
            loading: false
        })); 
    }

    pageTitle = () => (this.props.mode === "archived" ? "Archived Jobs" : "All Jobs");

    render() {
        let pagination = this.state.jobs.length > 0 ? this.renderPagination(this.state.mode, this.state.currentPage, this.state.totalPages) : <div />;
        let content = null;

        content = this.state.loading ? (<div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>)
            : this.state.jobs.map(job => {
                return (<Card key={'job_' + job.id} className="card-block">
                    <CardHeader>
                        <Row>
                            <Col lg={3}><span className="strong">{job.title}</span></Col>
                            <Col lg={3}><span className="strong">Closing Date: </span>{job.closingDate}</Col>
                            <Col lg={2}><span className="strong">Location: </span>{job.location}</Col>
                            <Col lg={3}><span className="strong">Contract Type: </span>{job.contractType}</Col>
                            <Col lg={1} className="text-right">
                                {job.active ? <Badge color="primary">Live</Badge> : <Badge color="danger">Closed</Badge>}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {job.excerpt == null || job.excerpt.length == 0 ? job.description.substring(0, 350) : job.excerpt}
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col lg={12} className="text-right">
                                {this.renderJobToolkit(job.active, job.id, job.total, job.totalUnread,
                                    job.totalShortlisted)}
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>);
            });

        return (<div>
            <Row>
                <Col lg={9} className="d-flex">
                    <div className="page-header mb-5">{this.pageTitle()}</div>
                </Col>
                <Col lg={3}>
                    <SearchForm keywords={this.state.searchKeywords} onChangeSearchKeywords={(e) => this.onChangeSearchKeywords(e)}
                        onSearch={this.onSearch} />
                </Col>
            </Row>
            <Row>
                {this.renderModal()}
                <Col lg={12}>{content}</Col>
            </Row>
            {pagination}
        </div>);
    }

    renderPagination(mode, p, totalPages) {
        let firstTab = p > 1 ? (<Pagination.First href={this.pageLink(mode, 1)} />) : null;
        let lastTab = p < totalPages ? (<Pagination.Last href={this.pageLink(mode, totalPages)} />) : null;

        let prevTab = this.state.hasPrevPage ? (<Pagination.Prev key={p - 1} href={this.pageLink(mode, (p - 1))} />) : null;
        let nextTab = this.state.hasNextPage ? (<Pagination.Next key={p + 1} href={this.pageLink(mode, (p + 1))} />) : null;

        const lowBound = this.state.boundary[0];
        const highBound = this.state.boundary[1];
        let prevEllipsis = lowBound > 1 ? (<Pagination.Ellipsis onClick={this.onClickEllipsisPrev} />) : null;
        let nextEllipsis = highBound < totalPages ? (<Pagination.Ellipsis onClick={this.onClickEllipsisNext} />) : null;

        let pageItems = [];
        for (var i = this.state.boundary[0]; i <= this.state.boundary[1]; i++) {
            pageItems.push(<Pagination.Item key={'pagination_item_' + i} href={this.pageLink(mode, i)} active={p === i}>{i}</Pagination.Item>);
        }

        return (<Row>
            <Col lg={12}>
                <Pagination size="md" className="justify-content-center">
                    {firstTab}
                    {prevTab}
                    {prevEllipsis}
                    {
                        [...Array(pageItems)].map(item => {
                            return (item);
                        })
                    }
                    {nextEllipsis}
                    {nextTab}
                    {lastTab}
                </Pagination>
            </Col>
        </Row>);
    }

    renderModal() {
        return (<Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop={this.state.backdrop}>
            <ModalHeader toggle={this.toggle}>Please confirm duplication</ModalHeader>
            <ModalBody>
                <div>
                    <p>Once the job is duplicated, you may want to change the closing date and active the job.</p>
                    <p>Are you sure to duplicate this job?</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={this.duplicateJob}>Yes</Button>
            </ModalFooter>
        </Modal>);
    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    } 

    onClickEllipsisPrev() {
        const lowBound = this.state.boundary[0] - this.state.ellipsis < 1 ? 1 : this.state.boundary[0] - this.state.ellipsis;
        const highBound = this.state.boundary[1] - this.state.ellipsis < 1 ? 1 : this.state.boundary[1] - this.state.ellipsis;

        this.setState({
            boundary: [lowBound, highBound]
        });
    }

    onClickEllipsisNext() {
        const lowBound = this.state.boundary[0] + this.state.ellipsis > this.state.totalPages ? this.state.totalPages : this.state.boundary[0] + this.state.ellipsis;
        const highBound = this.state.boundary[1] + this.state.ellipsis > this.state.totalPages ? this.state.totalPages : this.state.boundary[1] + this.state.ellipsis;

        this.setState({
            boundary: [lowBound, highBound]
        });
    }

    pageLink = (mode, p) => {
        if (mode == undefined || mode === "")
            return APIBASEURL.concat('jobs/p/' + p);
        else
            return APIBASEURL.concat('jobs/' + mode + "/p/" + p);
    }

    renderJobToolkit(active, id, total, totalUnread, totalShortlisted) {
        let toolkit = null;

        if (this.props.auth.authenticated && this.props.auth.admin) {
            let lnkAll = (total > 0) ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/jobs/application/' + id}>Applications <Badge color="secondary">{total}</Badge></Link></span> : "";
            let lnkUnread = (totalUnread > 0) ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/jobs/application/' + id + '/unread'}>Unread <Badge color="secondary">{totalUnread}</Badge></Link></span> : "";
            let lnkShortlisted = (totalShortlisted > 0) ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/jobs/application/' + id + '/shortlisted'}>Shortlisted <Badge color="secondary">{totalShortlisted}</Badge></Link></span> : "";

            toolkit = <div>
                <Link to={'#'} onClick={(evt) => { this.onDuplicate(id) }}>Copy</Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to={'/job/edit/' + id}>Edit</Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to={'/job/view/' + id}>View</Link>
                {lnkAll}
                {lnkUnread}
                {lnkShortlisted}
            </div>;
        }
        else {
            if (active) {
                toolkit = (<div>
                    <Link to={'/job/view/' + id}>View</Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to={'/apply/job/' + id}>Apply</Link>
                </div>);
            }
            else {
                toolkit = (<span>Job is closed</span>);
            }
        }

        return (toolkit);
    }

    onDuplicate(id) {
        console.log(id);
        this.setState(prevState => ({
            showModal: true,
            copyJobId: id
        }));
    }

    async duplicateJob() {
        var job = await DataService.getJob(this.state.copyJobId);

        var d = new Date(job.closingDate);
        job.closingDate = moment(d).add(1, 'M');

        job.active = true;
        job.archived = false;
        console.log(job);

        DataService.duplicateJob(job)
            .then(data => {
                this.props.history.push('/job/edit/' + data.id + '/duplicated');

                this.setState({
                    id: data.id,
                    job: update(this.state.job, {
                        id: { $set: data.id },
                        title: { $set: data.title },
                        excerpt: { $set: data.excerpt },
                        description: { $set: data.description },
                        location: { $set: data.location },
                        contractType: { $set: data.contractType },
                        closingDate: { $set: data.closingDate },
                        active: { $set: data.active },
                        archived: { $set: data.archived },
                    }),
                    oldJob: update(this.state.oldJob, {
                        id: { $set: data.id },
                        title: { $set: data.title },
                        excerpt: { $set: data.excerpt },
                        description: { $set: data.description },
                        location: { $set: data.location },
                        contractType: { $set: data.contractType },
                        closingDate: { $set: data.closingDate },
                        active: { $set: data.active },
                        archived: { $set: data.archived },
                    }),
                    form: update(this.state.form, {
                        inputDate: { $set: new Date(data.closingDate) }
                    }),
                    showModal: false,
                    copyJobId: 0,
                    alert: update(this.state.alert, {
                        alertType: { $set: 'info' },
                        message: { $set: 'Job is now duplicated.' }
                    })
                });
            })
            .catch(err => {
                this.setState({
                    alertType: 'error',
                    message: err
                });
            });
    }

    onChangeSearchKeywords(e) {
        this.setState({
            searchKeywords: e.target.value
        });
    }

    onSearch(e) {
        e.preventDefault();

        const keyword = this.state.searchKeywords;
        const jobs = this.state.backup;

        this.setState({
            jobs: jobs.filter(j => j.title.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
                || j.description.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) >= 0
                || j.location.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) >= 0
                || j.contractType.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) >= 0)
        });
    }
}