import React, { Component } from 'react';
import { CheckEligibility } from './CheckEligibility';
import { CheckAcknowledgement } from './CheckAcknowledgement';
import { FillInBasicDetail } from './FillInBasicDetail';
import { DropZone } from './DropZone';
import { FillInCareerDetail } from './FillInCareerDetail';
import { Login } from '../account/Login';
import DisplayAlert from '../DisplayAlert';
import './ApplicationContent.css';

export class ApplicationContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            auth: this.props.auth,
            application: this.props.application,
            alert: this.props.alert
        }

        this.onEligibilityChange = this.onEligibilityChange.bind(this);
        this.onAgreeChanged = this.onAgreeChanged.bind(this);
        this.onBasicDetailSubmit = this.onBasicDetailSubmit.bind(this);
        this.onCareerDetailsSubmit = this.onCareerDetailsSubmit.bind(this);
        this.onUploadCV = this.onUploadCV.bind(this);
        this.onRemoveCV = this.onRemoveCV.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if( nextProps.application !== prevState.application)
        {
            return {
                application: {
                    acknowledged: nextProps.application.acknowledged,
                    applicable: nextProps.application.applicable,
                    applicationId: nextProps.application.applicationId,
                    coverLetter: nextProps.application.coverLetter,
                    coverLetterExcerpt: nextProps.application.coverLetterExcerpt,
                    createdAt: nextProps.application.createdAt,
                    currency: nextProps.application.currency,
                    cv: nextProps.application.cv,
                    department: nextProps.application.department,
                    editable: nextProps.application.editable,
                    education: nextProps.application.education,
                    eligibility: nextProps.application.eligibility,
                    eligibilityDetails: nextProps.application.eligibilityDetails,
                    experience: nextProps.application.experience,
                    jobId: nextProps.application.jobId,
                    jobTitle: nextProps.application.jobTitle,
                    keywords: nextProps.application.keywords,
                    location: nextProps.application.location,
                    qualification: nextProps.application.qualification,
                    read: nextProps.application.read,
                    salary: nextProps.application.salary,
                    salaryInfo: nextProps.application.salaryInfo,
                    status: nextProps.application.status,
                    updatedAt: nextProps.application.updatedAt,
                    user: {
                        userId: nextProps.application.user.userId,
                        firstName: nextProps.application.user.firstName,
                        lastName: nextProps.application.user.lastName,
                        email: nextProps.application.user.email,
                        phone: nextProps.application.user.phone,
                        addressId: nextProps.application.user.addressId,
                        line1: nextProps.application.user.line1,
                        line2: nextProps.application.user.line2,
                        city: nextProps.application.user.city,
                        county: nextProps.application.user.county,
                        postcode: nextProps.application.user.postcode,
                        country: nextProps.application.user.country,
                        active: nextProps.application.user.active,
                        admin: nextProps.application.user.admin
                    }
                }
            };
        }
        return null;
    }

    render() {
        if (!this.state.application) {
            return <div><p>Cannot find application ...</p></div>;
        }
        else {
            let contents = null;
            switch (this.props.stage) {
                case 1:
                    console.log("step 1: check eligibility");
                    contents = <CheckEligibility jobId={this.props.jobId} eligible={this.state.application.eligibility}
                        details={this.state.application.eligibilityDetails} next={this.onEligibilityChange} />;
                    break;

                case 2:
                    console.log("step 2: concent");
                    contents = <CheckAcknowledgement jobId={this.props.jobId} agree={this.state.application.acknowledged}
                        next={this.onAgreeChanged} back={this.onBack.bind(this)} />;
                    break;

                case 3:
                    console.log("step 3: basic detail");
                    if (!this.props.auth.admin) {
                        if (this.props.auth.authenticated) {
                            contents = <FillInBasicDetail jobId={this.props.jobId} data={this.state.application}
                                next={this.onBasicDetailSubmit} back={this.onBack} />;
                        }
                        else {
                            contents = <div className="application-section">
                                <Login embedded={true} handleLogin={this.props.handleLogin} alert={this.state.alert}
                                    history={this.props.history} />
                            </div>;
                        }
                    }
                    else {
                        contents = (<p>Sorry, system admin is not allowed to apply for a job.</p>);
                    }
                    break;

                case 4:
                    console.log("step 4: career detail");
                    contents = <FillInCareerDetail jobId={this.props.jobId} data={this.state.application}
                        next={this.onCareerDetailsSubmit} back={this.onBack} />;
                    break;

                case 5:
                    console.log("step 5: upload cv");
                    contents = <DropZone jobId={this.props.jobId} files={this.state.application.cv} allowMutiple={false}
                        removeCV={this.onRemoveCV} uploadCV={this.onUploadCV} back={this.onBack} />;
                    break;

                default:
                    break;
            }

            return (<div>
                {contents}
                <DisplayAlert alertType={this.props.alert.alertType} header={''} message={this.props.alert.message} />
            </div>);
        }
    }


    onEligibilityChange(value, details) {
        this.props.checkEligibility(value, details);
    }

    onAgreeChanged(value) {
        this.props.checkAcknowledgement(value);
    }

    onBasicDetailSubmit(value) {
        this.props.saveBasicDetails(value);
    }

    onCareerDetailsSubmit(value) {
        this.props.saveCareerDetails(value);
    }

    onUploadCV(files) {
        this.props.uploadCV(files);
    }

    onRemoveCV() {
        this.props.removeCV();
    }

    onBack() {
        let s = this.props.stage - 1;
        if (s >= 1 && s <= 5) {
            this.setState({
                stage: s
            });
        }
        this.props.changeStage(s);
    }
}