import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { ApplicationToolkit } from './ApplicationToolkit';

export class FillInBasicDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.data.user,
            alert: this.props.alert
        };
        
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.data !== null && (nextProps.data.user.userId !== prevState.user.userId)) {
            console.log("derive basic detail user changed");

            return {
                user: {
                    userId: nextProps.data.user.userId,
                    firstName: nextProps.data.user.firstName,
                    lastName: nextProps.data.user.lastName,
                    email: nextProps.data.user.email,
                    phone: nextProps.data.user.phone,
                    addressId: nextProps.data.user.addressId,
                    line1: nextProps.data.user.line1,
                    line2: nextProps.data.user.line2,
                    city: nextProps.data.user.city,
                    county: nextProps.data.user.county,
                    postcode: nextProps.data.user.postcode,
                    country: nextProps.data.user.country,
                    active: nextProps.data.user.active,
                    admin: nextProps.data.user.admin
                }
            };
        }
        if (nextProps.alert !== prevState.alert) {
            return {
                alert: {
                    alertType: nextProps.alert.alertType,
                    message: nextProps.alert.message
                }
            };
        }
        return null;
    }

    render() {
        return (<div className="application-section">
            <Form>
                <Row>
                    <Col lg={12}>
                        <h2>Basic Details</h2>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col lg={3}>First Name</Col>
                    <Col lg={5}>{this.state.user.firstName}</Col>
                </FormGroup>
                <FormGroup row>
                    <Col lg={3}>Last Name</Col>
                    <Col lg={5}>{this.state.user.lastName}</Col>
                </FormGroup>
                <FormGroup row>
                    <Col lg={3}>Email</Col>
                    <Col lg={5}>{this.state.user.email}</Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Phone" lg={3}>Contact Number <span className="red">*</span></Label>
                    <Col lg={5}>
                        <Input type="text" name="phone" id="Phone" value={this.state.user.phone ? this.state.user.phone : ''}
                            placeholder="Contact Number" onChange={(e) => { this.onPhoneChanged(e) }} />
                    </Col>
                </FormGroup>
                <Row>
                    <Col lg={12}>
                        <h3>Address</h3>
                    </Col>
                </Row>
                <FormGroup row>
                    <Label for="AddressLine1" lg={3}>Address Line 1 <span className="red">*</span></Label>
                    <Col lg={5}>
                        <Input type="text" name="AddressLine1" id="AddressLine1" value={this.state.user.line1 ? this.state.user.line1 : ''}
                            placeholder="Address Line 1" onChange={(e) => { this.onLine1Changed(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="AddressLine2" lg={3}>Address Line 2</Label>
                    <Col lg={5}>
                        <Input type="text" name="AddressLine2" id="AddressLine2" value={this.state.user.line2 ? this.state.user.line2 : ''}
                            placeholder="Address Line 2" onChange={(e) => { this.onLine2Changed(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="City" lg={3}>City <span className="red">*</span></Label>
                    <Col lg={5}>
                        <Input type="text" name="City" id="City" value={this.state.user.city ? this.state.user.city : ''}
                            placeholder="City" onChange={(e) => { this.onCityChanged(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="County" lg={3}>County</Label>
                    <Col lg={5}>
                        <Input type="text" name="County" id="County" value={this.state.user.county ? this.state.user.county : ''}
                            placeholder="County" onChange={(e) => { this.onCountyChanged(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Postcode" lg={3}>Postcode <span className="red">*</span></Label>
                    <Col lg={5}>
                        <Input type="text" name="Postcode" id="Postcode" value={this.state.user.postcode ? this.state.user.postcode : ''}
                            placeholder="Postcode" onChange={(e) => { this.onPostcodeChanged(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Country" lg={3}>Country <span className="red">*</span></Label>
                    <Col lg={5}>
                        <Input type="text" name="Country" id="Country" value={this.state.user.country ? this.state.user.country : ''}
                            placeholder="Country" onChange={(e) => { this.onCountryChanged(e) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col lg={12}><span className="red">*</span> Indicates required field.</Col>
                </FormGroup>
                <ApplicationToolkit stage={3} valid={this.checkValidation()} back={this.handleBackClick} next={this.handleNextClick} />
            </Form>
        </div>);
    }

    onPhoneChanged(e) {
        let user = this.state.user;
        user.phone = e.target.value;

        this.setState({
            user: user
        });
    }

    onLine1Changed(e) {
        let user = this.state.user;
        user.line1 = e.target.value;

        this.setState({
            user: user
        });
    }

    onLine2Changed(e) {
        let user = this.state.user;
        user.line2 = e.target.value;

        this.setState({
            user: user
        });
    }

    onCityChanged(e) {
        let user = this.state.user;
        user.city = e.target.value;

        this.setState({
            user: user
        });
    }

    onCountyChanged(e) {
        let user = this.state.user;
        user.county = e.target.value;

        this.setState({
            user: user
        });
    }

    onPostcodeChanged(e) {
        let user = this.state.user;
        user.postcode = e.target.value;

        this.setState({
            user: user
        });
    }

    onCountryChanged(e) {
        let user = this.state.user;
        user.country = e.target.value;

        this.setState({
            user: user
        });
    }

    checkValidation() {
        let valid = true;

        if (!this.state.user.firstName || this.state.user.firstName.trim().length <= 0)
            valid = false;

        if (!this.state.user.lastName || this.state.user.lastName.trim().length <= 0)
            valid = false;

        if (!this.state.user.email || this.state.user.email.trim().length <= 0)
            valid = false;

        if (!this.state.user.phone || this.state.user.phone.trim().length <= 0)
            valid = false;

        if (!this.state.user.line1 || this.state.user.line1.trim().length <= 0)
            valid = false;

        else if (!this.state.user.city || this.state.user.city.trim().length <= 0)
            valid = false;

        else if (!this.state.user.postcode || this.state.user.postcode.trim().length <= 0)
            valid = false;

        else if (!this.state.user.country || this.state.user.country.trim().length <= 0)
            valid = false;
                            
        return valid;
    }

    handleBackClick() {
        this.props.back();
    }

    handleNextClick() {
        this.props.next(this.state.user);
    }
}