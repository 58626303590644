import React from 'react';
import { Row, Col, Card, CardTitle, CardText, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const SuccessScreen = () => (
    <div>
        <Card body inverse color="success">
            <CardTitle>Check your email!</CardTitle>
            <CardText>We've sent an email to your email address. Click the link in the email to reset your password.</CardText>
            <CardText>If you don't see a message in your inbox, make sure the email address listed above is correct
                and check your spam or junk mail folder. This email is sent from &nbsp;
                <a class="card-inverse" href="mailto:donotreply@liberum.com">donotreply@liberum.com</a>.
            </CardText>
        </Card>
    </div>
);

const ForgetPasswordForm = ({ email, valid, onRecoveryEmailChange, handleRecover }) => {
    return (<div>
        <Row>
            <Col md={12} className="d-flex">
                <div className="page-header mb-4">Forget Password</div>
            </Col>
        </Row>
        <Form>
            <FormGroup row>
                <Col lg={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button disabled>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Button>
                        </InputGroupAddon>
                        <Input type="text" name="email" id="Email" value={email}
                            onChange={(e) => onRecoveryEmailChange(e)} placeholder="Email" />
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col lg={6}>
                    <Button type="button" name="send" id="sendEmail" color="primary"
                        disabled={!valid} onClick={(e) => handleRecover(e)}>Send Recovery Email</Button>
                </Col>
            </FormGroup>
        </Form>
    </div>);
}

export { ForgetPasswordForm, SuccessScreen };