import { TokenHelper } from "./TokenHelper";
import { APIBASEURL } from "./SiteConfig";

export const DataService = {
    getJob: async (id) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/' + id.toString());
        const headers = access_token ? new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        })
            .then(res => res.json())
            .then(data => { return data; })
            .catch(err => { throw err; }); 
    },
    getJobs: async (p, limit) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/page/' + p + "/" + limit);

        const headers = access_token ? new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    },
    getJobCount: async (jobType) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/count/' + jobType);
        const headers = access_token ? new Headers({
            'Accpet': 'application/json',
            'Conent-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: headers
        })
            .then((rsp) => rsp.json())
            .then(data => { return data })
            .catch(err => { throw err });
    },
    getArchivedJobs: async (p, limit) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/archived/page/' + p + "/" + limit);
        const headers = access_token ? new Headers({
            'Accpet': 'application/json',
            'Conent-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: headers
        })
            .then((rsp) => rsp.json())
            .then(data => { return data })
            .catch(err => { throw err });
    },
    getProfile: function() {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/account/profile');

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            })
        })
            .then((rsp) => rsp.json())
            .then((data)=> { return data })
            .catch(err => { return err });
    },
    updateBasicProfileInfo: (info) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/user/' + info.userId.toString() + '/profile');
        const data = JSON.stringify({
            'userId': info.userId,
            'firstName': info.firstName,
            'lastName': info.lastName
        });

        return fetch(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            },
            body: data
        })
            .then(rsp => rsp.json())
            .then(data => { return data })
            .catch(err => { return err });
    },
    updateContactDetails: (contact) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/user/').concat(contact.userId).concat('/address');
        const data = JSON.stringify({
            'userId': contact.userId,
            'phone': contact.phone ? contact.phone : '',
            'addressId': contact.addressId ? contact.addressId : 0,
            'line1': contact.line1 ? contact.line1 : '',
            'line2': contact.line2 ? contact.line2 : '',
            'city': contact.city ? contact.city : '',
            'county': contact.county ? contact.county : '',
            'postcode': contact.postcode ? contact.postcode : '',
            'country': contact.country ? contact.country : ''
        }); 
        let method = null;
        if (contact.addressId !== null && contact.addressId > 0) {
            method = "PUT";
        } else
            method = "POST";

        return fetch(url, {
            method: method,
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            },
            body: data
        })
            .then(rsp => rsp.json())
            .then(data => { return data })
            .catch(err => { return err });
    },
    createJob: (job) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/');

        return fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer '.concat(access_token)
            },
            body: JSON.stringify(job)
        })
            .then(response => response.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    },
    updateJob: (job) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/' + job.id.toString());

        return fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(access_token)
            }),
            body: JSON.stringify(job)
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    },
    duplicateJob: (job) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/job/');

        return fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(access_token)
            },
            body: JSON.stringify(job)
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    },
    getUsers: (offset, limit) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/user/offset/' + offset + "/limit/" + limit);

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(access_token)
            })
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err; });
    },
    getUserCount: async () => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/user/count');
        const headers = access_token ? new Headers({
            'Accpet': 'application/json',
            'Conent-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

       return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: headers
        })
            .then((rsp) => rsp.json())
            .then(data => { return data })
            .catch(err => { throw err });
    },
    searchUser: (keyword) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/user/search/' + keyword);
        const headers = access_token ? new Headers({
            'Accpet': 'application/json',
            'Conent-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

        return fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            headers: headers
        })
            .then((rsp) => rsp.json())
            .then(data => { return data })
            .catch(err => { throw err });
    },
    getUserById: (id) => {
        const access_token = TokenHelper.getJwt();
        const url = APIBASEURL.concat('api/user/' + id);
        const header = access_token ? new Headers({
            'Accpet': 'application/json',
            'Conent-Type': 'application/json',
            'Cache-control': 'no-store, no-cache, must-revalidate',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer '.concat(access_token)
        }) : new Headers({});

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: header
        })
            .then(rsp => rsp.json())
            .then(data => { return data; })
            .catch(err => { throw err });
    }
}