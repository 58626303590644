import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import './NavMenu.css';
import { APIBASEURL } from '../helpers/SiteConfig';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        const href = window.location.href;

        this.state = {
            auth: this.props.auth,
            jobActive: href.indexOf('/jobs/') >= 0,
            userActive: href.indexOf('/users/') >= 0,
            applicationActive: href.indexOf('/applications/') >= 0,
            jobMenuOpen: false,
            accountSectionOpen: false,
            scrollNavClass: "navbar",
            scrollLogoClass: "logo hidden",
            mainAccountHidden: false,
            scrollAccountHidden: true
        };

        this.jobMenuToggle = this.jobMenuToggle.bind(this);
        this.toggleAccountSection = this.toggleAccountSection.bind(this);
        this.handleScroll = this.handleScroll.bind(this); 

        window.addEventListener('scroll', this.handleScroll);
    }
    
    render() {
        return (<div className="header-container">
            <div className="account-wrapper" hidden={this.mainAccountHidden}>
                {this.renderAccountSection()}
            </div>
            <div className="banner">
                <svg width='700' height='350' transform='rotate(65)' />
                <div className="header">
                    <Container fluid>
                        <a href="https://liberum.com/" className="logo">
                            <svg viewBox="0 0 164.4 27" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="150" height="24.63">
                                <path d="M55 12.7c2-.6 4-2.7 4-5.9 0-6-5.1-6.8-9.4-6.8h-9.5v26.6H49c3.9 0 5.8 0 7.8-1.4 2.9-1.8 3-4.6 3-5.9.1-5.3-3.8-6.2-4.8-6.6zM43.8 3.1h6.5c1.4 0 5.1.1 5.1 3.9 0 4.6-4.2 4.3-7.8 4.3h-3.8V3.1zm9.4 20.1c-.8.2-1.4.2-3.3.2h-6.1v-9h4.7c3.2 0 4.1.1 5.2.5.3.1 2.5 1 2.5 4.1.1 2.2-.8 3.7-3 4.2zM100.7 14.2l.4-.1c1.3-.2 6.1-1.6 6.1-6.7 0-5.7-4-7.4-9.9-7.4h-9.9l7.3 11.4h-7v15.2h3.7V14.5h5.2l7.8 12.1h4.2l-7.9-12.4zM93.5 3.1h4c3.5 0 5.8 1 5.8 4.3 0 2.9-2.3 3.7-4.6 3.9l-5.2-8.2zM68.2 14.5h12.7v-3.1H68.2V3.1h13.9V0H64.5v26.6h18v-3.1H68.2zM3.7 0H0v26.6h16.2v-3.1H3.7zM19.2 3.1h6.5v20.4h-6.5v3.1h16.7v-3.1h-6.5V3.1h6.5V0H19.2zM128.4 17.2c0 1.4 0 3-1 4.2-1.7 1.9-3.7 2.3-5.3 2.3h-.2c-1.6 0-3.5-.5-5.1-2.3-1-1.2-.9-2.8-1-4.2V0h-3.6v17c0 2.8.3 4.9 1.7 6.7 2.2 2.6 5.1 3.3 8.2 3.3s6-.7 8.2-3.3c1.4-1.8 1.7-3.9 1.7-6.7V0h-3.6v17.2zM160.2 0l-9.4 14.5L141.6 0h-4.5v26.6h3.7V5.5l7.8 12.4h4.2l7.9-12.2v20.9h3.7V0z"></path>
                            </svg>
                        </a>
                        {this.renderHeaderCaption()}
                    </Container>
                </div>
            </div>
            <Nav className={this.state.scrollNavClass}>
                <div className="container-fluid">
                    <a ref="scrollLogo" href="https://liberum.com/" className={this.state.scrollLogoClass}>
                        <svg viewBox="0 0 164.4 27" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="150" height="24.63">
                            <path d="M55 12.7c2-.6 4-2.7 4-5.9 0-6-5.1-6.8-9.4-6.8h-9.5v26.6H49c3.9 0 5.8 0 7.8-1.4 2.9-1.8 3-4.6 3-5.9.1-5.3-3.8-6.2-4.8-6.6zM43.8 3.1h6.5c1.4 0 5.1.1 5.1 3.9 0 4.6-4.2 4.3-7.8 4.3h-3.8V3.1zm9.4 20.1c-.8.2-1.4.2-3.3.2h-6.1v-9h4.7c3.2 0 4.1.1 5.2.5.3.1 2.5 1 2.5 4.1.1 2.2-.8 3.7-3 4.2zM100.7 14.2l.4-.1c1.3-.2 6.1-1.6 6.1-6.7 0-5.7-4-7.4-9.9-7.4h-9.9l7.3 11.4h-7v15.2h3.7V14.5h5.2l7.8 12.1h4.2l-7.9-12.4zM93.5 3.1h4c3.5 0 5.8 1 5.8 4.3 0 2.9-2.3 3.7-4.6 3.9l-5.2-8.2zM68.2 14.5h12.7v-3.1H68.2V3.1h13.9V0H64.5v26.6h18v-3.1H68.2zM3.7 0H0v26.6h16.2v-3.1H3.7zM19.2 3.1h6.5v20.4h-6.5v3.1h16.7v-3.1h-6.5V3.1h6.5V0H19.2zM128.4 17.2c0 1.4 0 3-1 4.2-1.7 1.9-3.7 2.3-5.3 2.3h-.2c-1.6 0-3.5-.5-5.1-2.3-1-1.2-.9-2.8-1-4.2V0h-3.6v17c0 2.8.3 4.9 1.7 6.7 2.2 2.6 5.1 3.3 8.2 3.3s6-.7 8.2-3.3c1.4-1.8 1.7-3.9 1.7-6.7V0h-3.6v17.2zM160.2 0l-9.4 14.5L141.6 0h-4.5v26.6h3.7V5.5l7.8 12.4h4.2l7.9-12.2v20.9h3.7V0z"></path>
                        </svg>
                    </a>
                    {this.renderMenuItem()}
                    <div className="account-wrapper2" hidden={this.state.scrollAccountHidden}>
                        {this.renderAccountSection()}
                    </div>
                </div>
            </Nav>
        </div>);
    }

    renderMenuItem = () => {
        return this.props.auth.admin ? (<div id="mainNav" className="mainNav">
            <div className="inner-container">
                <Navbar>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <NavDropdown title="Home" id="basic-nav-home">
                                <NavDropdown.Item href={APIBASEURL.concat('jobs/p/1')}>All jobs</NavDropdown.Item>
                                <NavDropdown.Item href={APIBASEURL.concat('jobs/archived/p/1')}>Archived jobs</NavDropdown.Item>
                                <NavDropdown.Item href={APIBASEURL.concat('job/create')}>Create jobs</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Item><Nav.Link href={APIBASEURL.concat('applications/p/1')}>Applications</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href={APIBASEURL.concat('users/view/0')}>Users</Nav.Link></Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>) : (<div className="mainNav">
            <div className="inner-container">
                <Navbar>
                    <Nav className="mr-auto">
                        <Nav.Item>
                            <Nav.Link href={APIBASEURL}>Home</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar>
            </div>
        </div>);
    }

    renderHeaderCaption = () => {
        return this.state.admin ? null : (<div className="inner-container">
            <div className="text-left">
                <div className="caption-top">Take the next step in your career</div>
                <h1>Liberum Careers Portal</h1>
            </div>
        </div>);
    }

    renderAccountSection = () => {
        let appl = this.props.auth.admin ? null :
            (<Dropdown.Item href={APIBASEURL.concat('applications')}> Applications</Dropdown.Item >);

        if (this.props.auth.authenticated) {
            return (<div className="auth-bar">
                <Dropdown>
                    <Dropdown.Toggle id="account-dropdown">{"Hello, " + this.props.auth.userName}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href={APIBASEURL.concat('account')}>Profile</Dropdown.Item>
                        {appl}
                        <Dropdown.Item divider />
                        <Dropdown.Item href={APIBASEURL.concat('logout')}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>);
        }
        else {
            return (<div className="auth-bar">
                <Dropdown>
                    <Dropdown.Toggle id="account-dropdown">{"Hello, " + this.props.auth.userName}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href={APIBASEURL.concat('login')}>Login</Dropdown.Item>
                        <Dropdown.Item href={APIBASEURL.concat('register')}>Register</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>);
        }
    }

    handleScroll() {
        if (window.pageYOffset >= 160) {
            this.setState({
                scrollNavClass: "navbar navbar-fixed-top",
                scrollLogoClass: "logo show",
                mainAccountHidden: true,
                scrollAccountHidden: false
            });
        } else {
            this.setState({
                scrollNavClass: "navbar",
                scrollLogoClass: "logo hidden",
                mainAccountHidden: false,
                scrollAccountHidden: true
            });
        }
    }

    jobMenuToggle = () => {
        this.setState({
            jobMenuOpen: !this.state.jobMenuOpen
        });
    }

    toggleAccountSection() {
        this.setState({
            accountSectionOpen: !this.state.accountSectionOpen
        });
    }
}