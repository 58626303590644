import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthService } from '../../helpers/AuthService';
import { APIBASEURL } from '../../helpers/SiteConfig';

const TOKEN_INTERVAL = 4000;

export class VerifyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: false,
            redirect: false,
            loading: true,
            message: ''
        };

        this.redirectPage = this.redirectPage.bind(this);

        AuthService.verifyEmail(this.props.token)
            .then((data) => {
                if (data.succeeded) {
                    this.props.handleVerification(true, data.user);
                    this.setState({
                        loading: false,
                        verified: data.succeeded,
                        redirect: data.succeeded,
                        message: ''
                    });

                } else {
                    this.setState({
                        loading: false,
                        verified: false,
                        redirect: false,
                        message: data.error.errorMessage
                    })
                }
            }).catch((err) => {
            });

        if (this.state.redirect) {
            setInterval(this.redirectPage, TOKEN_INTERVAL);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/'} />;
        }

        let content = this.state.verified
            ? <div className="row">
                <div className="col col-md-12">
                    <div className="well">
                        <h2>Email verification successful.</h2>
                        <p></p>
                        <p>You have full access of your account. Please <a href={APIBASEURL.concat('login')}>login</a>.</p>
                    </div>
                </div>
            </div>
            : <div className="row">
                <div className="col col-md-12">
                    <div className="well">
                        <h2>Email verification failed.</h2>
                        <p>&nbsp;</p>
                        <p>{this.state.message}</p>
                        <p>Email verification failed, page will jump to <a href={APIBASEURL}>Home</a> in 5 secs.</p>
                    </div>
                </div>
            </div>;

        return this.state.loading ? <div><p><em>Loading...</em></p></div> : <div>
            <div className="row"><div className="col col-lg-12"><div className="page-header">Account Verification</div></div></div>
            {content}
        </div>;
    }

    redirectPage() {
        this.setState({ redirect: true });
    }
}