import React, { Component } from 'react';
import DisplayAlert from '../DisplayAlert';
//import { Row, Col, Form, FormGroup, Form.Label, Form.Control, Button } from 'reactstrap';
import { Row, Col, Form, Button } from 'react-bootstrap';
import './EditProfile.css';
import update from 'immutability-helper'
import { DataService } from '../../helpers/DataService';
import { AuthService } from '../../helpers/AuthService';
import { FormGroup } from 'reactstrap';


export class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            password: '',
            confirmPassword: '',
            firstNameUntouched: true,
            lastNameUntouched: true,
            emailUntouched: true,
            passwordUntouched: true,
            confirmPasswordUntouched: true,
            line1Untouched: true,
            cityUntouched: true,
            postcodeUntouched: true,
            countryUntouched: true,
            updateAccountAlertType: '',
            updateAccountMessage: '',
            updateProfileAlertType: '',
            updateProfileMessage: '',
            updateContactAlertType: '',
            updateContactMessage: '',
            alert: {
                alertType: '',
                message: ''
            }
        };

        this.validUpdateAccount = this.validUpdateAccount.bind(this);
        this.validUpdateContact = this.validUpdateContact.bind(this);
        this.validUpdateProfile = this.validUpdateProfile.bind(this);    
    }

    componentDidMount() {
        this.fetchProfileInfo();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.auth !== prevState.auth) {
            DataService.getProfile()
                .then(data => {
                    return {
                        user: data
                    };
                }).catch(err => {
                    return {
                        user: null,
                        email: '',
                        password: '',
                        confirmPassword: '',
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: err }
                        })
                    }
                });
        }
        return null;
    }

    fetchProfileInfo() {
        DataService.getProfile()
            .then(data => {
                this.setState({
                    user: { ...this.state.user, ...data }
                });
            }).catch(err => {
                this.setState({
                    user: null,
                    email: '',
                    password: '',
                    confirmPassword: '',
                    alert: update(this.state.alert, {
                        alertType: { $set: 'error' },
                        message: { $set: err }
                    })
                })
            });
    }

    render() {
        return (<div>
            {this.renderEditProfileForm()}
            <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
        </div>);
    }

    renderEditProfileForm() {
        if (this.state.user && this.props.auth) {
            return (<div>
                <Row>
                    <Col lg={12}>
                        <span className="page-header">Edit Profile</span>
                    </Col>
                </Row>

                <div className="block">
                    <Row>
                        <Col lg={12}>
                            <span className="block-header">Basic Profile</span>
                        </Col>
                    </Row>
                    <Form noValidate validated={this.validUpdateProfile} onSubmit={(e) => { this.onProfileFormSubmit(e) }}>
                        <Form.Group as={Row} controlId="txtFirstName">
                            <Form.Label column sm="2">First Name</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required
                                    defaultValue={this.state.user ? this.state.user.firstName : ''}
                                    onChange={(e) => this.onFirstNameChange(e)} placeholder="First Name" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtLastName">
                            <Form.Label column sm="2">Last Name</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required
                                    defaultValue={this.state.user ? this.state.user.lastName : ''}
                                    onChange={(e) => this.onLastNameChange(e)} placeholder="Last Name" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="btnUpdateProfile">
                            <Col sm="2"></Col>
                            <Col sm="4">
                                <Button type="submit" color="primary" size="sm" disabled={!this.validUpdateProfile()}>Update Profile</Button>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="2"></Col>
                            <Col sm="4">
                                <DisplayAlert alertType={this.state.updateProfileAlertType} header={''} message={this.state.updateProfileMessage} />
                            </Col>
                        </Form.Group>
                    </Form>
                </div>

                <div className="block">
                    <Row>
                        <Col lg={12}>
                            <span className="block-header">Account</span>
                        </Col>
                    </Row>
                    <Form noValidate validated={this.validUpdateAccount}>
                        <Form.Group as={Row} controlId="txtEmail">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="4">
                                <Form.Control plaintext readOnly defaultValue={this.state.user ? this.state.user.email : ''} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtPassword">
                            <Form.Label column sm="2">Password</Form.Label>
                            <Col sm="4">
                                <Form.Control type="password" size="sm" required defaultValue={this.state.password}
                                    onChange={(e) => this.onPasswordChange(e)} placeholder="Password" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtConfirmPassword">
                            <Form.Label column sm="2">Confirm Password</Form.Label>
                            <Col sm="4">
                                <Form.Control type="password" size="sm" required defaultValue={this.state.confirmPassword}
                                    onChange={(e) => this.onConfirmPasswordChange(e)} placeholder="Confirm Password" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="btnUpdateAccount">
                            <Col sm="2"></Col>
                            <Col sm="4">
                                <Button color="primary" variant="primary" size="sm" active={this.validUpdateAccount()}
                                    disabled={!this.validUpdateAccount()}
                                    onClick={(e) => { this.onAccountFormSumbit(e) }}>Update Account</Button>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="2"></Col>
                            <Col sm="4">
                                <DisplayAlert alertType={this.state.updateAccountAlertType} header='' message={this.state.updateAccountMessage} />
                            </Col>
                        </Form.Group>
                    </Form>
                </div>

                <div className="block">
                    <Row>
                        <Col lg={8}>
                            <span className="block-header">Contact Details</span>
                        </Col>
                    </Row>
                    <Form noValidate validated={this.validUpdateContact} onSubmit={(e) => { this.onContactFormSubmit(e) }}>
                        <Form.Group as={Row} controlId="txtAddressLine1">
                            <Form.Label column sm="2">Address Line 1</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required 
                                    defaultValue={this.state.user ? this.state.user.line1 : ''}
                                    placeholder="Address Line 1" onChange={(e) => { this.onLine1Changed(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtAddressLine2">
                            <Form.Label column sm="2">Address Line 2</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" 
                                    defaultValue={(this.state.user && this.state.user.line2) ? this.state.user.line2 : ''}
                                    placeholder="Address Line 2" onChange={(e) => { this.onLine2Changed(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtCity">
                            <Form.Label column sm="2">City</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required
                                    defaultValue={this.state.user ? this.state.user.city : ''}
                                    placeholder="City" onChange={(e) => { this.onCityChanged(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtCounty">
                            <Form.Label column sm="2">County</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm"
                                    defaultValue={(this.state.user && this.state.user.county) ? this.state.user.county : ''}
                                    placeholder="County" onChange={(e) => { this.onCountyChanged(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtPostcode">
                            <Form.Label column sm="2">Postcode</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required
                                    defaultValue={this.state.user ? this.state.user.postcode : ''}
                                    placeholder="Postcode" onChange={(e) => { this.onPostcodeChanged(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtCountry">
                            <Form.Label column sm="2">Country</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required
                                    defaultValue={this.state.user ? this.state.user.country : ''}
                                    placeholder="Country" onChange={(e) => { this.onCountryChanged(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="txtPhone">
                            <Form.Label column sm="2">Phone</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" size="sm" required
                                    defaultValue={this.state.user ? this.state.user.phone : ''}
                                    placeholder="Contact Number" onChange={(e) => { this.onPhoneChanged(e) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="btnUpdateContact">
                            <Col sm="2"></Col>
                            <Col sm="4">
                                <Button type="submit" color="primary" size="sm" disabled={!this.validUpdateContact()}>Update Contact Details</Button>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="2"></Col>
                            <Col sm="4">
                                <DisplayAlert alertType={this.state.updateContactAlertType} header='' message={this.state.updateContactMessage} />
                            </Col>
                        </Form.Group>
                    </Form>
                </div>       
            </div>);
        }
        else {
            return (<div><p><em>We had some problem fetching your profile.</em></p></div>);
        }
    }

    onFirstNameChange(e) {
        let user = this.state.user;
        if (user !== null) {
            user.firstName = e.target.value;
            this.setState({
                user: user,
                updateProfileAlertType: '',
                updateProfileMessage: ''
            });
        }
    }

    onLastNameChange(e) {
        let user = this.state.user;
        if (user !== null) {
            user.lastName = e.target.value;
            this.setState({
                user: user,
                updateProfileAlertType: '',
                updateProfileMessage: ''
            });
        }
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
            updateAccountAlertType: '',
            updateAccountMessage: ''
        });
    }

    onConfirmPasswordChange(e) {
        this.setState({
            confirmPassword: e.target.value,
            updateAccountAlertType: '',
            updateAccountMessage: ''
        });
    }

    onPhoneChanged(e) {
        let user = this.state.user;
        if (user !== null) {
            user.phone = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    onLine1Changed(e) {
        let user = this.state.user;
        if (user !== null) {
            user.line1 = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    onLine2Changed(e) {
        let user = this.state.user;
        if (user !== null) {
            user.line2 = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    onCityChanged(e) {
        let user = this.state.user;
        if (user !== null) {
            user.city = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    onCountyChanged(e) {
        let user = this.state.user;
        if (user !== null) {
            user.county = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    onPostcodeChanged(e) {
        let user = this.state.user;
        if (user !== null) {
            user.postcode = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    onCountryChanged(e) {
        let user = this.state.user;
        if (user !== null) {
            user.country = e.target.value;
            this.setState({
                user: user,
                updateContactAlertType: '',
                updateContactMessage: ''
            });
        }
    }

    validUpdateAccount() {
        return this.state.password && this.state.password !== undefined
            && this.state.confirmPassword && this.state.confirmPassword !== undefined
            && (this.state.password === this.state.confirmPassword);
    }

    validUpdateProfile() {
        return this.state.user !== undefined && this.state.user !== null
            && this.state.user.firstName && this.state.user.firstName !== undefined && this.state.user.firstName.trim() !== ''
            && this.state.user.lastName && this.state.user.lastName !== undefined && this.state.user.lastName.trim() !== '';
    }

    validUpdateContact() {
        return this.state.user !== undefined && this.state.user !== null
            && this.state.user.line1 && this.state.user.line1 !== undefined && this.state.user.line1.trim() !== ''
            && this.state.user.postcode && this.state.user.postcode !== undefined && this.state.user.postcode.trim() !== ''
            && this.state.user.city && this.state.user.city !== undefined && this.state.user.city.trim() !== ''
            && this.state.user.country && this.state.user.country !== undefined && this.state.user.country.trim() !== ''
            && this.state.user.phone && this.state.user.phone !== undefined && this.state.user.country.trim() !== '';
    }

    onProfileFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({
                updateProfileAlertType: 'error',
                updateProfileMessage: 'Profile form validation failed.'
            });
        }
        else {
            DataService.updateBasicProfileInfo(this.state.user)
                .then(rsp => {
                    this.setState({
                        updateProfileAlertType: 'info',
                        updateProfileMessage: 'Profile has been updated.'
                    });
                }).catch(err => {
                    this.setState({
                        updateProfileAlertType: 'error',
                        updateProfileMessage: err
                    });
                });
        }
    }

    onContactFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({
                updateContactAlertType: 'error',
                updateContactMessage: 'Contact Details form validation failed.'
            });
        }
        else {
            DataService.updateContactDetails(this.state.user)
                .then(rsp => {
                    if (rsp.succeeded) {
                        this.setState({
                            updateContactAlertType: 'info',
                            updateContactMessage: 'Contact details have been updated.'
                        });
                    }
                }).catch(err => {
                    this.setState({
                        updateContactAlertType: 'error',
                        updateContactMessage: err
                    });
                });
        }
    }

    onAccountFormSumbit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({
                updateAccountAlertType: 'error',
                updateAccountMessage: 'Account form validation failed.'
            });
        }
        else {
            AuthService.resetPassword(this.state.user.email, this.state.password)
                .then(rsp => {
                    this.setState({
                        updateAccountAlertType: 'info',
                        updateAccountMessage: 'Account details have been updated.'
                    });
                }).catch(err => {
                    this.setState({
                        updateAccountAlertType: 'error',
                        updateAccountMessage: err
                    });
                });
        }
    }
}