import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { SearchJob } from './components/job/SearchJob';
import { ApplyJob } from './components/apply/ApplyJob';
import { EditJob } from './components/job/EditJob';
import { Login } from './components/account/Login';
import { Logout } from './components/account/Logout';
import { Register } from './components/account/Register';
import { SearchApplication } from './components/application/SearchApplication';
import { ViewApplicationByJob } from './components/application/ViewApplicationByJob';
import { ViewApplication } from './components/application/ViewApplication';
import { EditUser } from './components/EditUser';
import { ListUser } from './components/ListUser';
import { ForgetPassword } from './components/account/ForgetPassword';
import { ResetPassword } from './components/account/ResetPassword';
import { VerifyAccount } from './components/account/VerifyAccount';
import { EditProfile } from './components/account/EditProfile';
import { TokenHelper } from './helpers/TokenHelper';
import update from 'immutability-helper';
import { AuthService } from './helpers/AuthService';

const TOKEN_INTERVAL = 100000;

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            auth: {
                userId: 0,
                userName: 'Sign in',
                authenticated: false,
                admin: false
            },
            user: null,
            timer: null,
            alert: {
                alertType: '',
                message: ''
            }
        };
        
        this.fetchAccount();

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleVerification = this.handleVerification.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    componentDidMount() {
        const timer = setInterval(this.handleRefresh, TOKEN_INTERVAL);
        this.setState({ timer: timer });
    }
    
    componentWillMount() {
        clearInterval(this.state.timer);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.auth !== this.state.auth || nextState.alert !== this.state.alert;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.auth !== this.state.auth) {
            this.fetchAccount();
        }
    }
    
    render() {
        return (<div>
            <Layout path='/' auth={this.state.auth}>
                <Route exact path='/' render={({ match, history }) => (
                    <SearchJob baseUrl={this.props.baseUrl} params={match.params} auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/jobs/p/:page?' render={({ match, history }) => (
                    <SearchJob baseUrl={this.props.baseUrl} params={match.params} auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/jobs/:mode/p/:page?' render={({ match, history }) => (
                    <SearchJob baseUrl={this.props.baseUrl} params={match.params} auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/job/:mode/:id?/:status?' render={({ match, history }) => (
                    <EditJob params={match.params} auth={this.state.auth.admin} history={history} />
                )} />
                <Route path='/login' render={({ history }) => (
                    <Login embedded={false} auth={this.state.auth} handleLogin={this.handleLogin} history={history}
                        alert={this.state.alert} />
                )} />
                <Route path='/register' render={() => (<Register embedded={false} />)} />
                <Route path='/verify/:token?' render={({ match }) => (
                    <VerifyAccount token={match.params.token} baseUrl={this.props.baseUrl} handleVerification={this.handleVerification} />
                )} />
                <Route path='/forgetpassword' render={() => (<ForgetPassword />)} baseUrl={this.props.baseUrl} />
                <Route path='/resetpassword/:token?' render={({ match, history }) => (
                    <ResetPassword token={match.params.token} baseUrl={this.props.baseUrl} history={history} />
                )} />
                <Route path='/logout' render={({ history }) => (<Logout history={history} logout={this.handleLogout} />)} />
                <Route path='/apply/job/:id' render={({ match, history }) => (
                    <ApplyJob jobId={Number(match.params.id)} auth={this.state.auth} handleLogin={this.handleLogin}
                        alert={this.state.alert} history={history} />
                )} />
                <Route path='/account' render={({ history }) => (
                    <EditProfile auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/jobs/application/:id/:status?'
                    render={({ match, history }) => (
                        <ViewApplicationByJob params={match.params} auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/applications' render={({ match, history }) => (
                    <SearchApplication params={match.params} auth={this.state.auth} />
                )} />
                <Route exact path='/applications/p/:page/:keywords?' render={({ match, history }) => (
                    <SearchApplication params={match.params} auth={this.state.auth} />
                )} />
                <Route exact path='/applications/view/:id?' render={({ match }) => (
                    <ViewApplication id={match.params.id} auth={this.state.auth} print={false} />
                )} />
                <Route exact path='/applications/edit/:id?' render={({ match }) => (
                    <ViewApplication id={match.params.id} auth={this.state.auth} print={false} />
                )} />
                <Route exact path='/users/view/:offset' render={({ match, history }) => (
                    <ListUser params={match.params} auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/users/view/:offset/:limit' render={({ match, history }) => (
                    <ListUser params={match.params} auth={this.state.auth} history={history} />
                )} />
                <Route exact path='/users/u/:id' render={({ match, history }) => (
                    <EditUser userId={match.params.id} auth={this.state.auth} history={history} />
                )} />
            </Layout>
        </div>);
    }

    handleLogin(email, password) {
        AuthService.login(email, password)
            .then(rsp => {
                this.setState({
                    auth: update(this.state.auth, {
                        userId: { $set: rsp.user.userId },
                        userName: { $set: rsp.user.firstName + ' ' + rsp.user.lastName },
                        authenticated: { $set: rsp.user.active },
                        admin: { $set: rsp.user.admin }
                    }),
                    user: rsp.user,
                    alert: update(this.state.alert, {
                        alertType: { $set: '' },
                        message: { $set: '' }
                    })
                });
            })
            .catch(err => {
                this.setState({
                    auth: update(this.state.auth, {
                        userId: { $set: 0 },
                        userName: { $set: 'Sign in' },
                        authenticated: { $set: false },
                        admin: { $set: false }
                    }),
                    user: null,
                    alert: update(this.state.alert, {
                        alertType: { $set: 'error' },
                        message: { $set: err.message }
                    })
                });
            });
    }

    handleVerification(verified, user) {
        this.setState({
            auth: update(this.state.auth, {
                userId: { $set: user.userId },
                userName: { $set: user.firstName + ' ' + user.lastName },
                authenticated: { $set: user.active },
                admin: { $set: user.admin }
            }),
            user: user,
            alert: update(this.state.alert, {
                alertType: { $set: '' },
                message: { $set: '' }
            })
        });
    }

    handleLogout() {
        var ok = AuthService.logout();
        this.setState({
            auth: update(this.state.auth, {
                userId: { $set: 0 },
                userName: { $set: 'Sign in' },
                authenticated: { $set: false },
                admin: { $set: false }
            })
        }, () => { });
        return ok;
    }
    
    fetchAccount() {
        const token = TokenHelper.getJwt();

        AuthService.fetchAccount(token).then(data => {

            let auth = update(this.state.auth, {
                userId: { $set: data.userId },
                userName: { $set: data.name },
                authenticated: { $set: data.active },
                admin: { $set: data.admin }
            });
            
            this.setState({
                auth: auth
            });

        }).catch(() => {
            this.setState({
                auth: update(this.state.auth, {
                    userId: { $set: 0 },
                    userName: { $set: 'Sign in' },
                    authenticated: { $set: false },
                    admin: { $set: false }
                })
            });
        });
    }

    handleRefresh() {
        let refreshed = true;
        
        TokenHelper.refreshJwt()
            .then(data => {
                refreshed = data;
            })
            .catch(err => {
                refreshed = false;
            });

        if (!refreshed) {
            this.setState({
                auth: update(this.state.auth, {
                    userId: { $set: 0 },
                    userName: { $set: 'Sign in' },
                    authenticated: { $set: false },
                    admin: { $set: false }
                })
            });
        }
    }
}