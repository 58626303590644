import React, { Component } from 'react';
import {
    Col, Row, Form, FormGroup, Input, Label, InputGroup, InputGroupAddon,
    UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle
} from 'reactstrap';
import { ApplicationToolkit } from './ApplicationToolkit';
import './FillInCareerDetail.css';
//import CKEditor from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from 'ckeditor4-react';

export class FillInCareerDetail extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            keywordsUntouched: true,
            keywords: this.props.data.keywords,
            department: this.props.data.department,
            locationUntouched: true,
            location: this.props.data.location,
            currency: this.props.data.currency,
            salary: this.props.data.salary,
            salaryInfo: this.props.data.salaryInfo,
            experienceUntouched: true,
            experience: this.props.data.experience,
            educationUntouched: true,
            education: this.props.data.education,
            qualification: this.props.data.qualification,
            coverLetterUntouched: true,
            coverLetter: this.props.data.coverLetter,
            valid: false,
            alert: {
                alertType: '',
                message: null
            }
        };
    }

    render() {
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        return (<div className="application-section">
            <Row>
                <div className="col col-lg-12">
                    <h2>Your Career Details</h2>
                </div>
            </Row>
            <div className="row">
                <div className="col col-lg-12">
                    <p>To help us assess your suitability for future roles, please provide us with some information about
                        your career history. This information will be stored in our candidate database and we may contact
                        you in future about suitable opportunities.</p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <Form>
                <FormGroup className={(this.onLocationValid() || this.state.locationUntouched) ? '' : 'has-error'} row>
                    <Label md={3}>
                        Liberum Office Location
                    </Label>
                    <Col md={6}>
                        <Input type="select" id="Location" name="location" defaultValue={this.state.location ? this.state.location : ''}
                            onChange={(e) => this.onLocationChange(e)}>
                            <option value="">Please select</option>
                            <option value="London">London</option>
                            <option value="New York">New York</option>
                        <option value="Guernsey">Guernsey</option>
                            </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Department" lg={3}>Relevant Department</Label>
                    <Col lg={6}>
                        <Input type="select" name="department" id="Department" defaultValue={this.state.department ? this.state.department : ''}
                            onChange={(e) => this.onDepartmentChange(e)}>
                            <option value="">Please select</option>
                            <option value="Alternative Funds">Alternative Funds</option>
                            <option value="Corporate Access">Corporate Access</option>
                            <option value="Investment Banking">Investment Banking</option>
                            <option value="Equity Research">Equity Research</option>
                            <option value="Sales Trading, Trading & Market Making">Sales Trading, Trading & Market Making</option>
                            <option value="Compliance & Legal">Compliance & Legal</option>
                            <option value="Production">Production</option>
                            <option value="Facilities">Facilities</option>
                            <option value="Finance">Finance</option>
                            <option value="Human Resources">Human Resources</option>
                            <option value="Information Technology">Information Technology</option>
                            <option value="Operations & Middle Office">Operations & Middle Office</option>
                            <option value="Other">Other</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Salary" lg={3}>Expected Salary</Label>
                    <Col lg={6}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>{this.state.currency === "USD" ? "$" : "£"}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.onCurrencyTypeChange('£')}>£</DropdownItem>
                                        <DropdownItem onClick={() => this.onCurrencyTypeChange('$')}>$</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </InputGroupAddon>
                            <Input type="number" id="Salary" name="salary"
                                defaultValue={this.state.salary ? this.state.salary : 0}
                                min={0} max={999999999} step="1"
                                onChange={(e) => this.onSalaryChange(e)} placeholder="Salary" />
                            <InputGroupAddon addonType="append">.00</InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <Input type="text" placeholder="Any additional information" onChange={(e) => this.onSalaryInfoChange(e)}
                            defaultValue={this.state.salaryInfo ? this.state.salaryInfo : ''} />
                    </Col>
                </FormGroup>
                <FormGroup className={(this.onExperiencesValid() || this.state.experienceUntouched) ? '' : 'has-error'} row>
                    <Label for="Experiences" lg={3}>
                        How many years’ experience do you have in your relevant career history? <span className="red">*</span>
                    </Label>
                    <Col lg={6}>
                        <Input type="select" id="Experiences" name="experiences"
                            defaultValue={this.state.experience ? this.state.experience : ''} 
                            onChange={(e) => this.onExperiencesChange(e)}>
                            <option value="">Please select</option>
                            <option value="1 to 3">1 - 3 years</option>
                            <option value="4 to 8">4 - 8 years</option>
                            <option value="9 to 15">9 - 15 years</option>
                            <option value="16+">16 years +</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup className={(this.onEducationValid() || this.state.educationUntouched) ? '' : 'has-error'} row>
                    <Label for="Education" lg={3}>
                        What is your highest level of education? <span className="red">*</span>
                    </Label>
                    <Col lg={6}>
                        <Input type="select" id="Education" name="education"
                            defaultValue={this.state.education ? this.state.education : ''} 
                            onChange={(e) => this.onEducationChange(e)}>
                            <option value="">Please select</option>
                            <option value="GCSE's or equivalent">GCSE's or equivalent</option>
                            <option value="A Levels or equivalent">A Levels or equivalent</option>
                            <option value="Diploma of higher education">Diploma of higher education</option>
                            <option value="Bachelors degree or equivalent">Bachelors degree or equivalent</option>
                            <option value="Masters degree or equivalent">Masters degree or equivalent</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Qualification" lg={3}>
                        Have you completed the ACCA, CFA, or other relevant qualification?
                    </Label>
                    <Col lg={6}>
                        <Input type="text" id="Qualification" name="qualification" placeholder="Qualification"
                            onChange={(e) => this.onQualificationChange(e)}
                            defaultValue={this.state.qualification ? this.state.qualification : ''} />
                    </Col>
                </FormGroup>
                <FormGroup className={(this.onKeywordsValid() || this.state.keywordsUntouched) ? '' : 'has-error'} row>
                    <Label for="Keywords" lg={3}>
                        Keywords relating to your job search, i.e CRM, Equities, Financial Modelling <span className="red">*</span>
                    </Label>
                    <Col lg={6}>
                        <Input type="text" id="Keywords" name="keywords" placeholder="Keywords"
                            onChange={(e) => this.onKeywordsChange(e)}
                            defaultValue={this.state.keywords ? this.state.keywords : ''} />
                    </Col>
                </FormGroup>
                <FormGroup className={(this.onCoverLetterValid() || this.state.coverLetterUntouched) ? '' : 'has-error'} row>
                    <Label for="coverLetter" lg={3}>
                        Cover Letter <span className="red">*</span>
                    </Label>
                    <Col lg={8}>
                        <CKEditor id="coverLetter" name="coverletter"
                            //config={{
                            //    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo', 'alignment', '|', 'blockQuote']
                            //}}
                            //editor={ClassicEditor}
                            data={this.state.coverLetter ? this.state.coverLetter : ''}
                            onChange={(evt) => { this.onCoverLetterChange(evt.editor.getData()) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col lg={12}><span className="red">*</span> Indicates required field.</Col>
                </FormGroup>
                <ApplicationToolkit stage={4} valid={this.checkValid()} next={this.onNext.bind(this)} back={this.onBack.bind(this)} />
            </Form>
            <div className="clear-fix" />
        </div>);
    }

    onKeywordsValid() {
        if (this.state.keywords == null || this.state.keywords === '' || this.state.keywords.trim() === '') {
            return false;
        } else {
            return true;
        }
    }

    onKeywordsChange(e) {
        this.setState({
            keywordsUntouched: false,
            keywords: e.target.value
        });
    }

    onDepartmentChange(e) {
        this.setState({
            department: e.target.value
        });
    
    }

    onLocationValid() {
        if (this.state.location == null || this.state.location === "" || this.state.location.trim().length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    onLocationChange(e) {
        this.setState({
            locationUntouched: false,
            location: e.target.value
        });
    }

    onExperiencesValid() {
        if (this.state.experience == null || this.state.experience.trim().length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    onExperiencesChange(e) {
        this.setState({
            experienceUntouched: false,
            experience: e.target.value
        });
    }

    onCurrencyTypeChange(currency) {
        if (currency === '$') {
            this.setState({
                currency: 'USD'
            });
        } else {
            this.setState({
                currency: 'GBP'
            });
        }
    }

    onSalaryChange(e) {
        if (e.target.value !== undefined) {
            this.setState({
                salary: parseInt(e.target.value, 0)
            });
        }
    }

    onSalaryInfoChange(e) {
        this.setState({
           salaryInfo: e.target.value
        });
    }

    onEducationValid() {
        if (this.state.education == null || this.state.education.trim().length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    onEducationChange(e) {
        this.setState({
            educationUntouched: false,
            education: e.target.value
        });
    }

    onQualificationChange(e) {
        this.setState({
            qualification: e.target.value
        });
    }

    onCoverLetterValid() {
        if (this.state.coverLetter == null || this.state.coverLetter === "" || this.state.coverLetter.trim().length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    onCoverLetterChange(data) {
        this.setState({
            coverLetterUntouched: false,
            coverLetter: data,
        });
    }

    checkValid() {
        let valid = true;
        let error = [];

        if (this.state.experience == null || this.state.experience.trim().length <= 0) {
            error.push("please choose a valid experience");
            valid = false;
        }

        if (this.state.education == null || this.state.education.trim().length <= 0) {
            error.push("please choose a valid education");
            valid = false;
        }

        if (this.state.coverLetter == null || this.state.coverLetter.trim().length <= 0) {
            error.push("please fill in cover letter");
            valid = false;
        }

        return valid;
    }

    onBack() {
        this.props.back();
    }

    onNext() {
        var data = {
            keywords: this.state.keywords,
            department: this.state.department,
            location: this.state.location,
            currency: this.state.currency,
            salary: this.state.salary,
            salaryInfo: this.state.salaryInfo,
            experience: this.state.experience,
            education: this.state.education,
            qualification: this.state.qualification, 
            coverLetter: this.state.coverLetter
        };
        this.props.next(data);
    }
}