import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import DisplayAlert from '../DisplayAlert';
import { AuthService } from '../../helpers/AuthService';
import { ForgetPasswordForm, SuccessScreen } from './forget-password-form/ForgetPasswordForm';
import './ForgetPassword.css';
import update from 'immutability-helper';

export class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            emailSent: false,
            alert: {
                alertType: '',
                message: null
            }
        };

        this.valid = this.valid.bind(this);
        this.onRecoveryEmailChange = this.onRecoveryEmailChange.bind(this);
        this.handleRecover = this.handleRecover.bind(this);
    }

    render() {
        let content = this.state.emailSent ? <SuccessScreen /> :
            <ForgetPasswordForm email={this.state.email} valid={this.valid(this.state.email)}
                onRecoveryEmailChange={this.onRecoveryEmailChange} handleRecover={this.handleRecover} />;

        return (<div>
            {content}
            <Row>
                <Col md={1}></Col>
                <Col md={5}>
                    <DisplayAlert alertType={this.state.alert.alertType} header={''} message={this.state.alert.message} />
                </Col>
            </Row>
        </div>);
    }

    onRecoveryEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    valid = (email) => {
        return email !== undefined && email !== NaN && email !== null && email !== '' && email.toString().trim() !== '';
    }

    handleRecover = () => {
        AuthService.forgetPassword(this.state.email)
            .then((data) => {
                if (data.succeeded) {
                    this.setState({
                        emailSent: true
                    });
                } else {
                    this.setState({
                        emailSent: false,
                        alert: update(this.state.alert, {
                            alertType: { $set: 'error' },
                            message: { $set: data.message }
                        })
                    });
                }
            })
            .catch(() => {
                this.setState({
                    emailSent: false,
                    alert: update(this.state.alert, {
                        alertType: { $set: 'error' },
                        message: { $set: 'Password recovery failed.' }
                    })
                });
            });
    }
}