import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { ApplicationToolkit } from './ApplicationToolkit';
import './DropZone.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { APIBASEURL } from '../../helpers/SiteConfig';

export class DropZone extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isUploading: false,
            completed: false,
            files: this.props.files,
            data: null,
            alertType: '',
            message: ''
        };

        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.file !== prevState.file) {
            return { file: nextProps.file };
        }
        return null;
    }

    render() {
        let content = this.state.files
            ? this.displayFiles(this.state.files)
            : <div />;

        return (<div className="application-section">
            <Form id="uploadForm" name="uploadForm" onSubmit={this.handleSubmitClick.bind(this)}
                encType="multipart/form-data" method="POST" action={APIBASEURL.concat('api/file/upload')}>
                <h2>Upload CV</h2>
                <p>Please attach your CV before submitting the application.</p>
                <div className="dropzone-wrapper">
                    <div id="dropZone" className="dropzone" onClick={(e) => this.onClick(e)} onDrop={this.onDrop}
                        onDragOver={this.onDragOver}>
                        <span className="glyphicon glyphicon-download-alt"></span>
                        <h4>Please click to upload your CV...</h4>
                        <input type="file" id="fileUploader" name="fileUploader" className="hidden" ref="fileUploader"
                            onChange={(e) => this.onFileChange(e)}
                            accept=".doc,.docx,.pdf,.xml,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                    </div>
                    {content}
                </div>
                <ApplicationToolkit stage={5} valid={this.submitValid()} back={this.handleBackClick.bind(this)} next={this.handleSubmitClick.bind(this)} />
            </Form>
        </div>);
    }


    onClick(e) {
        let ele = document.getElementById("fileUploader");
        ele.click();
    }


    onDrop(e) {
        e.stopPropagation();
        let names = [];
        if (e.dataTransfer.files) {
            for (var i = 0; i < e.dataTransfer.files.length; i++) {
                names.push(e.dataTransfer.files[i].name);
            }
        }

        this.setState({
            files: names
        });
        e.preventDefault();
    }


    onDragOver(e) {
        e.stopPropagation();
        e.preventDefault();
    }


    onFileChange(e) {
        let list = this.state.files;

        if (!this.props.allowMutiple) {
            list = [e.target.files[0].name]
        } else {
            list.push(e.target.files.name);
        } 

        this.setState({
            files: list,
            data: e.target.files
        });
    }


    handleSubmitClick(e) {
        let ele = document.getElementById("fileUploader");
        this.props.uploadCV(ele.files);
    }

    handleBackClick() {
        this.props.back();
    }

    submitValid() {
        if (!this.state.files)
            return false;

        if (!this.props.allowMutiple && this.state.files.length > 1)
            return false;

        if (this.state.files.length <= 0)
            return false;

        return true;
    }
    
    removeFile(val) {
        let ele = document.getElementById("fileUploader");
        ele.value = '';
        this.setState({ files: [] });
        this.props.removeCV();
    }

    displayFiles(e) {
        return <div className="filelist">
            <ul>
                {this.state.files ? this.state.files.map(f => <li key={f}>
                    <FontAwesomeIcon icon={faFile} />{' '.concat(f).concat('  ')}
                    <FontAwesomeIcon icon={faTrash} onClick={() => this.removeFile(f)} />
                </li>) : <div />}
            </ul>
        </div>;
    }
}