import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import { Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Router basename={baseUrl} history={history}>
        <App />
    </Router>,
    rootElement);

unregister();
registerServiceWorker();